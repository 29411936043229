






























import { Component, Mixins, Watch } from "vue-property-decorator";
import { BaseSideBarMixin } from "@/mixins";
import { namespace } from "vuex-class";
const SideBar = namespace("SideBar");
const StaffModule = namespace("StaffModule");
const DepartmentModule = namespace("DepartmentModule");
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";
import {
  WeeklyItemModel,
  StaffToShiftRequest,
  DepartmentUnscheduledStaff,
  BaseResponse,
  AddStaffToShiftRequest,
  DepartmentEmployeeModel
} from "@/models";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  ShiftCardStaffTemplateComponent,
  StaffCardTemplateComponent
} from "@/common/template";
import moment from "moment";
@Component({
  components: {
    "side-menu-layout": SideMenuLayout,
    ValidationObserver,
    ValidationProvider,
    ShiftCardStaffTemplateComponent,
    StaffCardTemplateComponent
  }
})
export default class AddStaffToShiftComponent extends Mixins(BaseSideBarMixin) {
  search = "";
  staffs: DepartmentUnscheduledStaff[] = [];
  isSelected = false;
  @SideBar.Getter
  private currentActiveMenuItem!: WeeklyItemModel;
  @DepartmentModule.Getter
  currentDepartment!: DepartmentEmployeeModel;
  @StaffModule.Action
  getStaffToShift!: (
    model: StaffToShiftRequest
  ) => Promise<DepartmentUnscheduledStaff[]>;

  @StaffModule.Action
  addStaffToShift!: (model: AddStaffToShiftRequest) => Promise<BaseResponse>;

  @Watch("currentActiveMenuItem", { immediate: true, deep: true })
  changeCurrentActiveMenuItem() {
    console.log("currentActiveMenuItem change", this.currentActiveMenuItem);
    if (this.currentActiveMenuItem.departmentId > 0) {
      this.loadStaffToShift();
    }
  }

  @Watch("staffs", { immediate: true, deep: true })
  changeFilteredStaff() {
    console.log("staffs change", this.staffs);
    this.checkIfSelected();
  }

  @Watch("search", { immediate: true, deep: true })
  changeSearch() {
    console.log("changeSearch change", this.search);
    // this.model = { ...this.currentActiveMenuItem };
    this.applyFilter();
  }

  // async mounted() {
  // }

  filterStaff() {
    console.log("filterStaff", this.search);
    this.applyFilter();
  }

  checkIfSelected() {
    const selectedValue = this._.filter(this.staffs, s => {
      return s.selected;
    });
    this.isSelected = selectedValue.length > 0;
  }

  onSubmit() {
    const selectedValue = this._.filter(this.staffs, s => {
      return s.selected;
    }).map(c => c.userId);

    console.log("onsubmit", selectedValue);
    this.addStaffToShift({
      departmentId: this.currentActiveMenuItem.departmentId,
      shiftEmpReqId: this.currentActiveMenuItem.shiftEmpReqId,
      currentDepartmentName: this.currentDepartment.companyDepartmentName,
      userIds: selectedValue as number[]
    }).then(res => {
      if (res.success) {
        this.closeMenu({
          success: true,
          reload: true
        });
      } else {
        console.log("error, esle", res);
      }
    });
  }

  async loadStaffToShift() {
    this.staffs = [];
    console.log("loadStaffToShift", this.currentActiveMenuItem);
    this.getStaffToShift({
      departmentId: this.currentActiveMenuItem.departmentId,
      shiftEmpReqId: this.currentActiveMenuItem.shiftEmpReqId
    }).then(c => {
      this.staffs = this.parseModel(c);
    });
  }

  applyFilter() {
    if (this.search.length > 0) {
      const filter = this._.filter(this.staffs, s => {
        return (
          s.name.toLowerCase().includes(this.search.toLowerCase()) ||
          s.user_LName.toLowerCase().includes(this.search.toLowerCase()) ||
          s.user_FName.toLowerCase().includes(this.search.toLowerCase())
        );
      }).map(c => c.userId);
      this._.forEach(this.staffs, c => {
        if (filter.indexOf(c.userId) > 0) {
          c.display = true;
        } else {
          c.display = false;
        }
      });
    } else {
      this._.forEach(this.staffs, s => {
        s.display = true;
      });
    }
  }

  parseModel(data: DepartmentUnscheduledStaff[]) {
    if (data) {
      data.forEach(d => {
        const startDt = moment(d.shiftStartDate);
        const endDt = moment(d.shiftEndDate);
        d.shiftStartDate = startDt.toDate();
        d.shiftEndDate = endDt.toDate();
        d.name = d.user_FName + " " + d.user_LName;
        d.weekOf = moment(d.weekOf).toDate();
        d.oTWeekOf = moment(d.oTWeekOf).toDate();
        d.payrollOf = moment(d.payrollOf).toDate();
        d.display = true;
      });
      console.log("parseCalendarModel", data);
    }
    return data;
  }
}
