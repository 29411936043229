








































import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
import { UserModel } from "@/models";
import { DepartmentSelectorComponent } from "@/components/departments";

@Component({
  components: { DepartmentSelectorComponent }
})
export default class Header extends Vue {
  isAuth: boolean =
    localStorage.getItem("token") !== undefined &&
    localStorage.getItem("token") !== null;
  @Auth.State("user")
  private currentUser!: UserModel;

  version!: string | undefined;
  private toggleClick = true;

  @Auth.Action
  private signOut!: () => void;
  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    this.isAuth =
      localStorage.getItem("token") !== undefined &&
      localStorage.getItem("token") !== null;
  }
  logoutPush() {
    this.signOut();
    this.$router.push("/login");
  }
  created() {
    this.version = process.env.VUE_APP_VERSION;
  }
}
