
















import { Component, Vue, Watch, Prop, Ref } from "vue-property-decorator";
import {
  DropdownSelect,
  SortByFilterEnum,
  FilterBase,
  FilterModel
} from "@/models";
import {
  CheckBoxSelection,
  MultiSelect,
  SelectEventArgs
} from "@syncfusion/ej2-vue-dropdowns";
@Component({
  provide: { multiselect: [CheckBoxSelection] }
})
export default class SortFilterComponent extends Vue implements FilterBase {
  @Ref("multiselect") readonly multiselect!: MultiSelect;
  @Prop() filter!: FilterModel;
  @Prop() type!: string;

  dropdownDefaultFields = {
    value: "value",
    text: "text"
  };
  selectedSortBy: string[] | null = null;
  sortBy: DropdownSelect[] = [];
  @Watch("type", { immediate: true, deep: true })
  changeType() {
    this.buidlSortByFilter();
  }
  @Watch("filter", { immediate: true, deep: true })
  changefilter() {
    console.log("sort By filter", this.filter);
    this.setDefaultValue();
  }
  chipSelection(e: SelectEventArgs) {
    console.log("chipSelection", e, this.selectedSortBy);
    // if (this.list) {
    //   this.list.dataSource = this.selectedSortBy as string[];
    // }
  }
  // list?: ListBox;
  // mounted() {
  // if (this.multiselect) {
  //   console.log(
  //     "mounted list",
  //     (this.multiselect as any).ej2Instances.chipCollectionWrapper
  //   );

  //   ((this.multiselect as any).ej2Instances
  //     .chipCollectionWrapper as any).style.display = "none";

  //   this.list = new ListBox(
  //     {
  //       allowDragAndDrop: true,
  //     },
  //     (this.multiselect as any).ej2Instances.chipCollectionWrapper
  //   );
  // }
  // }
  // @Watch("selectedSortBy", { immediate: true, deep: true }) changeSor() {
  //   if (this.list) {
  //     this.list.dataSource = this.selectedSortBy as string[];
  //   }
  // }

  setDefaultValue() {
    if (this.type && this.sortBy.length) {
      if (this.filter && this.filter.sortBy.length) {
        this.selectedSortBy = this.filter.sortBy as string[];
      } else {
        this.selectedSortBy = null;
      }
    }
  }
  validate() {
    console.log("soorBY validate");
  }
  collectValue(): string[] {
    return this.selectedSortBy ?? [];
  }

  clearValue() {
    this.selectedSortBy = [];
  }

  buidlSortByFilter() {
    this.sortBy = [];
    const sortDefault = [
      {
        value: SortByFilterEnum.MemberPositionSortOrder,
        text: "Position Sort Order",
        columnType: "day|weekly|month"
      },
      {
        value: SortByFilterEnum.MemberPosition,
        text: "Position",
        columnType: "day|weekly|month"
      },
      {
        value: SortByFilterEnum.ShiftBeginTime,
        text: "Shift Begin Time",
        columnType: "day|weekly|month"
      },
      {
        value: SortByFilterEnum.PositionCategory,
        text: "Position Category",
        columnType: "day|weekly"
      },
      {
        value: SortByFilterEnum.EmployeeName,
        text: "Employee Name",
        columnType: "day|weekly|month"
      },
      {
        value: SortByFilterEnum.TeamSortOrder,
        text: "Team Sort Order",
        columnType: "day|weekly|month"
      },
      {
        value: SortByFilterEnum.ShiftTypeSortOrder,
        text: "Shift Type Sort Order",
        columnType: "day|weekly"
      }
    ];

    this.sortBy = this._.filter(sortDefault, s => {
      return s.columnType.indexOf(this.type) > -1;
    });
    this.sortBy = this._.sortBy(this.sortBy, "text");

    this.setDefaultValue();
  }
}
