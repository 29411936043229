var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentActiveMenuItem)?_c('side-menu-layout',{attrs:{"id":"side-menu"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('a',{staticClass:"link",on:{"click":_vm.employeeDay}},[_vm._v("Schedule Editor")]),_vm._v("> Add Icon")]},proxy:true},{key:"container",fn:function(){return [_c('StaffActionCardTemplate',{staticStyle:{"margin":"10px 0 40px 0"},attrs:{"data":_vm.currentActiveMenuItem}}),_c('CalendarTemplateComponent',{attrs:{"startDate":_vm.currentActiveMenuItem.startDate,"endDate":_vm.currentActiveMenuItem.endDate,"type":_vm.currentActiveMenuItem.type,"specialDates":_vm.specialDates,"selectedDate":_vm.currentActiveMenuItem.selectedDate}}),_c('ValidationObserver',{ref:"valObs",staticClass:"add-shift",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Icon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Icon:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{ref:"iconDD",attrs:{"dataSource":_vm.icons,"change":_vm.onIconChange,"itemTemplate":_vm.iTemplate,"valueTemplate":_vm.vTemplate,"allowFiltering":true,"cssClass":'icon-drop',"fields":_vm.dropdownDefaultFields,"disabled":_vm.loadingIconRules}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),(_vm.hasIconRules)?_c('div',{staticClass:"rule-block"},[(_vm.isHoursRequired)?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Hours/Day","rules":_vm.hoursRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Hours/Day:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{attrs:{"dataSource":_vm.dataForIcon.hoursTypes,"allowFiltering":true,"fields":_vm.dropdownDefaultFields},model:{value:(_vm.icon.hoursType),callback:function ($$v) {_vm.$set(_vm.icon, "hoursType", $$v)},expression:"icon.hoursType"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}):_vm._e(),(_vm.showSubType)?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Type","rules":_vm.typeRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Type:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{attrs:{"dataSource":_vm.dataForIcon.userScheduleTypeSubtypes,"allowFiltering":true,"fields":_vm.dropdownDefaultFields},model:{value:(_vm.icon.userScheduleTypeSubtype),callback:function ($$v) {_vm.$set(_vm.icon, "userScheduleTypeSubtype", $$v)},expression:"icon.userScheduleTypeSubtype"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}):_vm._e(),(_vm.showPayCodes)?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label"},[_vm._v("Pay Code:")]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{attrs:{"dataSource":_vm.dataForIcon.payCodeDepartments,"allowFiltering":true,"fields":_vm.dropdownInactiveDepFields},model:{value:(_vm.icon.payCodeDepartment),callback:function ($$v) {_vm.$set(_vm.icon, "payCodeDepartment", $$v)},expression:"icon.payCodeDepartment"}})],1)]):_vm._e(),(_vm.showIncentives)?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label"},[_vm._v("Inactive Deparment:")]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{attrs:{"dataSource":_vm.dataForIcon.shiftIncentiveDepartments,"allowFiltering":true,"fields":_vm.dropdownInactiveDepFields},model:{value:(_vm.icon.shiftIncentiveDepartment),callback:function ($$v) {_vm.$set(_vm.icon, "shiftIncentiveDepartment", $$v)},expression:"icon.shiftIncentiveDepartment"}})],1)]):_vm._e(),(_vm.isStartEndRequired)?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Begin Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Begin Time:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-timepicker',{attrs:{"showClearButton":false,"format":'HH:mm',"step":15},model:{value:(_vm.icon.beginTime),callback:function ($$v) {_vm.$set(_vm.icon, "beginTime", $$v)},expression:"icon.beginTime"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}):_vm._e(),(_vm.isStartEndRequired)?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"End Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("End Time:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-timepicker',{attrs:{"showClearButton":false,"format":'HH:mm',"step":15},model:{value:(_vm.icon.endTime),callback:function ($$v) {_vm.$set(_vm.icon, "endTime", $$v)},expression:"icon.endTime"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}):_vm._e(),_c('ValidationProvider',{staticClass:"form-group textarea-block",attrs:{"name":"Note","rules":_vm.noteRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Notes:"),(_vm.isNotesRequired)?_c('sup',[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form-input"},[_c('ejs-textbox',{attrs:{"multiline":true,"cssClass":'multi-notes',"rows":4,"placeHolder":_vm.lblDisplayMessageDesc},model:{value:(_vm.icon.notes),callback:function ($$v) {_vm.$set(_vm.icon, "notes", $$v)},expression:"icon.notes"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1):_vm._e()]}}],null,false,2546755449)})]},proxy:true},{key:"button",fn:function(){return [_c('ejs-button',{staticClass:"green",attrs:{"type":"submit","disabled":_vm.valObs && (_vm.valObs.ctx.dirty || !_vm.valObs.ctx.pristine) && _vm.valObs.ctx.invalid},nativeOn:{"click":function($event){return _vm.onSubmit($event)}}},[_vm._v("SAVE")])]},proxy:true}],null,false,2817221388)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }