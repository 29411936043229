





















import { Component, Vue } from "vue-property-decorator";

@Component
export default class UserEditComponent extends Vue {
  private userIdParams = -1;
  private userIdQuery = -1;
  private title = "";

  created() {
    console.log("this.$route", this.$route);
    if (this.$route.params.id) {
      this.userIdParams = parseInt(this.$route.params.id.toString(), 10);
    }
    if (this.$route.query.id) {
      this.userIdQuery = parseInt(this.$route.query.id.toString(), 10);
    }

    this.title =
      this.userIdParams === -1 && this.userIdQuery === -1
        ? "New User"
        : "Edit User";
    console.log("this.title", this.title, this.userIdParams, this.userIdQuery);
  }
}
