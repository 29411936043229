











import { Component, Vue } from "vue-property-decorator";
import SideMenuLayout from "../../template/side-menu-layout.vue";
@Component({
  components: {
    "side-menu-layout": SideMenuLayout
  }
})
export default class ResetPasswordComponent extends Vue {
  test = "test";
}
