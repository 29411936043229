















import { Component, Mixins } from "vue-property-decorator";
import { BaseSideBarMixin } from "@/mixins";
import { namespace } from "vuex-class";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";
const SideBar = namespace("SideBar");
import { ActionCardTemplate } from "@/common";
import { ShiftEmployeeUpset } from "@/models";
@Component({
  components: {
    "side-menu-layout": SideMenuLayout,
    ActionCardTemplate
  }
})
export default class ActionSchedulerComponent extends Mixins(BaseSideBarMixin) {
  @SideBar.Getter
  private currentActiveMenuItem!: ShiftEmployeeUpset;
}
