import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const SideBar = namespace("SideBar");
import { SideBarType, SideMenuItem, SideMenuItemOptions } from "@/models";
import moment from "moment";
@Component
export default class BaseSideBarMixin extends Vue {
  dropdownDefaultFields = {
    value: "value",
    text: "text"
  };
  loadedState = false;
  @SideBar.Action
  private toggleSideMenu!: (type: SideMenuItem) => void;
  @SideBar.Action
  updateSideMenuOptions!: (type: SideMenuItemOptions) => void;
  closeMenu(options?: object) {
    this.toggleSideMenu({ type: SideBarType.None, item: {}, options });
  }
  toggleSideBarMenu(type: SideMenuItem) {
    this.toggleSideMenu(type);
  }
  updateSideBarOptions(type: SideMenuItemOptions) {
    this.updateSideMenuOptions(type);
  }
  getTimeString(ms: number): string {
    if (ms || ms === 0) {
      return moment
        .utc(ms)
        .format("hh:mm a")
        .toUpperCase();
    } else {
      return "";
    }
  }

  getColor(numberOfEmpReq: number, numberOfEmpHired: number): string {
    return numberOfEmpReq == numberOfEmpHired
      ? "green"
      : numberOfEmpReq > numberOfEmpHired
      ? "red"
      : numberOfEmpReq < numberOfEmpHired
      ? "purple"
      : "";
  }

  getChangedSettings(newData: any, old: any) {
    const changed: any[] = [];
    this._.reduce(
      newData,
      (result: any, value: any, key: any) => {
        return this._.isEqual(value, old[key])
          ? changed
          : changed.push({
              key,
              newValue: value,
              oldValue: old[key]
            });
      },
      []
    );
    return changed;
  }
}
