


















































































































































































































































































import { Component, Mixins, Watch } from "vue-property-decorator";
import { ErrorBag, Field } from "vee-validate";
import { UspEmployeeDetailByUserId } from "@/models/employee-profile/employee.response.model";
import { namespace } from "vuex-class/lib/bindings";
import { stateOptions } from "@/common";
import { DropdownSelect } from "@/models/common/dropdown.model";
import ProfileService from "@/services/ProfileService";
import { SideBarType, SideMenuItem } from "@/models";
import { BaseMixin } from "@/mixins";

const Profile = namespace("Profile");
const SideBar = namespace("SideBar");
@Component
export default class EmployeeDetailsComponent extends Mixins(BaseMixin) {
  @Profile.Getter
  employeeDetails!: UspEmployeeDetailByUserId;

  @SideBar.Action
  private toggleSideMenu!: (type: SideMenuItem) => void;

  form: UspEmployeeDetailByUserId = { ...this.employeeDetails };
  disableForm = true;
  stateOptions = [...stateOptions];
  dropdownFields = {
    value: "value",
    text: "text"
  };
  educationLevelOptions: DropdownSelect[] = [{ value: 1, text: "Diploma" }];
  veeFields!: Field;
  veeErrors!: ErrorBag;

  toggleEditDepartmentSidebar() {
    this.toggleSideMenu({ type: SideBarType.EditEmployeeDetails, item: {} });
  }
  getValidationState(ref: keyof Field) {
    if (this.veeFields && !this.disableForm) {
      if (
        this.veeFields[ref as keyof Field] &&
        (this.veeFields[ref as keyof Field].dirty ||
          this.veeFields[ref as keyof Field].validated)
      ) {
        return !this.veeErrors?.has(ref);
      }
    }
    return null;
  }

  changeState() {
    this.disableForm = !this.disableForm;
  }

  @Watch("employeeDetails")
  updateFormData() {
    this.form = { ...this.employeeDetails };
    this.form.user_Email2;
  }

  getEducationLevelName(id: number) {
    if (id) {
      const currentEducationLevel = this.educationLevelOptions.filter(item => {
        return item.value === id;
      });
      if (currentEducationLevel.length === 1) {
        return currentEducationLevel[0].text;
      }
    }
  }

  getStateName(id: string) {
    if (id) {
      const currentState = this.stateOptions.filter(item => {
        return item.value === id;
      });
      if (currentState.length === 1) {
        return currentState[0].text;
      }
    }
  }
  async created() {
    this.form = { ...this.employeeDetails };
    this.educationLevelOptions = (
      await ProfileService.getEducationLevels()
    ).data;
  }
}
