




































































































































































































/* eslint-disable @typescript-eslint/camelcase */
import { stateOptions } from "@/common";
import { BaseSideBarMixin } from "@/mixins";
import { DropdownSelect } from "@/models";
import { UspEmployeeDetailByUserId } from "@/models/employee-profile/employee.response.model";
import ProfileService from "@/services/ProfileService";
import moment from "moment";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
import SideMenuLayout from "../../template/side-menu-layout.vue";
import {
  ValidationObserver,
  ValidationProvider,
  ValidationObserverInstance
} from "vee-validate";

const Profile = namespace("Profile");
@Component({
  components: {
    "side-menu-layout": SideMenuLayout,
    ValidationObserver,
    ValidationProvider
  }
})
export default class EditEmployeeDetailsComponent extends Mixins(
  BaseSideBarMixin
) {
  stateOptions = [...stateOptions];
  educationLevelOptions: DropdownSelect[] = [{ value: 1, text: "Diploma" }];
  form: Partial<UspEmployeeDetailByUserId> = {};
  @Profile.Getter
  employeeDetails!: UspEmployeeDetailByUserId;
  @Profile.Action
  private updateEmployeeDetailsByUserId!: (
    data: UspEmployeeDetailByUserId
  ) => void;
  @Ref("valObs") readonly valObs!: ValidationObserverInstance;
  async created() {
    this.form = { ...this.employeeDetails };
    this.educationLevelOptions = (
      await ProfileService.getEducationLevels()
    ).data;
  }
  async onSubmit() {
    const isValid = await this.valObs.validate();
    console.log("onSubmit", this.valObs);
    if (!isValid) {
      return;
    }
    this.form.user_HireDate =
      this.form.user_HireDate !== null
        ? moment(this.form.user_HireDate).format("YYYY-MM-DDTHH:mm:ss")
        : null;

    const {
      user_Fname,
      user_Lname,
      user_State,
      user_Email,
      user_Zip,
      user_Phone,
      user_HireDate,
      user_Address1,
      user_Address2,
      user_City,
      educationLevelId,
      badgeID,
      loginDisabled,
      userID,
      user_Email2
    } = this.form;

    const postModel: Partial<UspEmployeeDetailByUserId> = {
      user_Fname,
      user_Lname,
      user_State,
      user_Email,
      user_Email2,
      user_Zip,
      user_Phone,
      user_HireDate,
      user_Address1,
      user_Address2,
      user_City,
      educationLevelId,
      badgeID,
      loginDisabled,
      userID
    };

    this.updateEmployeeDetailsByUserId(postModel as UspEmployeeDetailByUserId);
  }
}
