import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { Login } from "@/components/user/login";
import { Registration } from "@/components/user/registration";
import { ResetPassword } from "@/components/user/resetPassword";
import { BASE_ROUTES } from "../base";
import { NotAuthtScreenComponent } from "@/components/notAuthScreen";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/notAuth",
    name: "base",
    component: NotAuthtScreenComponent,
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login
      },
      {
        path: "/resetpassword",
        name: "ResetPassword",
        component: ResetPassword
      },
      {
        path: "/registration",
        name: "Registration",
        component: Registration
      }
    ]
  },
  ...BASE_ROUTES
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login", "/resetpassword", "/registration"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("token");
  if (authRequired && !loggedIn && loggedIn === null) {
    return next("/login");
  }

  next();
});

export default router;
