import { RouteConfig } from "vue-router";
import DefaultScreenComponent from "./component.vue";
export * from "./component.vue";

export const DEFAULT_SCREEN_ROUTES: RouteConfig[] = [
  {
    path: "**",
    component: DefaultScreenComponent
  },
  {
    path: "",
    component: DefaultScreenComponent
  }
];
