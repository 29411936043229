











































import { Component, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class/lib/bindings";
import { SideBarType, SideMenuItem } from "@/models";
import { UspEmployeeDetailByUserId } from "@/models/employee-profile/employee.response.model";
import { BaseMixin } from "@/mixins";

const SideBar = namespace("SideBar");
const Profile = namespace("Profile");

@Component
export default class EmployeeInfoCardComponent extends Mixins(BaseMixin) {
  @SideBar.Action
  private toggleSideMenu!: (type: SideMenuItem) => void;

  @Profile.Getter
  private employeeDetails!: UspEmployeeDetailByUserId;

  @Profile.Getter
  loadedState!: boolean;
  @Profile.Action
  private updateUserLoginDisabled!: ({
    userID,
    loginDisabled
  }: {
    userID: number;
    loginDisabled: boolean;
  }) => void;
  path = `/employee-profile/${this.$route.params.id}/licenses`;

  randomColor!: string;
  toggleMessageMenu() {
    this.toggleSideMenu({ type: SideBarType.SendNewMessage, item: {} });
  }
  generateRandomColor() {
    this.randomColor = this._.sample(this.backgroundColorArray) ?? "";
  }

  created() {
    this.generateRandomColor();
  }

  updateLoginDisabled() {
    const { loginDisabled, userID } = this.employeeDetails;
    this.updateUserLoginDisabled({ userID, loginDisabled: !loginDisabled });
  }
  get accountStatusClass() {
    return this.employeeDetails.user_Status ? "active" : "disabled";
  }
}
