













































import { Component, Ref, Mixins, Watch } from "vue-property-decorator";
import { HeaderFilter, Spinner } from "@/common";
import { namespace } from "vuex-class";
const CalendarModule = namespace("CalendarModule");
const DepartmentModule = namespace("DepartmentModule");
const SideBar = namespace("SideBar");
const CalendarFilterModule = namespace("CalendarFilterModule");
import {
  WeeklyItemModel,
  DateFilterModel,
  SideMenuItemOptions,
  SideBarType,
  FilterModel,
  SideMenuItem
} from "@/models";
import {
  TimelineMonth,
  TimelineViews,
  Agenda,
  View,
  Resize,
  DragAndDrop,
  EventSettingsModel,
  Schedule,
  EventRenderedArgs
} from "@syncfusion/ej2-vue-schedule";
import { Header, Card, TimeScale } from "./template";
import { BaseCalendarMixin } from "@/mixins";
import moment from "moment";

@Component({
  components: { HeaderFilter, Card, Header, TimeScale, Spinner },
  provide: {
    schedule: [TimelineViews, TimelineMonth, Agenda, Resize, DragAndDrop]
  }
})
export default class DayComponent extends Mixins(BaseCalendarMixin) {
  @Ref("scheduleRef") private scheduleRef!: Schedule;
  @Ref("spinner") private spinner!: Spinner;
  showTab = false;
  selectedItem: object | undefined = {};
  virtualScroll = true;
  allowResizing = false;
  showHeaderBar = false;

  selectedDate: Date = new Date();
  currentView: View = "TimelineDay";
  height = "600px";
  @CalendarFilterModule.Getter
  currentFilter!: FilterModel;
  @SideBar.Getter
  private currentActiveMenuOptions!: SideMenuItemOptions;
  @SideBar.Getter
  private currentActiveMenu!: SideBarType;
  isLoading = false;
  loadingDate!: Date;
  @Watch("isFiltered", { deep: true, immediate: true })
  changeisFiltedData() {
    if (this.isFiltered) {
      this.spinner?.hideSpinner(
        document.getElementById("spinner") as HTMLElement
      );
    }
  }

  @Watch("kanbanData", { deep: true, immediate: true })
  changeisKanbanData() {
    this.updateDataSource();
  }

  @Watch("currentActiveMenuOptions", { immediate: true, deep: true })
  onCurrentActiveMenuChange() {
    if (
      this.currentActiveMenuOptions &&
      this.currentActiveMenuOptions.success &&
      this.currentActiveMenuOptions.reload
    ) {
      this.reload(this.selectedDate);
      // this.resetState();
    }
  }
  @Watch("$route", { immediate: true, deep: true })
  onRouteChange() {
    this.resetState();
  }
  @SideBar.Action
  private toggleSideMenu!: (type: SideMenuItem) => void;

  @CalendarModule.Action
  private loadingShiftsInfoByDateRange!: (
    model: DateFilterModel
  ) => Promise<WeeklyItemModel[]>;
  @DepartmentModule.Getter
  currentDepartmentId!: number;
  private eventSettings: EventSettingsModel = {
    allowAdding: false,
    allowEditing: false,
    allowDeleting: false,
    dataSource: []
  };
  private originalEventSettings: EventSettingsModel = {
    allowAdding: false,
    allowEditing: false,
    allowDeleting: false
  };
  private timeScale: object = {
    enable: true,
    interval: 60,
    slotCount: 4,
    majorSlotTemplate: this.timeScaleTemplate
    // minorSlotTemplate: this.timeScaleTemplate,
  };

  private toggleEdit(value: boolean) {
    this.showTab = value;
  }

  private timeScaleTemplate() {
    return { template: TimeScale };
  }

  private cellTemplate() {
    return {
      template: Card
    };
  }

  private dateHeaderTemplate() {
    return { template: Header };
  }

  mounted() {
    this.type = "day";
    this.$root.$on("open-daily-icons", this.toggleDailyIcons);
    if (this.spinner) {
      this.spinner.createSpinner({
        target: document.getElementById("spinner") as HTMLElement
      });
    }
    this.reload(this.selectedDate);
  }

  @Watch("currentDepartmentId", { immediate: true, deep: true })
  changeDep() {
    this.reload(this.selectedDate);
  }

  onDataBound() {
    if (this.scheduleRef) {
      const time = moment().format("HH:mm");
      this.scheduleRef.scrollTo(time);
    }
  }

  private dateChanged(value: Date) {
    this.selectedDate = value;
    this.reload(this.selectedDate);
  }

  private updateDataSource() {
    console.log("updateDataSource ", this.type, this.kanbanData);
    this.eventSettings = {
      ...this.originalEventSettings,
      dataSource: this.kanbanData
    };
  }

  private async reload(date: Date) {
    if (
      this.currentDepartmentId !== undefined &&
      this.currentDepartmentId !== 0 &&
      (!this.isLoading ||
        (this.loadingDate && !moment(date).isSame(moment(this.loadingDate))))
    ) {
      this.loadingDate = new Date(JSON.parse(JSON.stringify(date)));
      this.isLoading = true;
      this.isFiltered = false;
      if (this.spinner) {
        this.spinner.showSpinner(
          document.getElementById("spinner") as HTMLElement
        );
      }

      const data = this.getStartEndDate(date, 0, this.type);
      data.departmentId = this.currentDepartmentId;
      data.type = this.type;
      await this.loadingShiftsInfoByDateRange(data)
        .then((d: WeeklyItemModel[]) => {
          this.kanbanData = this.parseCalendarModel(d, this.selectedDate);
          this.originalData = JSON.parse(JSON.stringify(this.kanbanData));
          console.warn(this.originalData);
          this.applayCurrentFilters().then(c => {
            this.isFiltered = c;
          });
        })
        .finally(() => {
          if (this.isFiltered) {
            this.isFiltered = false;
          }
          this.isLoading = false;
        });
    }
  }

  async onEventRendered(args: EventRenderedArgs) {
    const style = await args.element.getAttribute("Style");
    const height = style ? style.toString() + "height:44px;" : "height:44px;"; //reduce two pixels from the height of work cells for borders
    args.element.setAttribute("Style", height);
  }

  toggleDailyIcons(data: boolean) {
    console.log("open Daily icons", data);

    const dataToSend = this._.filter(this.kanbanData, c => {
      return c.icons?.length;
    });
    this.toggleSideMenu({
      type: SideBarType.DailyIcons,
      item: dataToSend
    });
  }
}
