



















import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import { IconItem } from "@/models";
import { CardMixin } from "@/mixins";
import { ProfileLogoTemplateComponent } from "@/common/template";
@Component({
  components: { ProfileLogoTemplateComponent }
})
export default class IconCardTemplateComponent extends Mixins(CardMixin) {
  @Prop() image!: IconItem;
  @Watch("image", { immediate: true, deep: true })
  imageChange() {
    console.log("imageChange template", this.image);
  }
}
