















import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { FilterBase, FilterModel, FilterProperties } from "@/models";
@Component({ name: "FilterBySelector" })
export default class FilterBySelectorComponent extends Vue
  implements FilterBase {
  @Prop() filter!: FilterModel;
  @Prop() data!: FilterProperties;

  dropdownDefaultFields = {
    value: "value",
    text: "text"
  };
  selectedBy: string[] | number[] | null = null;

  @Watch("data", { immediate: true, deep: true })
  changeData() {
    this.setDefaultValue();
  }

  @Watch("filter", { immediate: true, deep: true })
  changeFilter() {
    this.setDefaultValue();
  }

  validate() {
    console.log("filter by validate");
  }
  collectValue(): FilterProperties | undefined {
    const val =
      this.selectedBy !== null && this.selectedBy.length
        ? this.selectedBy
        : undefined;
    return val !== undefined
      ? ({ values: val, key: this.data.key } as FilterProperties)
      : undefined;
  }
  clearValue() {
    this.selectedBy = [];
  }
  setDefaultValue() {
    if (this.data && this.filter && this.filter.filterBy.length) {
      const val = this._.find(this.filter.filterBy, f => {
        return f.key == this.data.key;
      });

      if (val && val.values.length) {
        this.selectedBy = val.values;
      } else {
        this.selectedBy = null;
      }
    }
  }
}
