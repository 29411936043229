import httpAuth from "../common/http/http-common";

class DepartmentService {
  getDepartmentsByEmployee(userId: number) {
    return httpAuth().get(`/department/DepartmentsByEmployee`, {
      params: {
        userId
      }
    });
  }
}

export default new DepartmentService();
