













import { Component, Vue, Ref } from "vue-property-decorator";
import { DepartmentEmployeeModel, UserModel } from "@/models";
import { DropDownList, ChangeEventArgs } from "@syncfusion/ej2-vue-dropdowns";
import { namespace } from "vuex-class";
const DepartmentModule = namespace("DepartmentModule");
const Auth = namespace("Auth");
import { ValueTemplate } from "./template";
import { setTime } from "@syncfusion/ej2-vue-schedule";

@Component({ components: { ValueTemplate } })
export default class DepartmentSelectorComponent extends Vue {
  @Ref("dep") private departmet!: DropDownList;
  originalData: DepartmentEmployeeModel[] = [];
  dropDownValue!: DepartmentEmployeeModel;
  value = 0;
  fieldColumns = {
    groupBy: "locationName",
    text: "companyDepartmentName",
    value: "companyDepartmentId"
  };
  dataLoaded = false;
  @DepartmentModule.Action
  getDepartmentByEmployee!: (
    userId: number
  ) => Promise<DepartmentEmployeeModel[]>;
  @Auth.Getter
  private currentUser!: UserModel;

  @DepartmentModule.Action
  changeDepartment!: (dep: object) => Promise<void>;

  async mounted() {
    await this.getDepartmentByEmployee(this.currentUser.userId).then(data => {
      console.log("getDepartmentByEmployee", data);
      if (data.length) {
        this.dropDownValue = data[0];
        this.value = this.dropDownValue.companyDepartmentId;
        this.changeDepartment(this.dropDownValue);
      }
      this.originalData = data;
      this.groupData();

      this.dataLoaded = true;
    });
  }

  private groupData() {
    this._.orderBy(this.originalData, [
      "locationName",
      "companyDepartmentName"
    ]);
  }

  valueTemplate() {
    return {
      template: ValueTemplate
    };
  }

  valueChange(value: ChangeEventArgs) {
    if (value.itemData) {
      this.changeDepartment(value.itemData);
    }
  }
}
