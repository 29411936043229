import {
  Component,
  Ref,
  Emit,
  Prop,
  Mixins,
  Watch
} from "vue-property-decorator";
import moment from "moment";
import { DatePicker, ChangedEventArgs } from "@syncfusion/ej2-vue-calendars";
import BaseMixin from "./baseMixin";
import { namespace } from "vuex-class";
const CalendarFilterModule = namespace("CalendarFilterModule");
@Component
export default class FilterMixin extends Mixins(BaseMixin) {
  @CalendarFilterModule.Action
  changeDate!: (date: Date) => Promise<void>;
  @CalendarFilterModule.Action
  changeType!: (tyoe: string) => Promise<void>;
  @CalendarFilterModule.Action
  private changeNoOfPeriods!: (rows: number) => void;
  @CalendarFilterModule.Action
  private changeNoOfTotals!: (rows: number) => void;
  @CalendarFilterModule.Action
  private changeNoOfStatistics!: (rows: number) => void;
  @Prop({ default: "weekly" }) type!: string;
  @Ref("datepicker") dtPIcker!: DatePicker;
  selectedWeek = "";
  selectedDay = "";
  selectedMonth = "";
  selectedScheduler = "";
  weekStartDay = 0;
  originalDate: Date = new Date();
  isFilterOpen = false;
  isMounted = true;

  start = "Motnh";
  depth = "Year";
  format = "dd MMMM y";

  @Watch("type")
  changeCurrentType() {
    this.changeType(this.type);
    console.log("changeType", this.type);
    if (this.type === "month") {
      this.start = "Year";
      this.depth = "Year";
      this.format = "MMMM y";
    } else if (
      this.type === "day" ||
      this.type === "weekly" ||
      this.type === "scheduler"
    ) {
      this.start = "Motnh";
      this.depth = "Year";
      this.format = "dd MMMM y";
    }
  }
  mounted() {
    this.isMounted = true;
    this.changeValueForDate(this.originalDate);
  }

  toggleFilterBody() {
    console.log("toggleFilterBody");
    this.isFilterOpen = !this.isFilterOpen;
  }

  @Emit("date-changed")
  dateChaged() {
    this.resetState();
    return this.originalDate;
  }

  dtChange(e: ChangedEventArgs) {
    if (e.value) {
      this.isMounted = false;
      const val = new Date(e.value);
      this.changeValueForDate(val);
    }
  }
  changeValueForDate(value: Date) {
    if (!moment(value).isSame(moment(this.originalDate)) || this.isMounted) {
      this.isMounted = false;
      this.originalDate = new Date(JSON.parse(JSON.stringify(value)));
      const date = this.getWeekStart(new Date(value), this.weekStartDay);
      this.selectedWeek = `Week of ${date}`;
      this.selectedDay = moment(value).format("dddd MM/DD/YYYY");
      this.selectedMonth = moment(value).format("MMMM YYYY");
      this.selectedScheduler = moment(value).format("MMMM DD, YYYY");
      this.changeDate(this.originalDate);
      this.dateChaged();
    }
  }
  changeValue(value: number) {
    const addValue =
      this.type === "weekly" || this.type === "scheduler"
        ? "w"
        : this.type === "month"
        ? "M"
        : "d";
    const newValue = moment(this.originalDate)
      .add(addValue, value)
      .toDate();
    this.changeValueForDate(newValue);
    if (this.type === "scheduler") {
      this.changeNoOfPeriods(0);
      this.changeNoOfTotals(0);
      this.changeNoOfStatistics(0);
    }
  }
}
