




import { Component, Vue } from "vue-property-decorator";
import {
  createSpinner,
  showSpinner,
  setSpinner,
  hideSpinner,
  SpinnerArgs,
  SetSpinnerArgs,
  SpinnerType
} from "@syncfusion/ej2-popups";
@Component
export default class SpinnerComponent extends Vue {
  defaultSettings = {
    label: "Loading...",
    type: "Bootstrap"
  };
  hideSpinner(element: HTMLElement) {
    hideSpinner(element);
  }
  showSpinner(element: HTMLElement) {
    showSpinner(element);
  }
  createSpinner(spinnerArgs: SpinnerArgs) {
    createSpinner({
      target: spinnerArgs.target,
      label: spinnerArgs.label ?? this.defaultSettings.label,
      cssClass: spinnerArgs.cssClass,
      template: spinnerArgs.template,
      type: spinnerArgs.type ?? (this.defaultSettings.type as SpinnerType),
      width: spinnerArgs.width
    });
  }

  setSpinner(spinnerArgs: SetSpinnerArgs) {
    setSpinner({
      cssClass: spinnerArgs.cssClass,
      template: spinnerArgs.template,
      type: spinnerArgs.type
    });
  }
}
