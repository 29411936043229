import { RouteConfig } from "vue-router";
import { DayComponent } from "@/components/calendar/day";

const path = "/day";

export const DAY_ROUTES: RouteConfig[] = [
  {
    path,
    name: "DayComponent",
    component: DayComponent
  }
];
