import { RouteConfig } from "vue-router";
import { SchedulerComponent } from "@/components/calendar/scheduler";

const path = "/scheduler";

export const SCHEDULER_ROUTES: RouteConfig[] = [
  {
    path,
    name: "SchedulerComponent",
    component: SchedulerComponent
  }
];
