import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class User extends VuexModule {
  name = "";

  get nameUpperCase() {
    return this.name.toUpperCase();
  }

  @Mutation
  setName(newName: string): void {
    this.name = newName;
  }

  @Action
  updateName(newName: string): void {
    console.log("New Name: " + newName);
    this.context.commit("setName", newName);
  }
}
