var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('side-menu-layout',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Add Shift")]},proxy:true},{key:"container",fn:function(){return [_c('ValidationObserver',{ref:"valObs",staticClass:"add-shift",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Date:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-datepicker',{attrs:{"format":'MM/dd/yyyy',"change":_vm.onDateChange,"enabled":!_vm.loadingShiftType,"showClearButton":false},model:{value:(_vm.model.shiftDate),callback:function ($$v) {_vm.$set(_vm.model, "shiftDate", $$v)},expression:"model.shiftDate"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Shift Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Shift Type:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{attrs:{"dataSource":_vm.shiftTypes,"fields":_vm.localShiftFields,"change":_vm.onShiftTypeChange,"allowFiltering":true},model:{value:(_vm.model.shiftTypeId),callback:function ($$v) {_vm.$set(_vm.model, "shiftTypeId", $$v)},expression:"model.shiftTypeId"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Start Time"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Start Time:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-textbox',{attrs:{"disabled":""},model:{value:(_vm.model.shiftBeginTime),callback:function ($$v) {_vm.$set(_vm.model, "shiftBeginTime", $$v)},expression:"model.shiftBeginTime"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"End Time"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("End Time:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-textbox',{attrs:{"disabled":""},model:{value:(_vm.model.shiftEndTime),callback:function ($$v) {_vm.$set(_vm.model, "shiftEndTime", $$v)},expression:"model.shiftEndTime"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('div',{staticClass:"add-position"},[_c('b-button',{staticClass:"add-pos",attrs:{"variant":"link","disabled":_vm.isPosionChange},on:{"click":_vm.addNewPosition}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v("Add Posotion")]),(_vm.positionError.length)?_c('div',{staticClass:"form-group"},[_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.positionError))])]):_vm._e()],1),_c('div',{staticClass:"positions-layout"},_vm._l((_vm.model.positions),function(pos,index){return (!pos.isDeleted)?_c('div',{key:pos.positionId,staticClass:"pos"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Position","rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ejs-dropdownlist',{attrs:{"dataSource":_vm.positions,"fields":_vm.localPositionFields,"change":_vm.onPositionChange,"allowFiltering":true,"open":_vm.onOpen},model:{value:(pos.positionId),callback:function ($$v) {_vm.$set(pos, "positionId", $$v)},expression:"pos.positionId"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Requirements","rules":"required|numeric|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-input"},[_c('ejs-textbox',{on:{"change":function($event){return _vm.changeReqired(pos)}},model:{value:(pos.numberOfEmpReq),callback:function ($$v) {_vm.$set(pos, "numberOfEmpReq", $$v)},expression:"pos.numberOfEmpReq"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('div',{staticClass:"remove-btn"},[_c('b-button',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.removePosition(pos, index)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])],1)],1):_vm._e()}),0)]}}])})]},proxy:true},{key:"button",fn:function(){return [_c('ejs-button',{staticClass:"green",attrs:{"type":"submit","disabled":_vm.loadingShiftType || !_vm.isModelCahnge || (_vm.valObs && (_vm.valObs.ctx.dirty || !_vm.valObs.ctx.pristine) && _vm.valObs.ctx.invalid)},nativeOn:{"click":function($event){return _vm.onSubmit($event)}}},[_vm._v("SAVE")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }