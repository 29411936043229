


















































































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { BaseSideBarMixin } from "@/mixins";
import { namespace } from "vuex-class";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";
const SideBar = namespace("SideBar");
const ShiftModule = namespace("ShiftModule");
const CalendarModule = namespace("CalendarModule");
const DepartmentModule = namespace("DepartmentModule");
const Toast = namespace("ToastModule");
import {
  IconEmployeeCardTemplate,
  CalendarTemplateComponent
} from "@/common";
import {
  ChangeEventArgs as ChangeEventArgsDD
} from "@syncfusion/ej2-vue-dropdowns";
import {
  DepartmentEmployeeModel,
  SchedulerActionModel,
  BaseResponse,
  SideBarType,
  SideMenuItemOptions,
  DropdownSelectWithImage,
  ShiftEmployeeDropDownRequest,
  ShiftEmployeeDropDownData,
  ShiftEmployeeEditIconData,
  UserScheduleTypeGroupEnum,
  IconItemUpset,
  ToastOptions
} from "@/models";
import {
  ValidationObserver,
  ValidationProvider,
  ValidationObserverInstance
} from "vee-validate";
import {
  createSpinner,
  showSpinner,
  hideSpinner
} from "@syncfusion/ej2-popups";
import moment from "moment";
@Component({
  components: {
    "side-menu-layout": SideMenuLayout,
    ValidationObserver,
    ValidationProvider,
    IconEmployeeCardTemplate,
    CalendarTemplateComponent,
    //IconDropDownTemplate
  }
})
export default class EditEmployeeIconComponent extends Mixins(
  BaseSideBarMixin
) {
  @Ref("valObs") readonly valObs!: ValidationObserverInstance;

  dataForIcon: ShiftEmployeeEditIconData = {
    userScheduleTypeSubtypes: [],
    hoursTypes: []
  };
  specialDates: Record<string, any>[] = []; // fix
  selectIcon!: DropdownSelectWithImage;
  loadingIconRules = false;
  hasIconRules = false;
  showIncentives = false;
  showPayCodes = false;
  showWorkRule = false;
  showSubType = false;
  typeRules = "";
  isNotesRequired = false;
  noteRules = "";
  isHoursRequired = false;
  hoursRules = "";
  isStartEndRequired = false;
  lblDisplayMessageDesc = "";
  lblDisplayHoursMessage = "";
  icon: IconItemUpset = {};
  amount?: number;
  model?: any;

  @Toast.Action
  private showToast!: (toastOptions: ToastOptions) => void;

  @SideBar.Getter
  private currentActiveMenuItem!: any;

  @DepartmentModule.Getter
  currentDepartment!: DepartmentEmployeeModel;

  @ShiftModule.Action
  getShiftEmployeeEditIconData!: (
    model: ShiftEmployeeDropDownRequest
  ) => Promise<ShiftEmployeeEditIconData>;

  @CalendarModule.Getter
  isShowError!: boolean;
  @CalendarModule.Action
  cleanError!: () => Promise<void>;

  @Watch("currentActiveMenuItem", { immediate: true, deep: true })
  async changeCurrentActiveMenuItem() {
    console.log("currentActiveMenuItem change", this.currentActiveMenuItem);
    this.currentActiveMenuItem.actions[
      this.currentActiveMenuItem.selected
    ].name = this.currentActiveMenuItem.name;
    this.currentActiveMenuItem.actions[this.currentActiveMenuItem.selected][
      "user_FName"
    ] = this.currentActiveMenuItem["user_FName"];
    this.currentActiveMenuItem.actions[this.currentActiveMenuItem.selected][
      "user_LName"
    ] = this.currentActiveMenuItem["user_LName"];
    this.model = {
      ...this.currentActiveMenuItem.actions[this.currentActiveMenuItem.selected]
    };
  }

  async mounted() {
    if (this.valObs) {
      this.valObs.reset();
    }
    const { createSpinner, showSpinner, hideSpinner } = this.$globalLoader;
    createSpinner([
      {
        target: document.getElementById("side-menu") as HTMLElement
      }
    ]);
    await this.getDropDownData();
  }
  async getDropDownData() {
    showSpinner(document.getElementById("side-menu") as HTMLElement);
    const selected = this.currentActiveMenuItem?.selected ?? -1;
    const icon = this.currentActiveMenuItem.actions[selected] as any;
    this.icon = {
      userScheduleType: icon.userScheduleType,
      notes: icon.userScheduleNotes,
      beginTime: icon.scheduleBeginTime,
      endTime: icon.scheduleEndTime,
      hoursType: icon.userScheduleHours,
      userScheduleTypeSubtype: icon.userSubtype,
      scheduleDate: icon.scheduleDate
    };
    await this.getShiftEmployeeEditIconData({
      departmentId: this.currentDepartment.companyDepartmentId,
      userScheduleTypeId: this.icon?.userScheduleType ?? 0 // fix
    })
      .then(res => {
        console.log("responmse dta", res, UserScheduleTypeGroupEnum.Inactive);
        this.dataForIcon.userScheduleTypeSubtypes =
          res.userScheduleTypeSubtypes;
        this.dataForIcon.hoursTypes = res.hoursTypes;
        this.dataForIcon.iconRules = res.iconRules;
        this.dataForIcon.shiftIncentiveDepartments =
          res.shiftIncentiveDepartments;
        this.dataForIcon.payCodeDepartments = res.payCodeDepartments;
        this.dataForIcon.reassignCategories = res.reassignCategories;
        this.applyIconRules();
        this.hasIconRules = true;
      })
      .finally(() => {
        hideSpinner(document.getElementById("side-menu") as HTMLElement);
      });
  }
  async onSubmit() {
    // fix
    console.log("on submit");
    const isValid = await this.valObs.validate();
    if (!isValid) {
      return;
    }
  }

  private employeeDay() {
    this.toggleSideBarMenu({
      type: SideBarType.EmployeeDay,
      item: this.currentActiveMenuItem
    });
  }
  private applyIconRules() {
    if (this.dataForIcon.iconRules) {
      this.showIncentives =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Inactive &&
        (this.dataForIcon.iconRules.isDepartmentRequired ?? false);
      this.showPayCodes =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Paycode &&
        (this.dataForIcon.iconRules.isDepartmentRequired ?? false);
      this.showWorkRule =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
        UserScheduleTypeGroupEnum.Workrule;
      this.isStartEndRequired =
        this.dataForIcon.iconRules.isStartEndTimeRequired ?? false;
      this.isHoursRequired =
        this.dataForIcon.iconRules.isHoursRequired ?? false;
      this.showSubType = this.dataForIcon.userScheduleTypeSubtypes.length > 1;
      this.isNotesRequired =
        this.dataForIcon.iconRules.isNotesRequired ?? false;
      this.lblDisplayMessageDesc = this.dataForIcon.iconRules.messageDesc;
      this.lblDisplayHoursMessage = this.dataForIcon.iconRules.hoursMessage;
      this.amount =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Inactive ||
        (this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Paycode &&
          (this.dataForIcon.iconRules.isDepartmentRequired ?? false))
          ? this.dataForIcon.iconRules.amount
          : undefined;
      this.typeRules = this.showSubType ? "required" : "";
      this.hoursRules = this.isHoursRequired ? "required" : "";
      this.noteRules = this.isNotesRequired ? "required" : "";
      if (this.valObs) {
        this.valObs.reset();
      }
      setTimeout(() => {
        this.hasIconRules = true;
      }, 0);
    } else {
      this.hasIconRules = false;
    }
  }
}
