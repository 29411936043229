












import { Component, Mixins } from "vue-property-decorator";
import { IconItem, SideBarType, SideMenuItem } from "@/models";
import { namespace } from "vuex-class";
const SideBar = namespace("SideBar");
import { CardMixin } from "@/mixins";
import {
  ProfileLogoTemplateComponent,
  ShiftTypeButtonTemplateComponent
} from "@/common/template";
@Component({
  components: {
    ProfileLogoTemplateComponent,
    ShiftTypeButtonTemplateComponent
  }
})
export default class WeeklyCard extends Mixins(CardMixin) {
  @SideBar.Action
  private toggleSideMenu!: (type: SideMenuItem) => void;

  cardClick(event: MouseEvent) {
    console.log("card click", event);

    this.toggleSideMenu({
      type: this.data.isOpenShift
        ? SideBarType.AddStaffToShift
        : SideBarType.AddEmployeeShift,
      item: this.data
    });
  }
  navigateToNextDay(value: number) {
    this.$root.$emit("updated-next-day-from-card", value);
  }

  cardIconClick(event: MouseEvent, icon: IconItem) {
    console.log("card icon click", event);
    event.preventDefault();
    event.stopPropagation();
    this.toggleSideMenu({
      type: SideBarType.UpsetIcon,
      item: this.data,
      subItem: icon
    });
  }
  toggleAddDepartmentMenu() {
    this.toggleSideMenu({
      type: SideBarType.AddEmployeeShift,
      item: this.data
    });
  }
}
