
























import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { HeaderFilter, Spinner } from "@/common";
import {
  WeeklyItemModel,
  WeeklyItemModelByKey,
  DateFilterModel,
  WeeklyItemModelByKeyItem,
  FilterModel
} from "@/models";
import { CardGroup } from "./template";
import { namespace } from "vuex-class";
const CalendarModule = namespace("CalendarModule");
const DepartmentModule = namespace("DepartmentModule");
const CalendarFilterModule = namespace("CalendarFilterModule");
import { BaseCalendarMixin } from "@/mixins";
import moment from "moment";

@Component({
  components: {
    HeaderFilter,
    CardGroup,
    Spinner
  }
})
export default class WeeklyComponent extends Mixins(BaseCalendarMixin) {
  @Ref("spinner") private spinner!: Spinner;
  private selectedItem: object | undefined = {};
  @CalendarModule.Action
  private loadingShiftsInfoByDateRange!: (
    model: DateFilterModel
  ) => Promise<WeeklyItemModel[]>;
  @DepartmentModule.Getter
  currentDepartmentId!: number;
  private data: WeeklyItemModelByKey[] = [];
  private selectedDate: Date = new Date();
  @CalendarFilterModule.Getter
  currentFilter!: FilterModel;
  mounted() {
    this.type = "weekly";
    if (this.spinner) {
      this.spinner.createSpinner({
        target: document.getElementById("spinner") as HTMLElement
      });
    }
    this.reload(this.selectedDate);
  }

  @Watch("isFiltered", { deep: true, immediate: true })
  changeisFiltedData() {
    if (this.isFiltered) {
      this.spinner?.hideSpinner(
        document.getElementById("spinner") as HTMLElement
      );
    }
  }

  @Watch("kanbanData", { deep: true, immediate: true })
  changeisKanbanData() {
    console.log("change kanban", this.isFiltered);
    if (this.isFiltered) {
      this.groupData();
    }
  }

  @Watch("currentDepartmentId", { immediate: true, deep: true })
  changeDep() {
    this.reload(this.selectedDate);
  }
  private openEditShift(data: WeeklyItemModel) {
    console.log("data", data);
    this.selectedItem = data;
  }

  private groupData() {
    this.data = [];
    const sort =
      this.currentSortBy.length > 0 ? this.currentSortBy : "shiftDateDt";
    const tempData = this._.orderBy(this.kanbanData, sort);
    const groupedData = this._.chain(tempData)
      .groupBy("shiftDateDt")
      .map((value, key) => ({
        key: moment(key).format("ddd MM/DD"),
        childs: this.groupChilds(value, "shiftType", false, 1),
        isParent: true,
        level: 0,
        type: "shiftDateDt"
      }))
      .value();

    this.data = groupedData;

    console.log(groupedData);
  }

  private groupChilds(
    data: WeeklyItemModel[],
    topKey: string,
    stop: boolean,
    level: number
  ): WeeklyItemModelByKeyItem[] {
    const sort =
      this.currentSortBy.length > 0
        ? [...topKey, ...this.currentSortBy]
        : [topKey, "StartTime"];

    data = this._.orderBy(data, sort);
    const childLevel = level + 1;
    const groupData = this._.chain(data)
      .groupBy(topKey)
      .map((value, key) => {
        // const changeKey = key;
        const val = value[0];
        // if (topKey === "shiftType" && val) {
        //   changeKey = `${key} (${val.shiftBeginTime} - ${val.shiftEndTime})`;
        // } else if (topKey === "memberPosition" && val) {
        //   changeKey = `${key} (${val.numberOfEmpHired}/${val.numberOfEmpReq})`;
        // }
        const data = {
          key: key,
          numberOfEmpReq: val.numberOfEmpReq,
          numberOfEmpHired: val.numberOfEmpHired,
          isParent: !stop,
          childs: [],
          items: [],
          level: level,
          type: topKey,
          shiftBeginTime: val.shiftBeginTime,
          shiftEndTime: val.shiftEndTime
        } as WeeklyItemModelByKeyItem;
        if (!stop) {
          data.items = this.groupChilds(
            value,
            "memberPosition",
            true,
            childLevel
          );
        } else {
          data.childs = value;
        }
        return data;
      })
      .value();
    return groupData;
  }

  private dateChanged(value: Date) {
    this.selectedDate = value;
    this.reload(this.selectedDate);
  }

  private reload(date: Date) {
    if (this.currentDepartmentId) {
      this.isFiltered = false;
      if (this.spinner) {
        this.spinner.showSpinner(
          document.getElementById("spinner") as HTMLElement
        );
      }

      const data = this.getStartEndDate(date, 6, this.type);
      data.departmentId = this.currentDepartmentId;
      data.type = this.type;
      this.loadingShiftsInfoByDateRange(data).then((d: WeeklyItemModel[]) => {
        console.log("daa", d);
        this.kanbanData = this.parseCalendarModel(d, this.selectedDate);
        this.originalData = JSON.parse(JSON.stringify(this.kanbanData));
        this.applayCurrentFilters().then(() => {
          this.groupData();
        });
      });
    }
  }
}
