import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import AuthService from "@/services/AuthService";
import { UserModel, RegistrationModel } from "@/models";

const storedUser = localStorage.getItem("user");

@Module({ namespaced: true })
class Auth extends VuexModule {
  status = storedUser ? { loggedIn: true } : { loggedIn: false };
  user = storedUser ? JSON.parse(storedUser) : null;
  failureMessage = "";

  @Mutation
  failure(error: string): void {
    this.failureMessage = error;
  }
  @Mutation
  loginSuccess(): void {
    this.status.loggedIn = true;
    const newUser = localStorage.getItem("user");
    this.user = newUser ? JSON.parse(newUser) : null;
  }

  @Mutation
  loginFailure(): void {
    this.status.loggedIn = false;
    this.user = null;
  }

  @Mutation
  logout(): void {
    this.status.loggedIn = false;
    this.user = null;
  }

  @Mutation
  registerSuccess(): void {
    this.status.loggedIn = false;
  }

  @Mutation
  registerFailure(): void {
    this.status.loggedIn = false;
  }

  @Action({ rawError: true })
  async login(data: UserModel) {
    this.context.commit("failure", "");
    try {
      const response = await AuthService.login(data.username, data.password);
      console.log("loginSuccess", response);
      this.context.commit("loginSuccess");
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error login: ", message);
      this.context.commit("failure", message);
    }
  }

  @Action
  signOut(): void {
    AuthService.logout();
    this.context.commit("logout");
  }

  @Action({ rawError: true })
  register(data: RegistrationModel) {
    this.context.commit("registerFailure", "");
    return AuthService.register(data.username, data.email, data.password).then(
      response => {
        this.context.commit("registerSuccess", response.data);
      },
      error => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.context.commit("registerFailure", message);
      }
    );
  }

  get isLoggedIn(): boolean {
    return this.status.loggedIn;
  }
  get currentUser(): UserModel {
    return this.user;
  }
  get errorMessage(): string {
    return this.failureMessage;
  }
}

export default Auth;
