












import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { addClass } from "@syncfusion/ej2-base";
import { namespace } from "vuex-class";
const CalendarModule = namespace("CalendarModule");
import moment from "moment";

@Component
export default class CalendarTemplateComponent extends Vue {
  @Prop() startDate!: Date;
  @Prop() endDate!: Date;
  @Prop() type!: string;
  @Prop() specialDates!: Record<string, any>[];
  @Prop() selectedDate!: Date;

  @CalendarModule.Action
  private changeDates!: (dates: Date[]) => void;
  @CalendarModule.Getter
  private currentDates!: Date[];

  @Watch("specialDates")
  onSpecialDatesChange() {
    this.changeDates([...this.currentDates]);
  }

  onValueChange(args: any) {
    if (
      !args.values
        .map((value: Date) => moment(value).format("YYYY-MM-DD"))
        .includes(moment(this.selectedDate).format("YYYY-MM-DD"))
    ) {
      args.values.push(moment(this.selectedDate).toDate());
    }
    if (args.name == "change") {
      this.changeDates([...args.values]);
    }
  }
  private load(args: any) {
    const specialDate = this.specialDates.find(
      key => key?.date === moment(args.date).format("YYYY-MM-DD")
    ) as any;
    if (specialDate !== undefined) {
      addClass([args.element.firstElementChild], ["highlight", specialDate.color]);
    }
    if (args.date < this.startDate || args.date >= this.endDate) {
      args.isDisabled = true;
    }
  }
}
