import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { WeeklyItemModel } from "@/models";
@Component
export default class CardMixin extends Vue {
  dropdownDefaultFields = {
    value: "value",
    text: "text"
  };
  @Prop() data!: WeeklyItemModel;
  @Prop() color!: string;
  @Prop() type!: string;
  _color!: string;
  name = "";
  logoName = "";

  @Watch("data", { immediate: true, deep: true })
  dataChange() {
    if (!this.color && this.data) {
      this._color =
        this.data.numberOfEmpReq == this.data.numberOfEmpHired
          ? "green"
          : this.data.numberOfEmpReq > this.data.numberOfEmpHired
          ? "red"
          : this.data.numberOfEmpReq < this.data.numberOfEmpHired
          ? "purple"
          : "";
    }
    if (this.data) {
      this.name = this.data.isOpenShift
        ? "Open Shift"
        : this.data.user_FName + " " + this.data.user_LName;
      const fName =
        this.data.user_FName != null
          ? this.data.user_FName.substring(0, 1)
          : "";
      const lName =
        this.data.user_LName != null
          ? this.data.user_LName.substring(0, 1)
          : "";
      this.logoName = fName + lName;
    }
  }

  colorArray: string[] = [
    "#6262ef",
    "#db5656",
    "#ef7be4",
    "#e8a370",
    "#7add7d",
    "#af8fd1"
  ];
  getColor() {
    return this.color !== undefined ? this.color : this._color;
  }
  getRundomColor(data = true, name: string) {
    let sum = 0;
    for (let i = 0 ; i < name?.length ; i++) {
      sum += name?.charCodeAt(i);
    }
    return !this.data.isOpenShift && data && name
      ? `background: ${this.colorArray[sum % this.colorArray.length]}`
      : "";
  }
}
