import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import UserService from "@/services/UserService";
import { UserListModel } from "@/models";

@Module({ namespaced: true })
class User extends VuexModule {
  items: UserListModel[] = [];
  @Mutation
  getAllSuccess(users: UserListModel[]): void {
    console.log("getAllSuccess", users);
    this.items = users;
  }
  @Mutation
  getSuccess(user: UserListModel): void {
    console.log("getSuccess", user);
  }
  @Mutation
  failure(user: string): void {
    console.log("failure", user);
  }

  @Action({ rawError: true })
  async loadingUsers() {
    try {
      const response = await UserService.getAll();
      this.context.commit("getAllSuccess", response.data);
      return response;
    } catch (error) {
      this.context.commit("failure", error);
    }
    // return await UserService.getAll()
    //   .then(users => {
    //     console.log("users", users);
    //     this.context.commit("getAllSuccess", users);
    //   })
    //   .catch(error => {
    //     this.context.commit("failure", error);
    //   });
  }

  @Action({ rawError: true })
  loadingUserById(id: number) {
    return UserService.get(id).then(
      users => {
        this.context.commit("getSuccess", users);
      },
      error => {
        this.context.commit("failure", error);
      }
    );
  }

  get users(): UserListModel[] {
    return this.items;
  }
}

export default User;
