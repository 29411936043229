

































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { BaseCalendarMixin } from "@/mixins";
import { namespace } from "vuex-class";
const CalendarModule = namespace("CalendarModule");
const DepartmentModule = namespace("DepartmentModule");
import { HeaderFilter } from "@/common";
import {
  WeeklyItemModel,
  DateFilterModel,
  MonthItemModelByKey,
  DepartmentEmployeeModel
} from "@/models";
import moment from "moment";
import {
  Month,
  View,
  Resize,
  DragAndDrop,
  EventSettingsModel,
  CellClickEventArgs,
  Schedule
} from "@syncfusion/ej2-vue-schedule";
import { Event } from "./template";
@Component({
  components: {
    HeaderFilter,
    Event
  },
  provide: {
    schedule: [Resize, DragAndDrop, Month]
  }
})
export default class MonthComponent extends Mixins(BaseCalendarMixin) {
  @Ref("scheduleRef") private scheduleRef!: Schedule;
  private selectedItem: object | undefined = {};
  private virtualScroll = true;
  private allowResizing = false;
  private showHeaderBar = false;
  private selectedDate: Date = new Date();
  private currentView: View = "Month";
  @CalendarModule.Action
  private loadingShiftsInfoByDateRange!: (
    model: DateFilterModel
  ) => Promise<WeeklyItemModel[]>;
  @DepartmentModule.Getter
  currentDepartmentId!: number;
  private eventSettings: EventSettingsModel = {
    allowAdding: false,
    allowEditing: false,
    allowDeleting: false,
    dataSource: []
  };
  private originalEventSettings: EventSettingsModel = {
    allowAdding: false,
    allowEditing: false,
    allowDeleting: false
  };

  private data: MonthItemModelByKey[] = [];
  mounted() {
    this.type = "month";
    this.reload(this.selectedDate);
  }

  @Watch("currentDepartmentId", { immediate: true, deep: true })
  changeDep() {
    this.reload(this.selectedDate);
  }

  private eventTemplate() {
    return {
      template: Event
    };
  }
  private groupData() {
    this.data = [];
    console.log("groupData", this.kanbanData);
    this._.orderBy(this.kanbanData, "shiftDateDt");
    const groupedData = this._.chain(this.kanbanData)
      .groupBy("shiftDateDt")
      .map((value, key) => ({
        key: moment(key).format("MM/DD/YYYY"),
        StartTime: moment(key).toDate(),
        EndTime: moment(key).toDate(),
        childs: value
      }))
      .value();
    this.data = groupedData;

    this._.forEach(this.data, d => {
      const filteredData = this._.filter(this.kanbanData, f => {
        return (
          moment(moment(f.shiftEndDate).format("MM/DD/YYYY")).isSame(
            moment(d.key)
          ) &&
          moment(moment(f.shiftEndDate).format("MM/DD/YYYY")).isAfter(
            moment(moment(f.shiftStartDate).format("MM/DD/YYYY"))
          )
        );
      });
      if (filteredData.length) {
        d.childs = [...d.childs, ...filteredData];
        this._.orderBy(d.childs, "shiftStartDate");
      }
    });

    console.log(groupedData);

    this.updateDataSource();
  }

  private dateChanged(value: Date) {
    this.selectedDate = value;
    this.reload(this.selectedDate);
  }
  private cellClick(e: CellClickEventArgs) {
    console.log("cellClick", e);
    return;
  }
  private updateDataSource() {
    this.eventSettings = {
      ...this.originalEventSettings,
      dataSource: this.data
    };
  }

  private reload(date: Date) {
    if (this.currentDepartmentId) {
      const data = this.getStartEndDate(date, 0, this.type);
      console.log(date, data);
      data.departmentId = this.currentDepartmentId;
      data.type = this.type;
      data.userId = Number(this.$route.query.userId);
      this.loadingShiftsInfoByDateRange(data).then((d: WeeklyItemModel[]) => {
        this.kanbanData = this.parseCalendarModel(d, this.selectedDate);
        this.groupData();
      });
    }
  }
}
