import { RouteConfig } from "vue-router";
import SearchStaffComponent from "./component.vue";
const path = "/search-staff";

export const STAFF_ROUTES: RouteConfig[] = [
  {
    path,
    name: "Search Staff",
    component: SearchStaffComponent
  }
];
