
























import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
import { UserModel } from "@/models";

@Component
export default class ForgottenPassword extends Vue {
  private user: UserModel = { username: "", password: "" } as UserModel;
  private message = "";
  private loading = false;

  @Auth.Getter
  private isLoggedIn!: boolean;

  // @Auth.Action
  // private resetPassword!: (data: any) => Promise<any>;

  private regUser() {
    this.loading = true;
    this.$validator.validateAll().then((isValid: boolean) => {
      if (!isValid) {
        this.loading = false;
        return;
      }

      if (this.user.username && this.user.password) {
        // this.resetPassword(this.user).then(
        // 	(data) => {
        // 		console.log('data login', data);
        // 		this.$router.push('/');
        // 	},
        // 	(error) => {
        // 		this.loading = false;
        // 		this.message = error;
        // 	}
        // );
      }
    });
  }
}
