






























































































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { BaseSideBarMixin } from "@/mixins";
import { namespace } from "vuex-class";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";
const SideBar = namespace("SideBar");
const ShiftModule = namespace("ShiftModule");
const CalendarModule = namespace("CalendarModule");
const DepartmentModule = namespace("DepartmentModule");
const Toast = namespace("ToastModule");
import {
  StaffActionCardTemplate,
  CalendarTemplateComponent,
  IconDropDownTemplate
} from "@/common";
import {
  ChangeEventArgs as ChangeEventArgsDD,
  DropDownList
} from "@syncfusion/ej2-vue-dropdowns";
import {
  DepartmentEmployeeModel,
  SchedulerActionModel,
  BaseResponse,
  SideBarType,
  SideMenuItemOptions,
  DropdownSelectWithImage,
  ShiftEmployeeDropDownRequest,
  ShiftEmployeeDropDownData,
  ShiftEmployeeEditIconData,
  UserScheduleTypeGroupEnum,
  IconItemUpset,
  ToastOptions
} from "@/models";
import {
  ValidationObserver,
  ValidationProvider,
  ValidationObserverInstance
} from "vee-validate";
import {
  createSpinner,
  showSpinner,
  hideSpinner
} from "@syncfusion/ej2-popups";
import moment from "moment";
@Component({
  components: {
    "side-menu-layout": SideMenuLayout,
    ValidationObserver,
    ValidationProvider,
    StaffActionCardTemplate,
    CalendarTemplateComponent,
    IconDropDownTemplate
  }
})
export default class AddEmployeeIconComponent extends Mixins(BaseSideBarMixin) {
  iTemplate() {
    return {
      template: IconDropDownTemplate
    };
  }
  vTemplate() {
    return {
      template: IconDropDownTemplate
    };
  }
  @Ref("valObs") readonly valObs!: ValidationObserverInstance;
  @Ref("iconDD") readonly iconDD!: DropDownList;

  icons: DropdownSelectWithImage[] = [];
  dataForIcon: ShiftEmployeeEditIconData = {
    userScheduleTypeSubtypes: [],
    hoursTypes: []
  };
  specialDates: Record<string, any>[] = []; // fix
  selectIcon!: DropdownSelectWithImage;
  loadingIconRules = false;
  hasIconRules = false;
  showIncentives = false;
  showPayCodes = false;
  showWorkRule = false;
  showSubType = false;
  typeRules = "";
  isNotesRequired = false;
  noteRules = "";
  isHoursRequired = false;
  hoursRules = "";
  isStartEndRequired = false;
  lblDisplayMessageDesc = "";
  lblDisplayHoursMessage = "";
  icon?: IconItemUpset;
  amount?: number;
  model?: SchedulerActionModel;

  @Toast.Action
  private showToast!: (toastOptions: ToastOptions) => void;

  @SideBar.Getter
  private currentActiveMenuItem!: SchedulerActionModel;

  @DepartmentModule.Getter
  currentDepartment!: DepartmentEmployeeModel;

  @ShiftModule.Action
  getShiftEmployeeDropDownData!: (
    model: ShiftEmployeeDropDownRequest
  ) => Promise<ShiftEmployeeDropDownData>;
  @ShiftModule.Action
  getShiftEmployeeEditIconData!: (
    model: ShiftEmployeeDropDownRequest
  ) => Promise<ShiftEmployeeEditIconData>;

  @CalendarModule.Getter
  isShowError!: boolean;
  @CalendarModule.Action
  cleanError!: () => Promise<void>;

  @Watch("currentActiveMenuItem", { immediate: true, deep: true })
  async changeCurrentActiveMenuItem() {
    console.log("currentActiveMenuItem change", this.currentActiveMenuItem);
    this.model = { ...this.currentActiveMenuItem };
  }

  async mounted() {
    if (this.valObs) {
      this.valObs.reset();
    }
    const { createSpinner, showSpinner, hideSpinner } = this.$globalLoader;
    createSpinner([
      {
        target: document.getElementById("side-menu") as HTMLElement
      }
    ]);
    await this.getDropDownData();
  }
  async getDropDownData() {
    showSpinner(document.getElementById("side-menu") as HTMLElement);
    await this.getShiftEmployeeDropDownData({
      userId: this.currentActiveMenuItem.userId,
      departmentId: this.currentDepartment.companyDepartmentId,
      locationId: this.currentDepartment.companyDepartmentLocationId
    })
      .then(r => {
        console.log("getShiftEmployeeDropDownData: ", r);
        this.icons = [];
        this.icons.push({
          imageUrl: "",
          value: -1,
          text: ""
        });
        this.icons = [...this.icons, ...r.iconsData];
      })
      .finally(() => {
        hideSpinner(document.getElementById("side-menu") as HTMLElement);
      });
  }
  async onSubmit() {
    // fix
    console.log("on submit");
    const isValid = await this.valObs.validate();
    if (!isValid) {
      return;
    }
  }

  private employeeDay() {
    this.toggleSideBarMenu({
      type: SideBarType.EmployeeDay,
      item: this.currentActiveMenuItem
    });
  }
  private onIconChange(args: ChangeEventArgsDD) {
    this.hasIconRules = false;
    this.checkIfIconExist();
    console.log("onIconChange", args);
    if (args.itemData && args.itemData.value) {
      const value = parseInt(args.itemData.value.toString(), 10);
      this.selectIcon = {
        value: value,
        text: args.itemData.text as string,
        imageUrl: ""
      };
      if (value == -1) {
        if (this.icon) {
          this.icon.userScheduleType = null;
          this.icon.userScheduleTypeName = "";
        }
        this.iconDD.clear();
        args.cancel = true;
        this.dataForIcon.userScheduleTypeSubtypes = [];
        this.dataForIcon.hoursTypes = [];
        this.dataForIcon.shiftIncentiveDepartments = [];
        this.dataForIcon.payCodeDepartments = [];
        this.dataForIcon.iconRules = undefined;
        this.dataForIcon.reassignCategories = [];
        this.applyIconRules();
        return;
      }
      this.loadingIconRules = true;
      if (this.icon) {
        this.icon.userScheduleType = value;
        this.icon.userScheduleTypeName = args.itemData.text;
      }
      showSpinner(document.getElementById("side-menu") as HTMLElement);
      this.getShiftEmployeeEditIconData({
        departmentId: this.currentDepartment.companyDepartmentId,
        userScheduleTypeId: value
      })
        .then(res => {
          console.log("responmse dta", res, UserScheduleTypeGroupEnum.Inactive);
          this.dataForIcon.userScheduleTypeSubtypes =
            res.userScheduleTypeSubtypes;
          this.dataForIcon.hoursTypes = res.hoursTypes;
          this.dataForIcon.iconRules = res.iconRules;
          this.dataForIcon.shiftIncentiveDepartments =
            res.shiftIncentiveDepartments;
          this.dataForIcon.payCodeDepartments = res.payCodeDepartments;
          this.dataForIcon.reassignCategories = res.reassignCategories;
          this.applyIconRules();
        })
        .finally(() => {
          this.loadingIconRules = false;
          hideSpinner(document.getElementById("side-menu") as HTMLElement);
        });
    }
  }
  private applyIconRules() {
    if (this.dataForIcon.iconRules) {
      this.showIncentives =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Inactive &&
        (this.dataForIcon.iconRules.isDepartmentRequired ?? false);
      this.showPayCodes =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Paycode &&
        (this.dataForIcon.iconRules.isDepartmentRequired ?? false);
      this.showWorkRule =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
        UserScheduleTypeGroupEnum.Workrule;
      this.isStartEndRequired =
        this.dataForIcon.iconRules.isStartEndTimeRequired ?? false;
      this.isHoursRequired =
        this.dataForIcon.iconRules.isHoursRequired ?? false;
      this.showSubType = this.dataForIcon.userScheduleTypeSubtypes.length > 1;
      this.isNotesRequired =
        this.dataForIcon.iconRules.isNotesRequired ?? false;
      this.lblDisplayMessageDesc = this.dataForIcon.iconRules.messageDesc;
      this.lblDisplayHoursMessage = this.dataForIcon.iconRules.hoursMessage;
      this.amount =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Inactive ||
        (this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Paycode &&
          (this.dataForIcon.iconRules.isDepartmentRequired ?? false))
          ? this.dataForIcon.iconRules.amount
          : undefined;
      this.typeRules = this.showSubType ? "required" : "";
      this.hoursRules = this.isHoursRequired ? "required" : "";
      this.noteRules = this.isNotesRequired ? "required" : "";
      if (this.valObs) {
        this.valObs.reset();
      }
      setTimeout(() => {
        this.hasIconRules = true;
      }, 0);
    } else {
      this.hasIconRules = false;
    }
  }
  private checkIfIconExist() {
    if (!this.icon) {
      this.icon = {
        userScheduleType: null,
        hoursType: undefined,
        payCodeDepartment: undefined,
        shiftIncentiveDepartment: undefined,
        userScheduleTypeSubtype: undefined,
        beginTime: undefined,
        endTime: undefined
      };
    }
  }
}
