









import ProfileLogoTemplateComponent from "@/common/template/profile-logo/component.vue";
import IconTypeButtonTemplateComponent from "@/common/template/icon-type-template/component.vue";
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import { CardMixin } from "@/mixins";
@Component({
  components: {
    ProfileLogoTemplateComponent,
    IconTypeButtonTemplateComponent
  }
})
export default class IconEmployeeCardTemplate extends Mixins(CardMixin) {}
