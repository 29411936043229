

















import { Component, Vue, Prop, Emit } from "vue-property-decorator";
@Component
export default class Increment extends Vue {
  @Prop() min!: number;
  @Prop() max!: number;
  @Prop() startValue!: number;
  @Prop() endValue!: number;
  @Prop() color!: string;
  @Emit("value-change")
  valueChange(value: number) {
    return value;
  }
  private increment(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    const val = parseInt(this.endValue.toString(), 10) + 1;
    this.valueChange(val);
  }
  private decrement(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    let val = parseInt(this.endValue.toString(), 10) - 1;
    if (this.min && this.min > val) {
      val = this.min;
    }
    console.log("decrement", val, this.min, this.min > val);
    this.valueChange(val);
  }
}
