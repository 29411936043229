
















import { Component, Mixins, Prop } from "vue-property-decorator";
import { CardMixin } from "@/mixins";
import { namespace } from "vuex-class";
const SideBar = namespace("SideBar");
import { SideMenuItem, SideBarType } from "@/models";
@Component({
  components: {}
})
export default class ShiftTypeButtonTemplateComponent extends Mixins(
  CardMixin
) {
  @Prop() canEdit!: boolean;

  @SideBar.Action
  private toggleSideMenu!: (type: SideMenuItem) => void;
  @SideBar.Getter
  private currentActiveMenuItem!: any;

  private openEditShift(event: any) {
    this.$emit("manage-action", event);
  }
}
