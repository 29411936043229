



















import { BaseMixin } from "@/mixins";
import { Component, Mixins } from "vue-property-decorator";
@Component
export default class UserTemplate extends Mixins(BaseMixin) {
  randomColor!: string;

  generateRandomColor() {
    this.randomColor = this._.sample(this.backgroundColorArray) ?? "";
  }

  created() {
    this.generateRandomColor();
  }

  toEmployeeProfile(userId: number) {
    return `/employee-profile/${userId}/details`;
  }
}
