




























































































































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { BaseSideBarMixin } from "@/mixins";
import { namespace } from "vuex-class";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";
import CommonService from "@/services/CommonService";
const SideBar = namespace("SideBar");
const ShiftModule = namespace("ShiftModule");
const DepartmentModule = namespace("DepartmentModule");
const Toast = namespace("ToastModule");
import { ChangeEventArgs } from "@syncfusion/ej2-vue-calendars";
import {
  ChangeEventArgs as ChangeEventArgsDD,
  PopupEventArgs,
  DropDownList
} from "@syncfusion/ej2-vue-dropdowns";
import {
  MemeberPostion,
  ShiftInsertModel,
  DepartmentEmployeeModel,
  ShiftType,
  ShiftTypeRequest,
  ShiftPostionInsertModel,
  BaseResponse,
  ShiftInformation,
  ShiftPosition,
  SideBarType,
  DropdownSelectNumber,
  UspShiftIncentiveByShiftId,
  Lookup,
  LookupTypeEnum,
  ToastOptions
} from "@/models";
import {
  createSpinner,
  showSpinner,
  hideSpinner
} from "@syncfusion/ej2-popups";
import {
  ValidationObserver,
  ValidationProvider,
  ValidationObserverInstance
} from "vee-validate";

import moment from "moment";
import { Increment } from "@/common";
import { Dialog } from "@syncfusion/ej2-vue-popups";
import { indexOf } from "node_modules/@types/lodash";

@Component({
  components: {
    "side-menu-layout": SideMenuLayout,
    ValidationObserver,
    ValidationProvider,
    Increment
  }
})
export default class EditShiftComponent extends Mixins(BaseSideBarMixin) {
  target = ".main-content";
  @Ref("valObs") readonly valObs!: ValidationObserverInstance;
  @Ref("posDD") readonly posDD!: DropDownList;
  @Ref("incDD") readonly incDD!: DropDownList;
  @Ref("confirmDialog") readonly confirmDialog!: Dialog;

  private confirmDlgButtons = [
    { click: this.closePopover, buttonModel: { content: "Close" } }
  ];
  private confirmPositionButtons = [
    {
      click: this.deletePosition,
      buttonModel: { content: "Yes", isPrimary: true }
    },
    { click: this.closePopover, buttonModel: { content: "No" } }
  ];
  private confirmAttributeButtons = [
    {
      click: this.deleteAttribute,
      buttonModel: { content: "Yes", isPrimary: true }
    },
    { click: this.closePopover, buttonModel: { content: "No" } }
  ];
  private confirmHeader = "Delete Item`(s)";
  private animationSettings = { effect: "None" };
  private confirmContent = "";

  private loadingShiftType = false;
  private positionError = "";
  private localShiftFields = { text: "shiftType", value: "shiftTypeId" };
  private localAmountFields = { text: "display", value: "lookupId" };
  private localPositionFields = { text: "memberPosition", value: "memberPositionId" };

  private currentShiftId = 0;
  private currentDeleteItem?: number;

  private shiftTypes: ShiftType[] = [];
  private positions: MemeberPostion[] = [];
  private attributes: DropdownSelectNumber[] = [];
  private amountLookup: Lookup[] = [];

  private selectedPos?: MemeberPostion;
  private selectedAtt: DropdownSelectNumber = {
    value: 0,
    text: "All"
  };

  private selectedShiftType!: ShiftType;
  private isModelCahnge = false;

  private isPosionChange = false;
  private isAttributeChange = false;
  private isAttributesOpen = true;

  @SideBar.Getter
  private currentActiveMenuItem!: ShiftInformation;

  private model: ShiftInformation = { ...this.currentActiveMenuItem };
  private originalModel!: ShiftInformation;

  @Toast.Action
  private showToast!: (toastOptions: ToastOptions) => void;

  @DepartmentModule.Getter
  private currentDepartment!: DepartmentEmployeeModel;
  @ShiftModule.Action
  private getShiftType!: (model: ShiftTypeRequest) => Promise<ShiftType[]>;
  @ShiftModule.Action
  private shiftUpsetInformation!: (model: ShiftInformation) => Promise<BaseResponse>;
  @ShiftModule.Action
  private getShiftInformation!: (shiftId: number) => Promise<ShiftInformation>;
  @ShiftModule.Getter
  private isShowError!: boolean;
  @ShiftModule.Action
  private cleanError!: () => Promise<void>;
  @ShiftModule.Action
  private getPositions!: (departmentId: number) => Promise<MemeberPostion[]>;
  @ShiftModule.Action
  private deleteEmployeeShift!: (shiftEmpReqId: number) => Promise<BaseResponse>;
  @ShiftModule.Action
  private deleteShiftAttribute!: (attributeId: number) => Promise<BaseResponse>;

  @Watch("model", { immediate: true, deep: true })
  private modelChange() {
    console.log("model change", this.model);
    this.checkChange();
  }
  @Watch("currentActiveMenuItem", { immediate: true, deep: true })
  private changeCurrentActiveMenuItem() {
    console.log("currentActiveMenuItem change", this.currentActiveMenuItem);
    this.setCurrentShiftId();
  }

  mounted() {
    const { createSpinner, showSpinner, hideSpinner } = this.$globalLoader;
    createSpinner([
      {
        target: document.getElementById("side-menu") as HTMLElement
      }
    ]);
    this.model = { ...this.currentActiveMenuItem };
    this.getRefreshShiftTypes();
    this.getRefreshPositions();
    this.loadLookup();
    if (this.valObs) {
      this.valObs.reset();
    }
  }

  private async onSubmit() {
    console.log("onSubmit", this.model);
    const isValid = await this.valObs.validate();
    if (!isValid) {
      return;
    }
    const validPositions = this.checkIfPositionSelected();
    if (!validPositions) {
      this.positionError = "Please select at least one position";
      return;
    }
    const sendModel = JSON.parse(JSON.stringify(this.model));
    console.log(sendModel);
    if (sendModel.attributes) {
      this._.forEach(sendModel.attributes, a => {
        a.numberUsersRequired = parseInt(a.numberUsersRequired.toString(), 10);
        a.amount = parseFloat((a.amount as number).toString());
        console.log("a", a);
      });
    }

    this.shiftUpsetInformation(sendModel).then(() => {
      if (this.isShowError) {
        this.cleanError();
        this.showToast({
          title: "Error",
          content: "Error occurred",
          cssClass: "e-toast-danger"
        });
      } else {
        this.showToast({
          title: "Success",
          content: "Shift save success",
          cssClass: "e-toast-success"
        });
        this.closeMenu({
          success: true,
          reload: true
        });
      }
    });
  }
  private async refresh() {
    if (
      this.currentActiveMenuItem &&
      this.currentActiveMenuItem.shiftId &&
      this.currentActiveMenuItem.shiftId > 0
    ) {
      this.loadedState = true;
      await this.getShiftInformation(
        this.currentActiveMenuItem.shiftId as number
      )
        .then(c => {
          console.log("refresh shift info", c);
          const data = this.parseResponse(c);
          this.originalModel = this.parseResponse(
            JSON.parse(JSON.stringify(c))
          );
          this.model = data;
          this.addFormData();

          if (this.valObs) {
            this.valObs.reset();
          }
        })
        .finally(() => {
          this.loadedState = false;
        });
    }
  }
  private async setCurrentShiftId() {
    if (
      this.currentShiftId == 0 ||
      (this.currentActiveMenuItem &&
        this.currentActiveMenuItem.shiftId &&
        this.currentActiveMenuItem.shiftId != 0 &&
        this.currentShiftId != this.currentActiveMenuItem.shiftId)
    ) {
      this.loadedState = true;
      console.log(
        "setCurrentUserId: ",
        this.currentShiftId,
        this.currentActiveMenuItem.shiftId
      );
      this.currentShiftId = parseInt(
        JSON.stringify(
          JSON.parse(this.currentActiveMenuItem.shiftId.toString())
        ),
        10
      );
      await this.refresh();
      this.loadedState = false;
    }
  }
  private deleteAttribute() {
    console.log("deleteAttribute");
    if (this.currentDeleteItem) {
      this.deleteShiftAttribute(this.currentDeleteItem).then(c => {
        if (c.success) {
          this.removeAttribute(this.currentDeleteItem as number);

          this.currentDeleteItem = undefined;
          if (this.confirmDialog) {
            this.confirmDialog.hide();
          }
        } else {
          console.error("error", c.message);
        }
      });
    }
  }
  private deletePosition() {
    console.log("deletePosition");
    if (this.currentDeleteItem) {
      this.deleteEmployeeShift(this.currentDeleteItem).then(c => {
        if (c.success) {
          this.removePosition(this.currentDeleteItem as number);

          this.currentDeleteItem = undefined;
          if (this.confirmDialog) {
            this.confirmDialog.hide();
          }

          this.updateSideBarOptions({
            success: true,
            reload: true
          });
        } else {
          console.error("error", c.message);
        }
      });
    }
  }
  private removePosition(shiftEmpReqId: number) {
    if (this.model.positions) {
      const index = this.model.positions?.findIndex(c => {
        return c.shiftEmpReqId == shiftEmpReqId;
      });

      if (this.model.positions && (isNaN(index) || index == 0) && index > -1) {
        this.model.positions.splice(index, 1);
      }
    }
    this.isPosionChange = !this.checkINewPOsition();

    console.log("removePosition", this.model.positions);
  }
  private deletePositionPopover(e: MouseEvent, pos: ShiftPosition) {
    e.preventDefault();
    e.stopPropagation();
    console.log("delete poover", pos);
    if (pos.isAdded) {
      this.removePosition(pos.shiftEmpReqId as number);
    } else {
      this.confirmDlgButtons = this.confirmPositionButtons;
      this.confirmContent =
        "<span>Are you sure you want to remove this Requirement from this shift?</span>";
      this.currentDeleteItem = pos.shiftEmpReqId;
      if (this.confirmDialog) {
        this.confirmDialog.show();
      }
    }
  }
  private removeAttribute(attributeId: number) {
    if (this.model.attributes) {
      const index = this.model.attributes?.findIndex(c => {
        return c.attributeId == attributeId;
      });
      if (
        this.model.attributes.length &&
        (isNaN(index) || index == 0) &&
        index > -1
      ) {
        this.model.attributes.splice(index, 1);
      }
    }
    console.log("removeAttribute", this.model.attributes);
  }
  private deleteAttributePopover(e: MouseEvent, att: UspShiftIncentiveByShiftId) {
    e.preventDefault();
    e.stopPropagation();
    console.log("delete poover", att);
    if (att.isAdded) {
      this.removeAttribute(att.attributeId);
    } else {
      this.confirmDlgButtons = this.confirmAttributeButtons;
      this.confirmContent =
        "<span>Are you sure you want to remove this Attribute from this shift?</span>";
      this.currentDeleteItem = att.attributeId;
      if (this.confirmDialog) {
        this.confirmDialog.show();
      }
    }
  }
  private closePopover() {
    console.log("closePopover");
    if (this.confirmDialog) {
      this.confirmDialog.hide();
    }
  }
  private valueChange(pos: ShiftPosition, value: number) {
    pos.numberOfEmpReq = value;
    pos.color = this.getColor(
      pos.numberOfEmpReq as number,
      pos.numberOfEmpHired as number
    );
    pos.isUpdated = true;
  }
  private loadLookup() {
    CommonService.getLookup(LookupTypeEnum.Amount).then(c => {
      console.log("amountLookup", c.data);
      this.amountLookup = c.data;
    });
  }
  private checkIfPositionSelected() {
    if (!this.model.positions || !this.model.positions.length) {
      //At least one position need to be selected.
      return false;
    }
    return true;
  }
  private onOpen(e: PopupEventArgs) {
    const allItems = e.popup.element.querySelectorAll(".e-list-item");
    if (allItems.length && this.model.positions) {
      const ids = this.model.positions
        .filter(c => {
          return c.positionId && !c.isDeleted;
        })
        .map(c => c.positionId as number);
      this._.forEach(allItems, item => {
        const filt = this._.find(ids, i => {
          return i !== null && i.toString() === item.getAttribute("data-value");
        });
        if (filt) {
          if (!item.classList.contains("e-hide")) {
            item.classList.add("e-hide");
          }
        } else {
          item.classList.remove("e-hide");
        }
      });
    }
  }
  private onAttributeChange(e: ChangeEventArgsDD, att: UspShiftIncentiveByShiftId) {
    e.cancel = true;
    let attributeTypeId = 4;
    let keyValue = this.model.shiftId;
    if (att.positionId != 0) {
      const posVal = this._.find(this.model.positions, p => {
        return p.positionId === att.positionId;
      });

      console.log(
        "onAttributeChange",
        this.model.positions,
        posVal,
        att.positionId
      );
      if (posVal) {
        keyValue = posVal.shiftEmpReqId as number;
      }
      attributeTypeId = 2;
    }

    att.attributeTypeId = attributeTypeId;
    att.attributeKeyId = keyValue;
    att.isUpdated = true;
  }
  private onIncentiveChange(e: ChangeEventArgsDD) {
    console.log("onIncentiveChange", e);
    if (e && e.itemData) {
      const val = e.itemData;
      this.selectedAtt = {
        value: parseInt(val.value as string, 10),
        text: val.text as string
      };
    }
    this.isAttributeChange = false;
    if (this.incDD) {
      this.incDD.clear();
    }
  }
  private onPositionChange(e: ChangeEventArgsDD) {
    console.log("onPositionChange", e);

    if (e && e.itemData) {
      const val = e.itemData as MemeberPostion;
      this.selectedPos = val;
    }
    this.isPosionChange = false;
  }
  private checkINewPOsition(): boolean {
    const newItems = this._.filter(this.model.positions, p => {
      return p.positionId === null;
    });
    return newItems.length === 0;
  }
  private addNewAttribute(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (this.model.attributes) {
      let attributeTypeId = 4;
      let keyValue = this.model.shiftId;
      if (this.selectedAtt.value != 0) {
        const posVal = this._.find(this.model.positions, p => {
          return p.positionId == this.selectedAtt.value;
        });
        if (posVal) {
          keyValue = posVal.shiftEmpReqId as number;
        }
        attributeTypeId = 2;
      }

      this.model.attributes.push({
        isDeleted: false,
        description: "",
        attributeId:
          this.model.attributes.length === 0
            ? 0
            : -this.model.attributes.length,
        attributeTypeId: attributeTypeId,
        attributeKeyId: keyValue,
        attributeLinkTypeLookupId: -1,
        numberUsersRequired: 0,
        attributePrimaryTable: "",
        attributeType: "",
        color: "",
        primaryKeyName: "",
        numberUsersHired: 0,
        isAdded: true,
        isUpdated: false,
        positionId: this.selectedAtt.value,
        positionName: this.selectedAtt.text,
        amountLookupId: 1000,
        statusLookupId: 2001
      });
      // this.isAttributeChange = true;
    }
  }
  private openAttributes(e: MouseEvent) {
    console.log("openAttributes");
    e.preventDefault();
    e.stopPropagation();
    this.isAttributesOpen = !this.isAttributesOpen;
  }
  private addNewPosition(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (this.model.positions && this.selectedPos) {
      this.isPosionChange = true;
      this.positionError = "";
      this.model.positions.push({
        positionName: this.selectedPos.memberPosition,
        positionId: this.selectedPos.memberPositionId,
        numberOfEmpReq: 0,
        numberOfEmpHired: 0,
        color: this.getColor(0, 0),
        isAdded: true,
        isUpdated: false,
        isDeleted: false,
        shiftEmpReqId: -this.model.positions.length
      });

      this.selectedPos = undefined;
      if (this.posDD) {
        this.posDD.clear();
      }
    }
    console.log("Add new Position", this.model);
  }
  private onShiftTypeChange(args: ChangeEventArgsDD) {
    if (args.itemData) {
      this.selectedShiftType = args.itemData as ShiftType;
      this.model.shiftBeginTime = (args.itemData as ShiftType).shiftTimeOfDayStart;
      this.model.shiftEndTime = (args.itemData as ShiftType).shiftTimeOfDayEnd;
    }
    console.log(
      "onShiftTypeChange",
      this.selectedShiftType.shiftPeriodStartTime
    );
  }
  private getRefreshPositions() {
    if (this.currentDepartment && this.currentDepartment.companyDepartmentId) {
      this.getPositions(this.currentDepartment.companyDepartmentId).then(
        positions => {
          this.positions = positions;
          hideSpinner(document.getElementById("side-menu") as HTMLElement);
        }
      );
    }
  }
  private getRefreshShiftTypes() {
    showSpinner(document.getElementById("side-menu") as HTMLElement);
    if (this.model.shiftDate) {
      this.shiftTypes = [];
      this.loadingShiftType = true;

      this.resetFormValidation();

      this.getShiftType({
        departmentId: this.currentDepartment.companyDepartmentId,
        shiftDate: new Date(this.model.shiftDate)
      })
        .then(st => {
          this.shiftTypes = st;
          console.log(" this.shiftTypes", this.shiftTypes);
        })
        .finally(() => {
          this.loadingShiftType = false;
          console.log("finaly after sfift type get");
        });
    }
  }
  private resetFormValidation() {
    this.model.shiftBeginTime = "";
    this.model.shiftEndTime = "";
    this.model.shiftTypeId = undefined;

    setTimeout(() => {
      if (this.valObs) {
        this.valObs.reset();
      }
    }, 0);
  }
  private parseResponse(d: ShiftInformation): ShiftInformation {
    if (d) {
      const startDt = moment(d.shiftStartDate);
      const endDt = moment(d.shiftEndDate);
      d.shiftDateDt = moment(d.shiftDate).toDate();
      d.shiftStartDate = startDt.toDate();
      d.shiftEndDate = endDt.toDate();
      if (d.positions && d.positions.length) {
        this._.forEach(d.positions, p => {
          p.color = this.getColor(
            p.numberOfEmpReq as number,
            p.numberOfEmpHired as number
          );
          p.isUpdated = false;
          p.isDeleted = false;
        });
      } else {
        d.positions = [];
      }
      if (d.attributes && d.attributes.length) {
        this._.forEach(d.attributes, a => {
          a.isUpdated = false;
          a.isDeleted = false;
        });
      } else {
        d.attributes = [];
      }
    }

    return d;
  }
  private addFormData() {
    this.attributes = [];
    this.attributes.push({
      value: 0,
      text: "All"
    });
    if (this.model && this.model.positions) {
      this._.forEach(this.model.positions, p => {
        this.attributes.push({
          value: p.positionId as number,
          text: p.positionName as string
        });
      });
    }
  }
  private checkChange() {
    if (this.model && this.originalModel) {
      const data = this.getChangedSettings(this.model, this.originalModel);
      console.log(data);
      this.isModelCahnge = data.length > 0;
    }
  }
  private back() {
    this.toggleSideBarMenu({
      type: SideBarType.EmployeeDay,
      item: this.currentActiveMenuItem
    });
  }
}
