


















import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import { CardMixin } from "@/mixins";
@Component
export default class ProfileLogoTemplateComponent extends Mixins(CardMixin) {
  @Prop() useColor!: boolean;
  @Emit("icon-click") emitIconClick(event: MouseEvent) {
    return event;
  }
  iconClick(event: MouseEvent) {
    this.emitIconClick(event);
  }
}
