



















import { Component, Vue } from "vue-property-decorator";
// import TestForm from "@/components/TestForm.vue";
// import { HubConnectionBuilder } from "@aspnet/signalr";
import { namespace } from "vuex-class";
const TaskHub = namespace("TaskHub");

// const connection = new HubConnectionBuilder()
//   .withUrl("http://localhost:8080/api/notify-hub")
//   .build();

@Component({
  components: {
    // TestForm,
  }
})
export default class Home extends Vue {
  private tasks: TaskItem[] = [];
  private curretnTaskId = 1;
  addTask() {
    // connection.invoke("AddTask", {
    //   id: this.curretnTaskId,
    //   desc: `Starting laundry task ${this.curretnTaskId}`,
    //   done: false,
    // });
    this.AddTask(this.curretnTaskId);
    this.curretnTaskId++;
  }
  @TaskHub.Action
  private AddTask!: (curretnTaskId: number) => Promise<void>;
  @TaskHub.Action
  private StartHub!: () => Promise<void>;
  @TaskHub.Action
  private StopHub!: () => Promise<void>;
  @TaskHub.Action
  private TaskIsDone!: (callback: (task: TaskItem) => void) => Promise<void>;
  @TaskHub.Action
  private AddedTask!: (callback: (task: TaskItem) => void) => Promise<void>;
  mounted() {
    console.info("mounted");
    this.StartHub();
    // connection.start();
    this.TaskIsDone(this.taskIsDone);
    this.AddedTask(this.addedTask);
    // connection.on("AddedTask", (task: TaskItem) => {
    //   this.tasks.push(task);
    // });
    // connection.on("TaskIsDone", (task: TaskItem) => {
    //   this._.filter(this.tasks, (t: TaskItem) => {
    //     return t.id === task.id;
    //   })[0].done = task.done;
    // });
  }

  navToProfile() {
    this.$router.push("/employee-profile");
  }

  addedTask(task: TaskItem) {
    if (task !== undefined) {
      this.tasks.push(task);
    }
  }
  taskIsDone(task: TaskItem) {
    if (task !== undefined) {
      this._.filter(this.tasks, (t: TaskItem) => {
        return t.id === task.id;
      })[0].done = task.done;
    }
  }
  beforeDestroy() {
    console.info("beforeDestroy");
    this.StopHub();
    // connection.stop();
  }
}

interface TaskItem {
  id: number;
  desc: string;
  done: boolean;
}
