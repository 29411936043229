
















import { Component, Vue, Watch } from "vue-property-decorator";
import EmployeeInfoCardComponent from "./employee-info-card/component.vue";
import EmployeeProfileNavComponent from "@/components/employee-profile/employee-profile-nav-card/component.vue";
import SideMenuComponent from "@/common/side-bars/component.vue";
import { namespace } from "vuex-class/lib/bindings";
import EmployeeActionCardComponent from "./employee-action-card/component.vue";
import EmployeeDetailsComponent from "./main-content/employee-details/component.vue";
import DepartmentAssignmentsComponent from "./main-content/department-assignmnets/component.vue";
import EmployeeLicensesComponent from "./main-content/employee-licenses/component.vue";

const SideBar = namespace("SideBar");
const Profile = namespace("Profile");
@Component({
  components: {
    EmployeeInfoCardComponent,
    EmployeeProfileNavComponent,
    SideMenuComponent,
    EmployeeActionCardComponent,
    EmployeeDetailsComponent,
    DepartmentAssignmentsComponent,
    EmployeeLicensesComponent
  }
})
export default class ProfileBaseComponent extends Vue {
  testString = "test";

  @Profile.Getter
  loadedState!: boolean;

  @SideBar.Action
  private resetState!: () => void;
  @Watch("$route", { immediate: true, deep: true })
  onRouteChange() {
    this.resetState();
  }

  @Profile.Action
  private getDepartmentAssignmentsByEmployee!: (userId: number) => void;
  @Profile.Action
  private getEmployeeDetailsByUserId!: (userId: number) => void;

  @Profile.Action
  private getEmployeeCertsByUserId!: (userId: number) => void;
  created() {
    const userId = +this.$route.params.id;
    this.getEmployeeDetailsByUserId(userId);
    this.getEmployeeCertsByUserId(userId);
    this.getDepartmentAssignmentsByEmployee(userId);
  }
}
