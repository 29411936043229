








import { Component, Mixins, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { SideBarType, SideMenuItem } from "@/models";
import { UspDepartmentAssignmentsByEmployee } from "@/models/employee-profile/employee.response.model";
import { PermissionMixin } from "@/mixins";

const SideBar = namespace("SideBar");
const Profile = namespace("Profile");
@Component
export default class ActionTemplate extends Mixins(PermissionMixin) {
  @SideBar.Action
  private toggleSideMenu!: (type: SideMenuItem) => void;
  @Profile.Action
  deleteDepartmentAssignment!: (staffId: number) => void;
  toggleEdit(data: UspDepartmentAssignmentsByEmployee) {
    this.toggleSideMenu({
      type: SideBarType.EditDepartmentSettings,
      item: data
    });
  }

  deleteDep(staffId: number) {
    this.deleteDepartmentAssignment(staffId);
  }
}
