import httpAuth from "../common/http/http-common";

class CommonService {
  getLookup(lookupTypeId: number) {
    return httpAuth().get(`/common/GetLookup`, {
      params: {
        lookupTypeId
      }
    });
  }
}

export default new CommonService();
