



































































































































import { Component, Mixins, Watch, Prop, Ref } from "vue-property-decorator";
import {
  IconItem,
  DropdownSelectNumber,
  DropdownSelectWithImage,
  ShiftEmployeeEditIconData,
  UserScheduleTypeGroupEnum,
  ShiftEmployeeDropDownRequest,
  ShiftInsertModel,
  EditIconData,
  IconItemUpset
} from "@/models";
import { CardMixin } from "@/mixins";
import {
  ValidationObserver,
  ValidationProvider,
  ValidationObserverInstance
} from "vee-validate";
import {
  ChangeEventArgs as ChangeEventArgsDD,
  DropDownList
} from "@syncfusion/ej2-vue-dropdowns";
import { namespace } from "vuex-class";
const ShiftModule = namespace("ShiftModule");
const SideBar = namespace("SideBar");
import { IconDropDownTemplate } from "@/common";
import moment from "moment";
@Component({
  components: { ValidationObserver, ValidationProvider, IconDropDownTemplate }
})
export default class IconEditFormTemplateComponent extends Mixins(CardMixin) {
  @SideBar.Getter
  private currentActiveMenuItem!: ShiftInsertModel;
  @SideBar.Getter
  private currentsSubItem!: IconItem;
  dropdownInactiveDepFields = {
    value: "chargeCode",
    text: "departmentDisplayText"
  };
  iTemplate() {
    return {
      template: IconDropDownTemplate
    };
  }
  vTemplate() {
    return {
      template: IconDropDownTemplate
    };
  }
  @Ref("iconValid") readonly valObs!: ValidationObserverInstance;
  @Ref("iconDD") readonly iconDD!: DropDownList;

  @ShiftModule.Action
  getShiftEmployeeEditIconData!: (
    model: ShiftEmployeeDropDownRequest
  ) => Promise<ShiftEmployeeEditIconData>;

  locations: DropdownSelectNumber[] = [];
  positions: DropdownSelectNumber[] = [];
  departments: DropdownSelectNumber[] = [];
  categories: DropdownSelectNumber[] = [];
  hours: DropdownSelectNumber[] = [];
  icons: DropdownSelectWithImage[] = [];
  dataForIcon: ShiftEmployeeEditIconData = {
    userScheduleTypeSubtypes: [],
    hoursTypes: []
  };
  selectIcon!: DropdownSelectWithImage;
  loadingIconRules = false;
  hasIconRules = false;
  showIncentives = false;
  showPayCodes = false;
  showSubType = false;
  typeRules = "";
  isNotesRequired = false;
  noteRules = "";
  isHoursRequired = false;
  hoursRules = "";
  showWorkRule = false;
  isStartEndRequired = false;
  lblDisplayMessageDesc = "";
  lblDisplayHoursMessage = "";
  amount?: number;

  @Prop() model!: IconItemUpset;
  @Prop() type!: string;

  loading = false;
  @ShiftModule.Action
  getEditIconData!: (
    model: ShiftEmployeeDropDownRequest
  ) => Promise<EditIconData>;
  @Watch("currentsSubItem", { immediate: true, deep: true })
  changeCurrentsSubItem() {
    console.log("currentsSubItem change", this.currentsSubItem);
    // this.refresh();
  }

  @Watch("model", { immediate: true, deep: true })
  changeModel() {
    console.log("changeModel change", this.model);
    this.refresh();
  }

  onIconChange(args: ChangeEventArgsDD) {
    if (this.loading) {
      return;
    }
    this.hasIconRules = false;
    console.log("onIconChange", args);
    if (args.itemData && args.itemData.value) {
      const value = parseInt(args.itemData.value.toString(), 10);
      this.selectIcon = {
        value: value,
        text: args.itemData.text as string,
        imageUrl: ""
      };
      if (value == -1) {
        if (this.model) {
          this.model.userScheduleType = null;
          this.model.userScheduleTypeName = "";
        }
        this.iconDD.clear();
        args.cancel = true;
        this.dataForIcon.userScheduleTypeSubtypes = [];
        this.dataForIcon.hoursTypes = [];
        this.dataForIcon.shiftIncentiveDepartments = [];
        this.dataForIcon.payCodeDepartments = [];
        this.dataForIcon.iconRules = undefined;
        this.dataForIcon.reassignCategories = [];
        this.applayIconRules();
        return;
      }
      this.loadingIconRules = true;
      if (this.model) {
        this.model.userScheduleType = value;
        this.model.userScheduleTypeName = args.itemData.text;
      }
      this.model.workrule = this.showWorkRule;
      this.model.amount = this.amount;

      this.getShiftEmployeeEditIconData({
        departmentId: this.currentActiveMenuItem.departmentId,
        userScheduleTypeId: value
      })
        .then(res => {
          console.log("responmse dta", res, UserScheduleTypeGroupEnum.Inactive);
          this.dataForIcon.userScheduleTypeSubtypes =
            res.userScheduleTypeSubtypes;
          this.dataForIcon.hoursTypes = res.hoursTypes;
          this.dataForIcon.iconRules = res.iconRules;
          this.dataForIcon.shiftIncentiveDepartments =
            res.shiftIncentiveDepartments;
          this.dataForIcon.payCodeDepartments = res.payCodeDepartments;
          this.dataForIcon.reassignCategories = res.reassignCategories;
          this.applayIconRules();
        })
        .finally(() => {
          this.loadingIconRules = false;
        });
    }
  }

  applayIconRules() {
    if (this.dataForIcon.iconRules) {
      this.showIncentives =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Inactive &&
        (this.dataForIcon.iconRules.isDepartmentRequired ?? false);
      this.showPayCodes =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Paycode &&
        (this.dataForIcon.iconRules.isDepartmentRequired ?? false);
      this.showWorkRule =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
        UserScheduleTypeGroupEnum.Workrule;
      this.isStartEndRequired =
        this.dataForIcon.iconRules.isStartEndTimeRequired ?? false;
      this.isHoursRequired =
        this.dataForIcon.iconRules.isHoursRequired ?? false;
      this.showSubType = this.dataForIcon.userScheduleTypeSubtypes.length > 1;
      this.isNotesRequired =
        this.dataForIcon.iconRules.isNotesRequired ?? false;
      this.lblDisplayMessageDesc = this.dataForIcon.iconRules.messageDesc;
      this.lblDisplayHoursMessage = this.dataForIcon.iconRules.hoursMessage;

      this.amount =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Inactive ||
        (this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Paycode &&
          (this.dataForIcon.iconRules.isDepartmentRequired ?? false))
          ? this.dataForIcon.iconRules.amount
          : undefined;

      this.typeRules = this.showSubType ? "required" : "";
      this.hoursRules = this.isHoursRequired ? "required" : "";
      this.noteRules = this.isNotesRequired ? "required" : "";

      this.model.workrule = this.showWorkRule;
      this.model.amount = this.amount;
      if (this.valObs) {
        this.valObs.reset();
      }
      setTimeout(() => {
        this.hasIconRules = true;
      }, 0);
    } else {
      this.hasIconRules = false;
    }
  }

  refresh() {
    this.loading = true;
    this.loadingIconRules = true;
    this.getEditIconData({
      departmentId: this.currentActiveMenuItem.departmentId,
      userScheduleTypeId: this.model.userScheduleType as number
    })
      .then(d => {
        console.log("icon data", d);
        const res = d.iconData;
        this.icons = d.icons;
        this.dataForIcon.userScheduleTypeSubtypes =
          res.userScheduleTypeSubtypes;
        this.dataForIcon.hoursTypes = res.hoursTypes;
        this.dataForIcon.iconRules = res.iconRules;
        this.dataForIcon.shiftIncentiveDepartments =
          res.shiftIncentiveDepartments;
        this.dataForIcon.payCodeDepartments = res.payCodeDepartments;
        this.dataForIcon.reassignCategories = res.reassignCategories;
        this.applayIconRules();
      })
      .finally(() => {
        this.loading = false;
        this.loadingIconRules = true;
      });
  }
}
