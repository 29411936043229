import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import ShiftService from "@/services/ShiftService";
import {
  ShiftInsertModel,
  ShiftTypeRequest,
  ShiftEmployeeDropDownRequest,
  ShiftEmployeeUpset,
  IconUpsetRequest,
  DeleteDailyIconRequest,
  ShiftInformation
} from "@/models";

@Module({ namespaced: true })
export default class ShiftModule extends VuexModule {
  failureMessage = "";
  showError = false;
  currentDropDownLoaded = {
    loaded: false,
    userId: 0,
    departmentId: 0,
    locationId: 0
  };

  get isShowError() {
    return this.showError;
  }

  @Mutation
  failure(error: string): void {
    this.failureMessage = error;
    this.showError = true;
  }

  @Action({ rawError: true })
  cleanError() {
    this.failureMessage = "";
    this.showError = false;
  }
  @Action({ rawError: true })
  async getShiftType(model: ShiftTypeRequest) {
    try {
      const response = await ShiftService.getShiftType(model);
      console.log("getShiftType", response);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error login: ", message);
      this.context.commit("failure", message);
    }
    return [];
  }

  @Action({ rawError: true })
  async getPositions(departmentId: number) {
    try {
      const response = await ShiftService.getPositions(departmentId);
      console.log("getPositions", response);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error login: ", message);
      this.context.commit("failure", message);
    }
    return [];
  }

  @Action({ rawError: true })
  async getPositionsByEmployee(model: ShiftEmployeeDropDownRequest) {
    try {
      const response = await ShiftService.getPositionsByEmployee(model);
      console.log("getPositionsByEmployee", response);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error login: ", message);
      this.context.commit("failure", message);
    }
    return [];
  }

  @Action({ rawError: true })
  async getShiftEmployeeDropDownData(model: ShiftEmployeeDropDownRequest) {
    try {
      const response = await ShiftService.getShiftEmployeeDropDownData(model);
      console.log("getShiftEmployeeDropDownData", response);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error login: ", message);
      this.context.commit("failure", message);
    }
    return [];
  }

  @Action({ rawError: true })
  async shiftsUpsert(model: ShiftInsertModel) {
    try {
      const response = await ShiftService.shiftsUpsert(model);
      console.log("shiftsUpsert", response);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error login: ", message);
      this.context.commit("failure", message);
    }
  }

  @Action({ rawError: true })
  async getShiftEmployeeEditIconData(model: ShiftEmployeeDropDownRequest) {
    try {
      const response = await ShiftService.getShiftEmployeeEditIconData(model);
      console.log("GetShiftEmployeeEditIconData", response);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error login: ", message);
      this.context.commit("failure", message);
    }
    return [];
  }

  @Action({ rawError: true })
  async shiftEmployeeUpset(model: ShiftEmployeeUpset) {
    try {
      const response = await ShiftService.shiftEmployeeUpset(model);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error: ", message);
      this.context.commit("failure", message);
    }
  }

  @Action({ rawError: true })
  async getEditIconData(model: ShiftEmployeeDropDownRequest) {
    try {
      const response = await ShiftService.getEditIconData(
        model.departmentId,
        model.userScheduleTypeId as number
      );
      console.log("getEditIconData", response);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error get icons: ", message);
      this.context.commit("failure", message);
    }
    return [];
  }

  @Action({ rawError: true })
  async iconDailyUpset(model: IconUpsetRequest) {
    try {
      const response = await ShiftService.iconDailyUpset(model);
      console.log("iconDailyUpset", response);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error iconDailyUpset: ", message);
      this.context.commit("failure", message);
    }
  }

  @Action({ rawError: true })
  async deleteDailyIcon(model: DeleteDailyIconRequest) {
    try {
      const response = await ShiftService.deleteDailyIcon(model);
      console.log("deleteDailyIcon", response);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error deleteDailyIcon: ", message);
      this.context.commit("failure", message);
    }
  }

  @Action({ rawError: true })
  async getShiftInformation(shiftId: number) {
    try {
      const response = await ShiftService.getShiftInformation(shiftId);
      console.log("getShiftInformation", response);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error getShiftInformation: ", message);
      this.context.commit("failure", message);
    }
  }

  @Action({ rawError: true })
  async shiftUpsetInformation(model: ShiftInformation) {
    try {
      const response = await ShiftService.shiftUpsetInformation(model);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error: ", message);
      this.context.commit("failure", message);
    }
  }

  @Action({ rawError: true })
  async deleteEmployeeShift(shiftEmpReqId: number) {
    try {
      const response = await ShiftService.deleteEmployeeShift(shiftEmpReqId);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error: ", message);
      this.context.commit("failure", message);
    }
  }

  @Action({ rawError: true })
  async deleteShiftAttribute(attributeId: number) {
    try {
      const response = await ShiftService.deleteShiftAttribute(attributeId);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error: ", message);
      this.context.commit("failure", message);
    }
  }
}
