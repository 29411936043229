var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('ValidationObserver',{ref:"iconValid",staticClass:"edit-shift-icon add-shift",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label"},[_vm._v("Icon:")]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{ref:"iconDD",attrs:{"dataSource":_vm.icons,"change":_vm.onIconChange,"itemTemplate":_vm.iTemplate,"valueTemplate":_vm.vTemplate,"allowFiltering":true,"cssClass":'icon-drop',"fields":_vm.dropdownDefaultFields,"disabled":_vm.loadingIconRules},model:{value:(_vm.model.userScheduleType),callback:function ($$v) {_vm.$set(_vm.model, "userScheduleType", $$v)},expression:"model.userScheduleType"}})],1)]),(_vm.hasIconRules)?_c('div',{staticClass:"rule-block"},[(_vm.isHoursRequired)?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Hours/Day","rules":_vm.hoursRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Hours/Day:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{attrs:{"dataSource":_vm.dataForIcon.hoursTypes,"allowFiltering":true,"fields":_vm.dropdownDefaultFields},model:{value:(_vm.model.hoursType),callback:function ($$v) {_vm.$set(_vm.model, "hoursType", $$v)},expression:"model.hoursType"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}):_vm._e(),(_vm.showSubType)?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Type","rules":_vm.typeRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Type:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{attrs:{"dataSource":_vm.dataForIcon.userScheduleTypeSubtypes,"allowFiltering":true,"fields":_vm.dropdownDefaultFields},model:{value:(_vm.model.userScheduleTypeSubtype),callback:function ($$v) {_vm.$set(_vm.model, "userScheduleTypeSubtype", $$v)},expression:"model.userScheduleTypeSubtype"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}):_vm._e(),(_vm.showPayCodes)?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label"},[_vm._v("Pay Code:")]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{attrs:{"dataSource":_vm.dataForIcon.payCodeDepartments,"allowFiltering":true,"fields":_vm.dropdownInactiveDepFields},model:{value:(_vm.model.payCodeDepartment),callback:function ($$v) {_vm.$set(_vm.model, "payCodeDepartment", $$v)},expression:"model.payCodeDepartment"}})],1)]):_vm._e(),(_vm.showIncentives)?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label"},[_vm._v("Inactive Deparment:")]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{attrs:{"dataSource":_vm.dataForIcon.shiftIncentiveDepartments,"allowFiltering":true,"fields":_vm.dropdownInactiveDepFields},model:{value:(_vm.model.shiftIncentiveDepartment),callback:function ($$v) {_vm.$set(_vm.model, "shiftIncentiveDepartment", $$v)},expression:"model.shiftIncentiveDepartment"}})],1)]):_vm._e(),(_vm.isStartEndRequired)?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Begin Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Begin Time:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-timepicker',{attrs:{"showClearButton":false,"format":'HH:mm',"step":15},model:{value:(_vm.model.beginTime),callback:function ($$v) {_vm.$set(_vm.model, "beginTime", $$v)},expression:"model.beginTime"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}):_vm._e(),(_vm.isStartEndRequired)?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"End Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("End Time:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-timepicker',{attrs:{"showClearButton":false,"format":'HH:mm',"step":15},model:{value:(_vm.model.endTime),callback:function ($$v) {_vm.$set(_vm.model, "endTime", $$v)},expression:"model.endTime"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}):_vm._e(),_c('ValidationProvider',{staticClass:"form-group textarea-block",attrs:{"name":"Note","rules":_vm.noteRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Notes:"),(_vm.isNotesRequired)?_c('sup',[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form-input"},[_c('ejs-textbox',{attrs:{"multiline":true,"cssClass":'multi-notes',"rows":4,"placeHolder":_vm.lblDisplayMessageDesc},model:{value:(_vm.model.notes),callback:function ($$v) {_vm.$set(_vm.model, "notes", $$v)},expression:"model.notes"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1):_vm._e()]}}],null,false,2457151443)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }