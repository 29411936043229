
































































































































































































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { BaseSideBarMixin } from "@/mixins";
import { namespace } from "vuex-class";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";
const SideBar = namespace("SideBar");
const ShiftModule = namespace("ShiftModule");
const DepartmentModule = namespace("DepartmentModule");
const Toast = namespace("ToastModule");
import {
  IconDropDownTemplate,
  ShiftCardTemplate,
  ActionCardTemplate
} from "@/common";
import {
  ChangeEventArgs as ChangeEventArgsDD,
  DropDownList
} from "@syncfusion/ej2-vue-dropdowns";
import {
  DepartmentEmployeeModel,
  ShiftEmployeeUpset,
  ShiftType,
  ShiftTypeRequest,
  ShiftEmployeeDropDownRequest,
  ShiftEmployeeDropDownData,
  DropdownSelectNumber,
  DropdownSelectWithImage,
  ShiftEmployeeEditIconData,
  UserScheduleTypeGroupEnum,
  BaseResponse,
  SideBarType,
  SideMenuItemOptions,
  ToastOptions
} from "@/models";
import {
  ValidationObserver,
  ValidationProvider,
  ValidationObserverInstance
} from "vee-validate";
import moment from "moment";
@Component({
  components: {
    "side-menu-layout": SideMenuLayout,
    ValidationObserver,
    ValidationProvider,
    IconDropDownTemplate,
    ShiftCardTemplate,
    ActionCardTemplate
  }
})
export default class AddEmployeeShiftComponent extends Mixins(
  BaseSideBarMixin
) {
  dropdownInactiveDepFields = {
    value: "chargeCode",
    text: "departmentDisplayText"
  };
  iTemplate() {
    return {
      template: IconDropDownTemplate
    };
  }
  vTemplate() {
    return {
      template: IconDropDownTemplate
    };
  }
  @Ref("valObs") readonly valObs!: ValidationObserverInstance;
  @Ref("iconDD") readonly iconDD!: DropDownList;

  @Toast.Action
  private showToast!: (toastOptions: ToastOptions) => void;
  name = "";
  private currentUserId = 0;
  locations: DropdownSelectNumber[] = [];
  positions: DropdownSelectNumber[] = [];
  departments: DropdownSelectNumber[] = [];
  categories: DropdownSelectNumber[] = [];
  hours: DropdownSelectNumber[] = [];
  icons: DropdownSelectWithImage[] = [];
  dataForIcon: ShiftEmployeeEditIconData = {
    userScheduleTypeSubtypes: [],
    hoursTypes: []
  };
  loadingShiftType = false;
  loadingIconRules = false;
  hasIconRules = false;
  showIncentives = false;
  showPayCodes = false;
  showWorkRule = false;
  showSubType = false;
  typeRules = "";
  isNotesRequired = false;
  noteRules = "";
  isHoursRequired = false;
  hoursRules = "";
  isStartEndRequired = false;
  lblDisplayMessageDesc = "";
  lblDisplayHoursMessage = "";
  amount?: number;

  selectIcon!: DropdownSelectWithImage;
  @SideBar.Getter
  private currentActiveMenuItem!: ShiftEmployeeUpset;
  @SideBar.Getter
  private currentSubItem!: object;
  @SideBar.Getter
  private currentActiveMenuOptions!: SideMenuItemOptions;

  model: ShiftEmployeeUpset = { ...this.currentActiveMenuItem };

  @DepartmentModule.Getter
  currentDepartment!: DepartmentEmployeeModel;
  @ShiftModule.Action
  getShiftType!: (model: ShiftTypeRequest) => Promise<ShiftType[]>;
  @ShiftModule.Action
  getShiftEmployeeDropDownData!: (
    model: ShiftEmployeeDropDownRequest
  ) => Promise<ShiftEmployeeDropDownData>;
  @ShiftModule.Action
  getShiftEmployeeEditIconData!: (
    model: ShiftEmployeeDropDownRequest
  ) => Promise<ShiftEmployeeEditIconData>;
  @ShiftModule.Getter
  isShowError!: boolean;
  @ShiftModule.Action
  cleanError!: () => Promise<void>;
  @ShiftModule.Action
  shiftEmployeeUpset!: (model: ShiftEmployeeUpset) => Promise<BaseResponse>;

  @Watch("currentActiveMenuItem", { immediate: true, deep: true })
  async changeCurrentActiveMenuItem() {
    console.log("currentActiveMenuItem change", this.currentActiveMenuItem);
    this.name =
      this.currentActiveMenuItem.user_FName +
      " " +
      this.currentActiveMenuItem.user_LName;
    await this.setCurrentUserId();

    this.model = { ...this.currentActiveMenuItem };
  }

  created() {
    this.model = { ...this.currentActiveMenuItem };
  }

  async mounted() {
    if (this.valObs) {
      this.valObs.reset();
    }
    this.setCurrentUserId();
  }

  async onSubmit() {
    const isValid = await this.valObs.validate();
    console.log("onSubmit", this.valObs);
    if (!isValid) {
      return;
    }
    const dt = moment(this.model.shiftDate).format("MM/DD/yyyy");

    this.model.shiftBeginTime = moment(this.model.shiftStartDate).format(
      "HH:mm:ss"
    );
    this.model.shiftEndTime = moment(this.model.shiftEndDate).format(
      "HH:mm:ss"
    );

    this.model.shiftStartDate = moment(
      dt + " " + this.model.shiftBeginTime
    ).toDate();
    this.model.shiftEndDate = moment(
      dt + " " + this.model.shiftEndTime
    ).toDate();

    if (
      moment(this.model.shiftStartDate).diff(moment(this.model.shiftEndDate)) >=
      0
    ) {
      this.model.shiftEndDate = moment(this.model.shiftEndDate)
        .add("d", 1)
        .toDate();
    }
    this.model.currentDepartmentName = this.currentDepartment.companyDepartmentName;
    console.log("shiftEmployeeUpset", this.model);
    this.shiftEmployeeUpset(this.model).then(() => {
      console.log("shiftEmployeeUpset response");
      if (this.isShowError) {
        this.cleanError();
        // this.$toasted.show("Error occurred", {
        //   position: "top-right",
        //   theme: "outline",
        //   duration: 5000
        // });
        this.showToast({
          title: "Error",
          content: "Error occurred",
          cssClass: "e-toast-danger"
        });
      } else {
        // this.$toasted.show("Shift Employee save success", {
        //   position: "top-right",
        //   theme: "outline",
        //   duration: 5000
        // });
        this.showToast({
          title: "Success",
          content: "Shift Employee save success",
          cssClass: "e-toast-success"
        });
        this.closeMenu({
          success: true,
          reload: true
        });
      }
    });
  }
  onSubTypeChange(args: ChangeEventArgsDD) {
    console.log("onSubTypeChange", args);
  }
  onIconChange(args: ChangeEventArgsDD) {
    this.hasIconRules = false;
    this.checkIfIconExist();
    console.log("onIconChange", args);
    if (args.itemData && args.itemData.value) {
      const value = parseInt(args.itemData.value.toString(), 10);
      this.selectIcon = {
        value: value,
        text: args.itemData.text as string,
        imageUrl: ""
      };
      if (value == -1) {
        if (this.model.icon) {
          this.model.icon.userScheduleType = null;
          this.model.icon.userScheduleTypeName = "";
        }
        this.iconDD.clear();
        args.cancel = true;
        this.dataForIcon.userScheduleTypeSubtypes = [];
        this.dataForIcon.hoursTypes = [];
        this.dataForIcon.shiftIncentiveDepartments = [];
        this.dataForIcon.payCodeDepartments = [];
        this.dataForIcon.iconRules = undefined;
        this.dataForIcon.reassignCategories = [];
        this.applayIconRules();
        return;
      }
      this.loadingIconRules = true;
      if (this.model.icon) {
        this.model.icon.userScheduleType = value;
        this.model.icon.userScheduleTypeName = args.itemData.text;
      }
      this.model.workrule = this.showWorkRule;
      this.model.amount = this.amount;

      this.getShiftEmployeeEditIconData({
        departmentId: this.model.departmentId,
        userScheduleTypeId: value
      })
        .then(res => {
          console.log("responmse dta", res, UserScheduleTypeGroupEnum.Inactive);
          this.dataForIcon.userScheduleTypeSubtypes =
            res.userScheduleTypeSubtypes;
          this.dataForIcon.hoursTypes = res.hoursTypes;
          this.dataForIcon.iconRules = res.iconRules;
          this.dataForIcon.shiftIncentiveDepartments =
            res.shiftIncentiveDepartments;
          this.dataForIcon.payCodeDepartments = res.payCodeDepartments;
          this.dataForIcon.reassignCategories = res.reassignCategories;
          this.applayIconRules();
        })
        .finally(() => {
          this.loadingIconRules = false;
        });
    }
  }
  onDepartmentChange(args: ChangeEventArgsDD) {
    console.log("ondepartmentchange", args);
  }
  onPositionChange(args: ChangeEventArgsDD) {
    console.log("onPositionChange", args);
  }

  async getDropDownData() {
    await this.getShiftEmployeeDropDownData({
      userId: this.currentActiveMenuItem.userId,
      departmentId: this.currentActiveMenuItem.departmentId,
      locationId: this.currentActiveMenuItem.locationId
    }).then(r => {
      console.log("getShiftEmployeeDropDownData: ", r);
      this.positions = [];
      this.locations = [];
      this.departments = [];
      this.icons = [];
      this.positions = r.reassignPositions;
      this.locations = r.reassignLocations;
      this.departments = r.reassignDepartments;
      this.icons.push({
        imageUrl: "",
        value: -1,
        text: ""
      });
      this.icons = [...this.icons, ...r.iconsData];
    });
  }

  async setCurrentUserId() {
    if (
      this.currentUserId == 0 ||
      (this.currentActiveMenuItem &&
        this.currentActiveMenuItem.userId &&
        this.currentActiveMenuItem.userId != 0 &&
        this.currentUserId != this.currentActiveMenuItem.userId)
    ) {
      this.loadedState = true;
      console.log(
        "setCurrentUserId: ",
        this.currentUserId,
        this.currentActiveMenuItem.userId
      );
      this.currentUserId = parseInt(
        JSON.stringify(
          JSON.parse(this.currentActiveMenuItem.userId.toString())
        ),
        10
      );
      this.checkIfIconExist();
      await this.getDropDownData();
      this.loadedState = false;
    }
  }

  applayIconRules() {
    if (this.dataForIcon.iconRules) {
      this.showIncentives =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Inactive &&
        (this.dataForIcon.iconRules.isDepartmentRequired ?? false);
      this.showPayCodes =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Paycode &&
        (this.dataForIcon.iconRules.isDepartmentRequired ?? false);
      this.showWorkRule =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
        UserScheduleTypeGroupEnum.Workrule;
      this.isStartEndRequired =
        this.dataForIcon.iconRules.isStartEndTimeRequired ?? false;
      this.isHoursRequired =
        this.dataForIcon.iconRules.isHoursRequired ?? false;
      this.showSubType = this.dataForIcon.userScheduleTypeSubtypes.length > 1;
      this.isNotesRequired =
        this.dataForIcon.iconRules.isNotesRequired ?? false;
      this.lblDisplayMessageDesc = this.dataForIcon.iconRules.messageDesc;
      this.lblDisplayHoursMessage = this.dataForIcon.iconRules.hoursMessage;

      this.amount =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Inactive ||
        (this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Paycode &&
          (this.dataForIcon.iconRules.isDepartmentRequired ?? false))
          ? this.dataForIcon.iconRules.amount
          : undefined;

      this.typeRules = this.showSubType ? "required" : "";
      this.hoursRules = this.isHoursRequired ? "required" : "";
      this.noteRules = this.isNotesRequired ? "required" : "";
      if (this.valObs) {
        this.valObs.reset();
      }
      setTimeout(() => {
        this.hasIconRules = true;
      }, 0);
    } else {
      this.hasIconRules = false;
    }
  }

  checkIfIconExist() {
    if (!this.model.icon) {
      this.model.icon = {
        userScheduleType: null,
        hoursType: undefined,
        payCodeDepartment: undefined,
        shiftIncentiveDepartment: undefined,
        userScheduleTypeSubtype: undefined,
        beginTime: undefined,
        endTime: undefined
      };
    }
  }

  getSideBarType(): SideBarType {
    return SideBarType.ActionScheduler;
  }

  isMainPage(): boolean {
    return this.currentActiveMenuOptions?.mainPage ?? true;
  }
}
