/* eslint-disable @typescript-eslint/camelcase */
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import ProfileService from "@/services/ProfileService";
import {
  UspDepartmentAssignmentsByEmployee,
  UspEmployeeCertsByUserId,
  UspEmployeeDetailByUserId
} from "@/models/employee-profile/employee.response.model";
import _ from "lodash";

@Module({ namespaced: true })
class ProfileModule extends VuexModule {
  employeeDetailsState: Partial<UspEmployeeDetailByUserId> = {};

  employeeCertsState: UspEmployeeCertsByUserId[] = [];

  employeeAssignmentsState: UspDepartmentAssignmentsByEmployee[] = [
    {
      userId: 0,
      locationId: 0,
      locationName: null,
      companyDepartmentId: 0,
      companyDepartmentName: null,
      memberPosition: "",
      userAccessDesc: null,
      memberPositionID: 0,
      userAccessType: null,
      staffTitleId: null,
      staffTitle: null,
      shiftGroupId: null,
      defaultTeamId: null,
      teamName: "",
      companyDepartmentStaffID: 0,
      staffCategoryId: 0
    }
  ];
  loaded = false;

  @Mutation
  EmployeeDetailsByUserId(response: UspEmployeeDetailByUserId) {
    this.employeeDetailsState = response;
  }

  @Mutation
  EmployeeCertsByUserId(response: UspEmployeeCertsByUserId[]) {
    this.employeeCertsState = response;
  }

  @Mutation
  loadingStatus(newLoadingState: boolean) {
    this.loaded = newLoadingState;
  }
  @Mutation
  updateLoginDisabled(loginDisabled: boolean) {
    this.employeeDetailsState.loginDisabled = loginDisabled;
  }
  @Mutation
  departmentAssignmentsByEmployee(data: UspDepartmentAssignmentsByEmployee[]) {
    this.employeeAssignmentsState = _.chain(data)
      .groupBy("companyDepartmentId")
      .map((department, id) => ({
        ...department[0],
        memberPositionArray: _.map(department, "memberPosition")
      }))
      .value();
  }
  @Action({ rawError: true })
  updateUserLoginDisabled({
    userID,
    loginDisabled
  }: {
    userID: number;
    loginDisabled: boolean;
  }) {
    try {
      ProfileService.updateUserLoginDisabled(userID, loginDisabled);
      this.context.commit("updateLoginDisabled", loginDisabled);
      this.context.dispatch(
        "ToastModule/showToast",
        {
          title: `Success!`,
          content: `Login Disabled Status has been updated!`
        },
        { root: true }
      );
    } catch (err) {
      this.context.dispatch("failure", err);
    }
  }
  @Action({ rawError: true })
  async getEmployeeDetailsByUserId(userId: number) {
    try {
      this.context.commit("loadingStatus", true);
      const response = await ProfileService.getEmployeeDetailsByUserId(userId);
      console.log("getEmployeeDetailsByUserId", response.data);

      this.context.commit("EmployeeDetailsByUserId", response.data);
      this.context.commit("loadingStatus", false);
    } catch (err) {
      this.context.dispatch("failure", err);
      this.context.commit("loadingStatus", false);
    }
  }
  @Action
  failure(error: Error) {
    this.context.dispatch(
      "ToastModule/showToast",
      {
        title: `Error occurred!`,
        content: error,
        cssClass: "e-toast-danger"
      },
      { root: true }
    );
  }
  @Action({ rawError: true })
  async getEmployeeCertsByUserId(userId: number) {
    try {
      // this.context.commit("loadingStatus", true);
      const response = await ProfileService.getEmployeeCertsByUserId(userId);
      console.log("getEmployeeCertsByUserId", response.data);
      this.context.commit("EmployeeCertsByUserId", response.data);
      // this.context.commit("loadingStatus", false);
    } catch (err) {
      this.context.dispatch("failure", err);
      this.context.commit("loadingStatus", false);
    }
  }

  @Action({ rawError: true })
  async updateEmployeeDetailsByUserId(data: UspEmployeeDetailByUserId) {
    try {
      this.context.commit("loadingStatus", true);
      const response = await ProfileService.updateEmployeeDetailsByUserId(data);
      console.log("updateEmployeeDetailsByUserId", response);
      this.context.dispatch(
        "ToastModule/showToast",
        {
          title: `Success!`,
          content: `Employee Details have been updated!`
        },
        { root: true }
      );
      this.context.dispatch("getEmployeeDetailsByUserId", this.currentUserId);
      this.context.commit("loadingStatus", false);
    } catch (err) {
      this.context.dispatch("failure", err);
      this.context.commit("loadingStatus", false);
    }
  }
  @Action({ rawError: true })
  async addDepartmentGroupToUser(deptGroupId: number) {
    try {
      const response = await ProfileService.departmentAssignmentByGroupUpsert(
        this.currentUserId ?? 0,
        deptGroupId
      );
      this.context.dispatch(
        "getDepartmentAssignmentsByEmployee",
        this.currentUserId
      );
      this.context.dispatch(
        "ToastModule/showToast",
        {
          title: `Success!`,
          content: `Dept Group ${deptGroupId} has been added!`
        },
        { root: true }
      );
      console.log("deptGroupUpsert", response.data);
    } catch (err) {
      this.context.dispatch("failure", err);
    }
  }
  @Action({ rawError: true })
  async insertEmployeeCerts(data: UspEmployeeCertsByUserId) {
    try {
      const response = await ProfileService.insertEmployeeCerts(data);
      console.log("insertEmployeeCerts", response.data);
      this.context.dispatch(
        "ToastModule/showToast",
        {
          title: `Success!`,
          content: `License ${data.userProfile_CertNo} has been added!`
        },
        { root: true }
      );
      this.context.dispatch("getEmployeeCertsByUserId", this.currentUserId);
    } catch (err) {
      this.context.dispatch("failure", err);
    }
  }
  @Action({ rawError: true })
  async insertDeptAssignment(departmentId: number) {
    try {
      if (this.currentUserId) {
        const response = await ProfileService.insertDeptAssignment(
          this.currentUserId,
          departmentId
        );
        console.log("insertDeptAssignment", response.data);
        console.log("Success");
        this.context.dispatch(
          "getDepartmentAssignmentsByEmployee",
          this.currentUserId
        );
        this.context.dispatch(
          "ToastModule/showToast",
          {
            title: `Success!`,
            content: `Department ${departmentId} has been added!`
          },
          { root: true }
        );
      }
    } catch (err) {
      this.context.dispatch("failure", err);
      this.context.commit("loadingStatus", false);
    }
  }
  @Action({ rawError: true })
  async updateDepAssignment(depAssignment: UspDepartmentAssignmentsByEmployee) {
    try {
      this.context.commit("loadingStatus", true);
      const response = await ProfileService.updateDepAssignment(depAssignment);
      console.log("updateDepAssignment", response.data);
      this.context.dispatch(
        "ToastModule/showToast",
        {
          title: `Success!`,
          content: `Assignment ${depAssignment.companyDepartmentStaffID} settings have been updated!`
        },
        { root: true }
      );
      this.context.dispatch(
        "getDepartmentAssignmentsByEmployee",
        this.currentUserId
      );
      this.context.commit("loadingStatus", false);
    } catch (err) {
      this.context.dispatch("failure", err);
      this.context.commit("loadingStatus", false);
    }
  }
  @Action({ rawError: true })
  async getDepartmentAssignmentsByEmployee(userId: number) {
    try {
      const response = await ProfileService.getDepartmentAssignmentsByEmployee(
        userId
      );
      this.context.commit("departmentAssignmentsByEmployee", response.data);
    } catch (err) {
      this.context.dispatch("failure", err);
    }
  }

  @Action({ rawError: true })
  async deleteDepartmentAssignment(companyDepartmentStaffID: number) {
    try {
      const response = await ProfileService.deleteDepartmentAssignment(
        companyDepartmentStaffID
      );
      console.log(response);
      this.context.dispatch(
        "ToastModule/showToast",
        {
          title: `Success!`,
          content: `Department Assignment ${companyDepartmentStaffID} has been deleted!`
        },
        { root: true }
      );
      this.context.dispatch(
        "getDepartmentAssignmentsByEmployee",
        this.currentUserId
      );
    } catch (err) {
      this.context.dispatch("failure", err);
    }
  }
  get employeeCerts() {
    return this.employeeCertsState;
  }
  get employeeDetails() {
    return this.employeeDetailsState;
  }

  get departmentAssignments() {
    return this.employeeAssignmentsState;
  }
  get loadedState() {
    return this.loaded;
  }
  get currentUserId() {
    return this.employeeDetailsState.userID;
  }
}

export default ProfileModule;
