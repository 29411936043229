

















































































/* eslint-disable @typescript-eslint/camelcase */
import { Component, Mixins, Ref, Vue, Watch } from "vue-property-decorator";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";
import { namespace } from "vuex-class/lib/bindings";
import { UspEmployeeCertsByUserId } from "@/models/employee-profile/employee.response.model";
import { stateOptions } from "@/common";
import ProfileService from "@/services/ProfileService";
import moment from "moment";
import { DropdownSelect } from "@/models/common/dropdown.model";
const Profile = namespace("Profile");
const SideBar = namespace("SideBar");
import {
  ValidationObserver,
  ValidationProvider,
  ValidationObserverInstance
} from "vee-validate";
import { BaseSideBarMixin } from "@/mixins";
@Component({
  components: {
    "side-menu-layout": SideMenuLayout,
    ValidationObserver,
    ValidationProvider
  }
})
export default class AddLicenseComponent extends Mixins(BaseSideBarMixin) {
  dropTarget = "#droparea";
  @Profile.Action
  insertEmployeeCerts!: (data: UspEmployeeCertsByUserId) => void;
  @Profile.Getter
  currentUserId!: number;
  @SideBar.Getter
  currentActiveMenuItem!: UspEmployeeCertsByUserId;
  @Ref("valObs") readonly valObs!: ValidationObserverInstance;
  defautlVal: UspEmployeeCertsByUserId = {
    userID: 0,
    userProfile_CertId: 3,
    userProfile_CertNo: "",
    userProfile_CertExp: "",
    userProfile_RecStat: 1,
    userProfile_CertState: "NH",
    userProfile_Note: "",
    companyCertID: 0
  };
  newCert: UspEmployeeCertsByUserId = {
    userID: 0,
    userProfile_CertId: 3,
    userProfile_CertNo: "",
    userProfile_CertExp: "",
    userProfile_RecStat: 1,
    userProfile_CertState: "NH",
    userProfile_Note: "",
    companyCertID: 0
  };
  messageType = [
    { value: "a", text: "Free Text" },
    { value: "b", text: "Test 1" },
    { value: "c", text: "Test 2" }
  ];
  dropdownFields = {
    value: "value",
    text: "text"
  };
  stateOptions = [...stateOptions];
  licenseOptions: DropdownSelect[] = [
    {
      value: 0,
      text: "select license"
    }
  ];

  async onSubmit() {
    this.newCert.userID = this.currentUserId;
    const isValid = await this.valObs.validate();
    console.log("onSubmit", this.valObs);
    if (!isValid) {
      return;
    }
    this.newCert.userProfile_CertExp = moment(
      this.newCert.userProfile_CertExp
    ).format("YYYY-MM-DDTHH:mm:ss");
    this.newCert.userProfile_CertId = parseInt(
      String(this.newCert.userProfile_CertId)
    );
    this.insertEmployeeCerts(this.newCert);
  }
  async created() {
    this.licenseOptions = (await ProfileService.getCompanyCerts()).data;
  }
  @Watch("currentActiveMenuItem", { deep: true, immediate: true })
  chaneLicenseData() {
    if (this.currentActiveMenuItem.userProfile_CertId) {
      this.newCert = { ...this.currentActiveMenuItem };
    } else {
      this.newCert = { ...this.defautlVal };
    }
  }
}
