import httpAuth from "../common/http/http-common";
import moment from "moment";
import {
  DateFilterModel,
  ParameterCalendarModel,
  AddUserToSchedulerModel
} from "@/models";

class CalendareService {
  getShiftsInfoByDateRange(model: DateFilterModel) {
    return httpAuth().get(`/calendar/GetShiftsInfoByDateRange`, {
      params: {
        departmentId: model.departmentId,
        startDate: moment(model.startDate).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: moment(model.endDate).format("YYYY-MM-DDTHH:mm:ss"),
        type: model.type,
        userId: isNaN(model.userId ?? NaN) ? null : model.userId
      }
    });
  }
  getEmployeePositionShiftsInfo(model: ParameterCalendarModel) {
    return httpAuth().get(`/scheduler/EmployeePositionShiftsInfo`, {
      params: {
        userId: model.userId,
        shiftTypeId: model.shiftTypeId,
        positionId: model.positionId,
        startDate: moment(model.startDate).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: moment(model.endDate).format("YYYY-MM-DDTHH:mm:ss"),
        includeUnderStaffed: model.includeUnderStaffed
      }
    });
  }
  departmentScheduleShiftUpsert(model: AddUserToSchedulerModel) {
    return httpAuth().get("/scheduler/InsertDepartmentScheduleShift", {
      params: {
        userId: model.userId,
        shiftTypeId: model.shiftTypeId,
        positionId: model.positionId,
        dateArray: model.dateArray
      }
    });
  }
}

export default new CalendareService();
