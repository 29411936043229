import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VeeValidate from "vee-validate";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import moment from "moment";
import {
  BootstrapVue,
  IconsPlugin,
  LayoutPlugin,
  FormCheckboxPlugin,
  ToastPlugin
} from "bootstrap-vue";

Vue.prototype.moment = moment;

import {
  ButtonPlugin,
  CheckBoxPlugin,
  SwitchPlugin
} from "@syncfusion/ej2-vue-buttons";
import { SchedulePlugin } from "@syncfusion/ej2-vue-schedule";
import {
  DatePickerPlugin,
  TimePickerPlugin,
  DateTimePickerPlugin
} from "@syncfusion/ej2-vue-calendars";
import { SidebarPlugin, MenuPlugin } from "@syncfusion/ej2-vue-navigations";
import { GridPlugin } from "@syncfusion/ej2-vue-grids";
import {
  DropDownListPlugin,
  MultiSelectPlugin
} from "@syncfusion/ej2-vue-dropdowns";
import { KanbanPlugin } from "@syncfusion/ej2-vue-kanban";
import {
  TextBoxPlugin,
  NumericTextBoxPlugin,
  UploaderPlugin
} from "@syncfusion/ej2-vue-inputs";
import { DialogPlugin, TooltipPlugin } from "@syncfusion/ej2-vue-popups";
import { CalendarPlugin } from "@syncfusion/ej2-vue-calendars";
import LoaderPlugin from "./plugins/globalLoaderPlugin";
Vue.use(ButtonPlugin);
Vue.use(CheckBoxPlugin);
Vue.use(SchedulePlugin);
Vue.use(DatePickerPlugin);
Vue.use(TimePickerPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(SidebarPlugin);
Vue.use(MenuPlugin);
Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);
Vue.use(KanbanPlugin);
Vue.use(TextBoxPlugin);
Vue.use(NumericTextBoxPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(SwitchPlugin);
Vue.use(UploaderPlugin);
Vue.use(MultiSelectPlugin);
Vue.use(DialogPlugin);
Vue.use(CalendarPlugin);
// Vue.use(Toasted);
Vue.use(ToastPlugin);
Vue.use(TooltipPlugin);

Vue.config.productionTip = false;

Vue.use(IconsPlugin);
Vue.use(BootstrapVue);
Vue.use(LayoutPlugin);

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields",
  errorBagName: "veeErrors"
});
Vue.use(VueLodash, { name: "custom", lodash });
Vue.use(LoaderPlugin);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
