import axios, { AxiosRequestConfig } from "axios";
export default ({ requiresAuth = true } = {}) => {
  const options: AxiosRequestConfig = {};
  const link = process.env.VUE_APP_API_URL;
  const token = localStorage.getItem("token");
  options.baseURL = link;
  options.headers = {
    "Content-type": "application/json",
    Access: "application/json"
  };
  if (requiresAuth) {
    options.headers.Authorization = "Bearer " + token;
  }
  const instance = axios.create(options);
  return instance;
};
