










































import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import { BaseMixin } from "@/mixins";
import Frag from "vue-frag";
import moment from "moment";
import {
  ParameterShiftTotalsModel,
  ShiftTotalsItemModelByUserId,
  SettingsSchedulerModel
} from "@/models";
import {
  createSpinner,
  showSpinner,
  hideSpinner
} from "@syncfusion/ej2-popups";
import { namespace } from "vuex-class";
const CalendarModule = namespace("CalendarModule");
const DepartmentModule = namespace("DepartmentModule");
const CalendarFilterModule = namespace("CalendarFilterModule");
@Component({
  directives: {
    Frag
  }
})
export default class SchedulerTotalsComponent extends Mixins(BaseMixin) {
  @CalendarModule.Action
  private loadingShiftTotalsInfoByDateRange!: (
    model: ParameterShiftTotalsModel
  ) => Promise<ShiftTotalsItemModelByUserId[]>;

  @DepartmentModule.Getter
  private currentDepartmentId!: number;
  @CalendarFilterModule.Getter
  private currentSchedulerFilter!: SettingsSchedulerModel;
  @CalendarFilterModule.Action
  private changeNoOfTotals!: (rows: number) => void;

  private shiftTotals: Array<object> = [];
  private selectedPositions: Array<string> = [];
  private selectedShifts: Array<string> = [];
  private isLoading = false;

  @Prop() noOfColumns!: number;
  @Prop() noOfWeeks!: number;
  @Prop() onTop!: boolean;
  @Prop() Columns!: Array<number | string>;
  @Prop() getBodyCSS!: (
    index: number,
    name: string,
    rowindex: number,
    length: number
  ) => Array<string>;
  @Prop() isDate!: (value: string | number) => boolean;
  @Prop() selectedDate!: Date;
  @Prop() freezeTable!: () => void;
  @Prop() onReload!: boolean;
  @Prop() isHidden!: () => boolean;

  @Watch("onReload")
  changeOnReload() {
    if (this.isLoading === false && this.onReload === true) {
      this.reload();
    }
  }
  @Watch("currentSchedulerFilter")
  changeFilterModel() {
    this.applyFilters();
  }

  mounted() {
    const { createSpinner, showSpinner, hideSpinner } = this.$globalLoader;
    createSpinner([
      {
        target: document.getElementById("totals") as HTMLElement
      }
    ]);
    this.reload();
    this.applyFilters();
  }

  get shiftTotalArray() {
    const shiftTotals = [] as any;
    const shifts = [] as any;
    const that = this as any;
    this.shiftTotals
      .filter(post => this.filter(post))
      .forEach(function(shiftTotal: any) {
        if (shiftTotal !== null && shifts.includes(shiftTotal.shift)) {
          const index = shiftTotals.findIndex(
            (total: any) => total.shift === shiftTotal.shift
          );
          const schedules = {} as any;
          for (const key in shiftTotal.schedules) {
            if (key in shiftTotals[index].schedules) {
              shiftTotals[index].schedules[key].required +=
                shiftTotal.schedules[key].required;
              shiftTotals[index].schedules[key].hired +=
                shiftTotal.schedules[key].hired;
              shiftTotals[index].schedules[key].class = that.getColor(
                shiftTotals[index].schedules[key].required,
                shiftTotals[index].schedules[key].hired
              );
              shiftTotals[index].schedules[key].value =
                shiftTotals[index].schedules[key].hired +
                "/" +
                shiftTotals[index].schedules[key].required;
            } else {
              schedules[key] = {
                required: shiftTotal.schedules[key].required,
                hired: shiftTotal.schedules[key].hired,
                class: that.getColor(
                  shiftTotal.schedules[key].required,
                  shiftTotal.schedules[key].hired
                ),
                value:
                  shiftTotal.schedules[key].hired +
                  "/" +
                  shiftTotal.schedules[key].required
              };
            }
          }
        } else if (shiftTotal !== null) {
          shifts.push(shiftTotal.shift);
          const schedules = {} as any;
          for (const key in shiftTotal.schedules) {
            schedules[key] = {
              required: shiftTotal.schedules[key].required,
              hired: shiftTotal.schedules[key].hired,
              class: that.getColor(
                shiftTotal.schedules[key].required,
                shiftTotal.schedules[key].hired
              ),
              value:
                shiftTotal.schedules[key].hired +
                "/" +
                shiftTotal.schedules[key].required
            };
          }
          shiftTotals.push({ shift: shiftTotal.shift, schedules: schedules });
        }
      });
    return shiftTotals;
  }

  private getColor(required: number, hired: number): string {
    return required == hired ? "green" : required > hired ? "red" : "purple";
  }
  private reload() {
    console.log("dep reload", this.currentDepartmentId);
    this.shiftTotals = []; // fix
    showSpinner(document.getElementById("totals") as HTMLElement);
    const that = this as any;
    if (
      this.currentDepartmentId !== undefined &&
      this.currentDepartmentId !== 0
    ) {
      this.isLoading = true;
      const data: ParameterShiftTotalsModel = {
        startDate: moment(this.selectedDate)
          .startOf("day")
          .toDate(),
        endDate: moment(this.selectedDate)
          .add(this.noOfWeeks, "w")
          .endOf("day")
          .toDate(),
        departmentId: this.currentDepartmentId
      };
      this.loadingShiftTotalsInfoByDateRange(data).then(
        (d: ShiftTotalsItemModelByUserId[]) => {
          d.forEach(function(arrayItem) {
            try {
              that.shiftTotals.push(JSON.parse(arrayItem.jsonData as string));
            } catch (error) {
              console.error("Error parsing " + arrayItem.jsonData);
            }
          });
          this.isLoading = false;
          this.changeNoOfTotals([
            ...new Set(this.shiftTotals.map((item:any) => item.shift))
          ].length + 1);
          hideSpinner(document.getElementById("totals") as HTMLElement);
          this.freezeTable();
        }
      );
    }
  }
  private filter(shiftTotal: any): boolean {
    const shifts = this.selectedShifts;
    const positions = this.selectedPositions;
    if (shifts.length === 0 && positions.length === 0) {
      return true;
    }
    if (shifts.includes(shiftTotal.shift)) {
      return true;
    }
    if (positions.includes(shiftTotal.position)) {
      return true;
    }
    return false;
  }
  private applyFilters() {
    this.noOfWeeks = this.currentSchedulerFilter.noOfWeeks;
    this.selectedShifts = this.currentSchedulerFilter.selectedShifts;
    this.selectedPositions = this.currentSchedulerFilter.selectedPositions;
  }
}
