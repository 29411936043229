








































































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";
import { BaseSideBarMixin } from "@/mixins";

@Component({
  components: {
    "side-menu-layout": SideMenuLayout
  }
})
export default class ComposeMessageMenuComponent extends Mixins(
  BaseSideBarMixin
) {
  activeMessageTypes = [
    {
      type: "message",
      iconClass: ["fas", "fa-envelope"],
      active: false
    },
    {
      type: "comment",
      iconClass: ["far", "fa-comment"],
      active: false
    },
    {
      type: "notification",
      iconClass: ["fas", "fa-phone"],
      active: false
    },
    {
      type: "voice",
      iconClass: ["fas", "fa-microphone"],
      active: false
    }
  ];
  messageType = [
    { value: "a", text: "Free Text" },
    { value: "b", text: "Test 1" },
    { value: "c", text: "Test 2" }
  ];

  priority = [
    { value: "a", text: "Normal" },
    { value: "b", text: "High" },
    { value: "c", text: "Test 2" }
  ];
  currentActiveType = "message";
  charsLeft = 300;
  messageText = "";
  appMesageActive = false;
  @Ref() textboxRef!: HTMLElement;

  inputHandler(args: any) {
    (this.textboxRef as any).removeAttributes(["maxlength"]);
    if (this.commentTypeSelected) {
      (this.textboxRef as any).addAttributes({ maxlength: 300 });
    }
    const word = this.messageText;
    const addressCount = word.length;

    this.charsLeft = 300 - addressCount;
  }

  changeActiveMessageType(type: string) {
    this.activeMessageTypes = this.activeMessageTypes.map(item => {
      item.active = item.type === type ? !item.active : item.active;
      return item;
    });
  }

  checkMessageType(type: any) {
    return type.active;
  }

  mounted() {
    this.addAttachFileIcon();
  }

  addAttachFileIcon() {
    (this.textboxRef as any).addIcon("append", ["fas fa-paperclip"]);
  }
  appMesageClick() {
    this.appMesageActive = !this.appMesageActive;
  }
  get commentTypeSelected() {
    return this.activeMessageTypes.filter(item => item.type === "comment")[0]
      .active;
  }

  @Watch("commentTypeSelected")
  onCommentTypeChange() {
    this.messageText = "";
    this.charsLeft = 300 - this.messageText.length;
  }
}
