












































import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
import { MenuEventArgs, Sidebar } from "@syncfusion/ej2-vue-navigations";

@Component
export default class Navigation extends Vue {
  @Ref("sidebarInstance") readonly sidebarInstance!: Sidebar;
  private width = "220px";
  private mediaQuery = "(min-width: 600px)";
  private target = ".main-content";
  private dockSize = "52px";
  private enableDock = true;
  @Auth.Getter
  private isLoggedIn!: boolean;
  private isAuth = false;
  public topMenuItems: object[] = [
    {
      text: "Day",
      iconCss: "fa-calendar-day",
      url: "/day"
    },
    {
      text: "Weekly",
      iconCss: "fa-calendar-week",
      url: "/weekly"
    },
    {
      text: "Month",
      iconCss: "fa-calendar-alt",
      url: "/month"
    },
    {
      text: "Scheduler",
      iconCss: "fa-grip-horizontal",
      url: "/scheduler"
    }
  ];
  public bottomMenuItems: object[] = [
    {
      text: "Employees",
      iconCss: "fa-users",
      url: "/search-staff"
    },
    {
      text: "Report Centers",
      iconCss: "fa-chart-bar",
      url: "/users"
    },
    {
      text: "Settings/Configurations",
      iconCss: "fa-cog",
      url: "/users"
    }
  ];
  private onCreated() {
    if (this.sidebarInstance) {
      this.sidebarInstance.hide();
    }
  }
  computed() {
    this.isAuth =
      localStorage.getItem("token") !== undefined &&
      localStorage.getItem("token") !== null;
  }
  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    this.isAuth =
      localStorage.getItem("token") !== undefined &&
      localStorage.getItem("token") !== null;
  }

  private navigateTo(value: string) {
    this.$router.push("/" + value);
  }
  private onBeforeItemRender(args: MenuEventArgs) {
    console.log(args.item);
  }

  private changeMenuStatus() {
    this.sidebarInstance.toggle();
  }
}
