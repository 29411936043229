











import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { BaseSideBarMixin } from "@/mixins";
import { namespace } from "vuex-class";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";
const SideBar = namespace("SideBar");
const ShiftModule = namespace("ShiftModule");
import { WeeklyItemModel } from "@/models";
import { IconCardTemplateComponent } from "@/common";

@Component({
  components: {
    "side-menu-layout": SideMenuLayout,
    IconCardTemplateComponent
  }
})
export default class DailyIconsComponent extends Mixins(BaseSideBarMixin) {
  @SideBar.Getter
  private currentActiveMenuItem!: WeeklyItemModel[];

  model: WeeklyItemModel[] = { ...this.currentActiveMenuItem };
  loading = false;

  @Watch("currentActiveMenuItem", { immediate: true, deep: true })
  changeCurrentActiveMenuItem() {
    console.log("currentActiveMenuItem change", this.currentActiveMenuItem);
  }

  async mounted() {
    this.model = { ...this.currentActiveMenuItem };
  }
}
