






































import { Component, Vue } from "vue-property-decorator";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";

@Component({
  components: {
    "side-menu-layout": SideMenuLayout
  }
})
export default class ManageAccessLevelsComponent extends Vue {
  tableData = {
    fields: ["position", "description", "actions"],
    unassignedLevels: [
      {
        position: "RN",
        description: "Registered Nurse"
      },
      {
        position: "RN",
        description: "Registered Nurse"
      },
      {
        position: "RN",
        description: "Registered Nurse"
      },
      {
        position: "RN",
        description: "Registered Nurse"
      }
    ],
    assignedLevels: [
      {
        position: "Charge",
        description: "Charge Access"
      }
    ]
  };
}
