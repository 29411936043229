




























import { Component, Vue } from "vue-property-decorator";
import {
  Filter,
  Selection,
  Sort,
  VirtualScroll
} from "@syncfusion/ej2-vue-grids";
import { namespace } from "vuex-class";
import { SideBarType, SideMenuItem } from "@/models";
import { UspEmployeeCertsByUserId } from "@/models/employee-profile/employee.response.model";
import StatusTemplate from "./template/statusTemplate/component.vue";
import ActivityTemplate from "./template/activityTemplate/component.vue";
import moment from "moment";

const SideBar = namespace("SideBar");
const Profile = namespace("Profile");
@Component({
  provide: {
    grid: [Sort, Filter, Selection, VirtualScroll]
  }
})
export default class EmployeeLicensesComponent extends Vue {
  customAttributes = { class: "customcss" };
  @SideBar.Action
  private toggleSideMenu!: (type: SideMenuItem) => void;

  @Profile.Getter
  private employeeCerts!: UspEmployeeCertsByUserId[];

  toggleAddLicenseMenu(cert: UspEmployeeCertsByUserId) {
    this.toggleSideMenu({ type: SideBarType.AddLicense, item: { ...cert } });
  }

  statusTemplate() {
    return {
      template: StatusTemplate
    };
  }

  expDateSpanColor(cert: UspEmployeeCertsByUserId) {
    const formattedDate = moment(cert.userProfile_CertExp);
    const now = moment();
    const difference = formattedDate.diff(now, "days");
    if (difference > 0 && difference < 30) {
      return "#FF0000";
    } else if (difference <= 0) {
      return "#FF0000";
    } else {
      return "#044444";
    }
  }
  certStatusColor(cert: UspEmployeeCertsByUserId) {
    const formattedDate = moment(cert.userProfile_CertExp);
    const now = moment();
    const difference = formattedDate.diff(now, "days");
    if (difference <= 0) {
      return "#FCBBBB";
    }
    if (difference > 0 && difference < 30) {
      return "#FFFFCC";
    }
    if (difference > 30) {
      return "#8EDE7F";
    }
  }
  imageErrorFallback(cert: UspEmployeeCertsByUserId) {
    cert.imageHasErrorLoading = true;
  }

  formatExpDate(certDate: string) {
    const formattedMoment = moment(certDate);
    const formattedDate = formattedMoment.format("MM/DD/YYYY").toString();
    const now = moment();
    const difference = formattedMoment.diff(now, "days");
    let status;
    if (difference <= 0) {
      status = "(Expired)";
    }
    if (difference > 0 && difference < 30) {
      status = "(Expires soon)";
    }
    if (difference > 30) {
      status = "";
    }
    return `${formattedDate} ${status}`;
  }
  activityTemplate() {
    return {
      template: ActivityTemplate
    };
  }
}
