import httpAuth from "../common/http/http-common";
import {
  DepartmentAssignmentGroup,
  DepartmentSettingsDropdownsData,
  UspActivityLogByEmployee,
  UspDepartmentAssignmentsByEmployee,
  UspEmployeeCertsByUserId,
  UspEmployeeDetailByUserId
} from "@/models/employee-profile/employee.response.model";
import { DropdownSelect } from "@/models/common/dropdown.model";

class ProfileService {
  getEmployeeDetailsByUserId(userId: number) {
    return httpAuth().get<UspEmployeeDetailByUserId>(
      "/employeeProfile/EmployeeDetailsByUserId",
      {
        params: {
          userId
        }
      }
    );
  }
  getEmployeeCertsByUserId(userId: number) {
    return httpAuth().get<UspEmployeeCertsByUserId[]>(
      "/employeeProfile/EmployeeCertsByUserId",
      {
        params: {
          userId
        }
      }
    );
  }
  // getLocationDepartmentsByCompanyId(companyId: number) {
  //   return httpAuth.get<UspLocationDepartmentsByCompanyId[]>(
  //     "/employeeProfile/EmployeeDepratmentsLocationsByCompanyId",
  //     {
  //       params: {
  //         companyId
  //       }
  //     }
  //   );
  // }

  getActivityLogByEmployee(userId: number) {
    return httpAuth().get<UspActivityLogByEmployee[]>(
      "/employeeProfile/GetActivityLogByEmployee",
      {
        params: {
          userId
        }
      }
    );
  }
  getCompanyCerts() {
    return httpAuth().get<DropdownSelect[]>(
      "/employeeProfile/GetCompanyCertsForDropdown"
    );
  }
  getEducationLevels() {
    return httpAuth().get<DropdownSelect[]>(
      "/employeeProfile/GetEducationLevels"
    );
  }
  getDepartmentAssignmentsByEmployee(userId: number) {
    return httpAuth().get<UspDepartmentAssignmentsByEmployee[]>(
      "/employeeProfile/GetDepartmentAssignmentsByEmployee",
      {
        params: {
          userId
        }
      }
    );
  }
  getDropdownsDataForDepartmentSettings(departmentId: number) {
    return httpAuth().get<DepartmentSettingsDropdownsData>(
      "/employeeProfile/GetDropdownsDataForDepartmentSettings",
      {
        params: {
          departmentId
        }
      }
    );
  }
  getUnassignedDepartmentsByUserId(userId: number) {
    return httpAuth().get<UspDepartmentAssignmentsByEmployee[]>(
      "/employeeProfile/GetUnassignedDepartments",
      {
        params: {
          userId
        }
      }
    );
  }
  getDepartmentAssignmentGroupsByUserId(userId: number) {
    return httpAuth().get<DepartmentAssignmentGroup[]>(
      "/employeeProfile/GetDepartmentAssignmentGroups",
      {
        params: {
          userId
        }
      }
    );
  }
  updateUserLoginDisabled(userId: number, loginDisabled: boolean) {
    return httpAuth().get("/employeeProfile/UpdateUserLoginDisabled", {
      params: {
        userId,
        loginDisabled
      }
    });
  }
  departmentAssignmentByGroupUpsert(
    userId: number,
    departmentAssignmentGroupId: number
  ) {
    return httpAuth().get("/employeeProfile/InsertDepartmentAssignmentGroup", {
      params: {
        userId,
        departmentAssignmentGroupId
      }
    });
  }
  insertEmployeeCerts(data: UspEmployeeCertsByUserId) {
    return httpAuth().post("/employeeProfile/InsertEmployeeLicense", {
      ...data
    });
  }
  insertDeptAssignment(userId: number, companyDepartmentID: number) {
    return httpAuth().get("/employeeProfile/InsertDepartmentAssignment", {
      params: {
        userId,
        companyDepartmentID
      }
    });
  }
  updateEmployeeDetailsByUserId(data: UspEmployeeDetailByUserId) {
    return httpAuth().post("/employeeProfile/UpdateEmployeeDetailByUserId", {
      ...data
    });
  }
  updateDepAssignment(data: UspDepartmentAssignmentsByEmployee) {
    return httpAuth().post("/employeeProfile/UpdateDepAssignment", {
      ...data
    });
  }
  deleteDepartmentAssignment(companyDepartmentStaffID: number) {
    return httpAuth().get("/employeeProfile/DeleteDepartmentAssignment", {
      params: {
        companyDepartmentStaffID
      }
    });
  }
}

export default new ProfileService();
