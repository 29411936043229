





import { Component, Vue, Watch } from "vue-property-decorator";
import { Card } from "@/components/calendar/weekly/template";
@Component({
  components: { Card }
})
export default class CardTemplate extends Vue {
  private data: object = {};
  @Watch("data") changeData(old: object, n: object) {
    console.log("data", this.data, old, n);
  }
}
