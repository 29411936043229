import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import DepartmentService from "@/services/DepartmentService";
import { DepartmentEmployeeModel } from "@/models";

@Module({ namespaced: true })
export default class DepartmentModule extends VuexModule {
  failureMessage = "";
  selectedDepartment = {};
  selectedDepartmentId = 0;
  @Mutation
  failure(error: string): void {
    this.failureMessage = error;
  }

  @Mutation
  updateSelectedDepartment(dep: DepartmentEmployeeModel) {
    this.selectedDepartment = dep;
    this.selectedDepartmentId = dep.companyDepartmentId;
  }

  @Action({ rawError: true })
  changeDepartment(dep: DepartmentEmployeeModel) {
    this.context.commit("updateSelectedDepartment", dep);
  }

  @Action({ rawError: true })
  async getDepartmentByEmployee(userId: number) {
    try {
      const response = await DepartmentService.getDepartmentsByEmployee(userId);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error login: ", message);
      this.context.commit("failure", message);
    }
    return [];
  }

  get currentDepartment() {
    return this.selectedDepartment;
  }

  get currentDepartmentId() {
    return this.selectedDepartmentId;
  }

  get userLoginTypeIdForDepartment() {
    return (this.selectedDepartment as DepartmentEmployeeModel).loginTypeID;
  }
}
