import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import moment from "moment";
const SideBar = namespace("SideBar");
@Component
export default class BaseMixin extends Vue {
  @SideBar.Action
  resetState!: () => void;

  backgroundColorArray = [
    "#300241",
    "#7f3f97",
    "#8dc63f",
    "#da4444",
    "#3378b6"
  ];

  colorArray = [
    "#6262ef",
    "#db5656",
    "#ef7be4",
    "#e8a370",
    "#7add7d",
    "#af8fd1"
  ];

  getWeekStart(date: Date, weekStartDay = 0): string {
    let startDay = moment(date)
      .weekday(weekStartDay)
      .format("MM/DD/YYYY");
    if (moment(date) < moment(startDay)) {
      startDay = moment(startDay)
        .subtract(1, "week")
        .format("MM/DD/YYYY");
    }
    return startDay;
  }

  formatUSNumber(entry = "") {
    const replace = entry.replace(/\D+/g, "").replace(/^1/, "");
    const match = replace.match(/([^\d]*\d[^\d]*){1,10}$/);
    if (match) {
      const part1 =
        match[0].length > 2 ? `(${match[0].substring(0, 3)})` : match[0];
      const part2 = match[0].length > 3 ? ` ${match[0].substring(3, 6)}` : "";
      const part3 = match[0].length > 6 ? `-${match[0].substring(6, 10)}` : "";
      return `${part1}${part2}${part3}`;
    } else {
      return "";
    }
  }

  formatHireDate(hireDate: string) {
    const date = moment(hireDate);
    if (date.isValid()) {
      return date.format("MM/DD/YYYY");
    } else {
      return "";
    }
  }

  getChangedSettings(newData: any, old: any) {
    const changed: any[] = [];
    this._.reduce(
      newData,
      (result: any, value: any, key: any) => {
        return this._.isEqual(value, old[key])
          ? changed
          : changed.push({
              key,
              newValue: value,
              oldValue: old[key]
            });
      },
      []
    );
    return changed;
  }

  percentEmpUsedEx(
    periodStartDateString: string,
    periodStartTime: string,
    periodEndTime: string,
    empStartDateString: string,
    empStartTime: string,
    empEndTime: string,
    threshold: number
  ): number {
    const empStartDate = moment(
      empStartDateString + " " + empStartTime
    ).toDate();
    const startDate = moment(
      periodStartDateString + " " + periodStartTime
    ).toDate();
    let empEndDate = moment(empStartDateString + " " + empEndTime).toDate();
    let endDate = moment(periodStartDateString + " " + periodEndTime).toDate();
    empEndDate =
      empEndDate < empStartDate
        ? moment(empEndDate)
            .add(1, "days")
            .toDate()
        : empEndDate;
    endDate =
      endDate < startDate
        ? moment(endDate)
            .add(1, "days")
            .toDate()
        : endDate;
    const retval = this.percentEmpUsed(
      startDate,
      endDate,
      empStartDate,
      empEndDate
    );
    return retval < threshold ? 0 : 1;
  }

  percentEmpUsed(
    startDate: Date,
    endDate: Date,
    empStartDate: Date,
    empEndDate: Date
  ): number {
    const sd = startDate > empStartDate ? startDate : empStartDate;
    const ed = endDate > empEndDate ? empEndDate : endDate;
    let retVal = 0.0;
    if (sd <= ed) {
      const totalSecs = (startDate.valueOf() - endDate.valueOf()) / 1000;
      const usedSecs = (sd.valueOf() - ed.valueOf()) / 1000;
      retVal = totalSecs !== 0 ? usedSecs / totalSecs : 0.0;
    }
    return retVal;
  }

  afterMidnight(startDate: string): boolean {
    return (
      startDate == "00:00" ||
      startDate == "01:00" ||
      startDate == "02:00" ||
      startDate == "03:00" ||
      startDate == "04:00" ||
      startDate == "05:00"
    );
  }
}
