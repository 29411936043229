














































import { PermissionMixin } from "@/mixins";
import { SideBarType, SideMenuItem } from "@/models";
import { Component, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";

const SideBar = namespace("SideBar");
const Profile = namespace("Profile");
@Component
export default class EmployeeActionCardComponent extends Mixins(
  PermissionMixin
) {
  @SideBar.Action
  toggleSideMenu!: (type: SideMenuItem) => void;

  @Profile.Getter
  currentUserId!: number;

  openSidebar(type: SideBarType) {
    this.toggleSideMenu({ type, item: {} });
  }

  navToMonthView(type: string) {
    this.$router.push({
      path: `/month`,
      query: {
        userId: String(this.currentUserId),
        redirectType: type
      }
    });
  }
}
