




import { Component, Vue, Watch, Prop } from "vue-property-decorator";
@Component
export default class GroupByFilterComponent extends Vue {
  @Prop() data!: object;
  @Prop() type = "";

  @Watch("data", { immediate: true, deep: true })
  changeData() {
    console.log("Data", this.data);
  }
}
