















































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { BaseSideBarMixin } from "@/mixins";
import { namespace } from "vuex-class";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";
const SideBar = namespace("SideBar");
const ShiftModule = namespace("ShiftModule");
const CalendarModule = namespace("CalendarModule");
const DepartmentModule = namespace("DepartmentModule");
const Toast = namespace("ToastModule");
import { StaffActionCardTemplate, CalendarTemplateComponent } from "@/common";
import {
  ChangeEventArgs as ChangeEventArgsDD,
  DropDownList
} from "@syncfusion/ej2-vue-dropdowns";
import {
  ShiftType,
  SideBarType,
  ToastOptions
} from "@/models";
import {
  ValidationObserver,
  ValidationProvider,
  ValidationObserverInstance
} from "vee-validate";
import {
  createSpinner,
  showSpinner,
  hideSpinner
} from "@syncfusion/ej2-popups";
import moment from "moment";
@Component({
  components: {
    "side-menu-layout": SideMenuLayout,
    ValidationObserver,
    ValidationProvider,
    StaffActionCardTemplate,
    CalendarTemplateComponent
  }
})
export default class DeleteEmployeeShiftComponent extends Mixins(
  BaseSideBarMixin
) {
  @Ref("valObs") readonly valObs!: ValidationObserverInstance;
  private specialDates: Record<string, any>[] = []; // fix
  private shiftTypes: ShiftType[] = [];
  private localShiftFields = { 
    text: "shiftType", 
    value: "shiftTypeId" 
  };

  @Toast.Action
  private showToast!: (toastOptions: ToastOptions) => void;

  @SideBar.Getter
  private currentActiveMenuItem!: any; // fix

  @CalendarModule.Getter
  isShowError!: boolean;
  @CalendarModule.Action
  cleanError!: () => Promise<void>;

  created() {
    const selected = this.currentActiveMenuItem?.selected ?? -1;
    const shift = this.currentActiveMenuItem.actions[selected] as any;
    this.shiftTypes = [
      {
        shiftType: shift.shiftType,
        shiftTypeId: shift.shiftTypeId,
        companyDepartmentId: shift.departmentId,
        shiftTypeSortOrder: null,
        shiftTimeOfDayStart: "",
        shiftTimeOfDayEnd: "",
        "shiftPeriodStartTime_ms": 0,
        "shiftPeriodEndTime_ms": 0
      }
    ];
  }
  mounted() {
    if (this.valObs) {
      this.valObs.reset();
    }
    const { createSpinner, showSpinner, hideSpinner } = this.$globalLoader;
    createSpinner([
      {
        target: document.getElementById("side-menu") as HTMLElement
      }
    ]);
  }

  private async onSubmit() {
    // fix
    console.log("on submit");
    const isValid = await this.valObs.validate();
    if (!isValid) {
      return;
    }
  }
  private employeeDay() {
    this.toggleSideBarMenu({
      type: SideBarType.EmployeeDay,
      item: this.currentActiveMenuItem
    });
  }
}
