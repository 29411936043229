import {
  createSpinner,
  showSpinner,
  hideSpinner,
  SpinnerArgs,
  SpinnerType,
  createElementParams
} from "@syncfusion/ej2-popups";

const defaultSettings: { label: string; type: SpinnerType } = {
  label: "Loading...",
  type: "Bootstrap"
};
const LoaderPlugin = {
  install(Vue: any, options?: any) {
    Vue.prototype.$globalLoader = {
      createSpinner: (
        args: SpinnerArgs[] | SpinnerArgs,
        internalCreateElement?: createElementParams
      ) => {
        if (Array.isArray(args)) {
          args.forEach(item => {
            createSpinner(
              { ...defaultSettings, ...item },
              internalCreateElement
            );
          });
        } else {
          createSpinner({ ...defaultSettings, ...args }, internalCreateElement);
        }
      },
      hideSpinner,
      showSpinner: (container: HTMLElement | HTMLElement[]) => {
        if (Array.isArray(container)) {
          container.forEach(item => {
            showSpinner(item);
          });
        } else {
          showSpinner(container);
        }
      }
    };
  }
};

export default LoaderPlugin;
