import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { WeeklyItemModelByKeyItem } from "@/models";
@Component
class BaseCardHeaderMixin extends Vue {
  @Prop() data!: WeeklyItemModelByKeyItem;
  @Prop() cssClass!: string;
  isChildsOpen = true;
  @Emit("open-click") changeOpen() {
    return this.isChildsOpen;
  }

  private openChilds() {
    this.isChildsOpen = !this.isChildsOpen;
    this.changeOpen();
  }
}

export default BaseCardHeaderMixin;
