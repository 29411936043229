














































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import StaffService from "@/services/StaffService";
import UserTemplate from "./templates/user-name-template.vue";
import {
  Sort,
  Filter,
  Page,
  GridComponent,
  Resize
} from "@syncfusion/ej2-vue-grids";
import { namespace } from "vuex-class";
import {
  DepartmentEmployeeModel,
  UspDepartmentStaffSearch,
  AccessLevel
} from "@/models";
import { HeaderFilter } from "@/common";
import { BaseCalendarMixin } from "@/mixins";

const DepartmentModule = namespace("DepartmentModule");
@Component({
  components: {
    HeaderFilter
  },
  provide: {
    grid: [Sort, Filter, Page, Resize]
  }
})
export default class SearchStaffComponent extends Mixins(BaseCalendarMixin) {
  searchStaffData: UspDepartmentStaffSearch[] = [];
  loadedData: UspDepartmentStaffSearch[] = [];
  customAttributes = { class: "search-customcss" };
  filterOptions = {
    showFilterBarStatus: false,
    mode: "Immediate"
  };
  pageSettings: object = {
    pageSizes: [20, 50, 100, 200],
    pageCount: 4,
    pageSize: 20
  };

  @Ref("searchstaff") searchStaffGrid!: GridComponent;

  @DepartmentModule.Getter
  currentDepartment!: DepartmentEmployeeModel;
  created() {
    const { companyDepartmentId, loginTypeID } = this.currentDepartment;
    this.loadDepData(companyDepartmentId, loginTypeID);
  }

  mounted() {
    this.type = "searchStaff";
  }
  @Watch("currentDepartmentId", { immediate: true, deep: true })
  async loadDepStaffOnChange() {
    const { companyDepartmentId, loginTypeID } = this.currentDepartment;
    this.loadDepData(companyDepartmentId, loginTypeID);
  }

  async loadDepData(depId: number, access: AccessLevel) {
    if (depId) {
      let staffData = (
        await StaffService.getAllStaffByDepartmentId(depId, access)
      ).data;
      staffData = this._.map(staffData, item => {
        item.positionListArray = item.positionList
          .split(",")
          .map(item => item.trim());
        return item;
      });
      this.searchStaffOriginalData = staffData;
      this.filteredSearchStaffData = [...this.searchStaffOriginalData];
    }
  }

  handleChange(val: string) {
    if (val) {
      this.filteredSearchStaffData = this._.filter(
        this.filteredSearchStaffData,
        record => {
          return this._.includes(record.userFullName, val);
        }
      );
    } else {
      this.applySearchStaffFilter();
    }
  }
  userTemplate() {
    return {
      template: UserTemplate
    };
  }
  rowSelected() {
    const selectedrecords = this.searchStaffGrid.getSelectedRecords()[0] as UspDepartmentStaffSearch;
    this.$router.push(`/employee-profile/${selectedrecords.userId}/details`);
  }
}
