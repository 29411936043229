

























import { Component, Vue, Watch } from "vue-property-decorator";
import {
  MonthItemModelByKey,
  WeeklyItemModel,
  MonthUser,
  MonthShiftType
} from "@/models";
@Component({})
export default class EventTemplate extends Vue {
  private data!: MonthItemModelByKey;
  private shiftTypes: MonthShiftType[] = [];
  private users: MonthUser[] = [];
  @Watch("data", { deep: true, immediate: true })
  changeData() {
    this.getDataForEvent();
  }

  getDataForEvent() {
    if (this.data && this.data.childs) {
      this._.forEach(this.data.childs, c => {
        const u = {
          userId: c.userId,
          fullName: `${c.user_FName} ${c.user_LName}`,
          img: ""
        };
        const findUser = this._.find(this.users, f => {
          return f.userId === u.userId;
        });
        if (!findUser) {
          this.users.push(u);
        }
      });
      const meberPos = this.groupChilds(this.data.childs);
      this.shiftTypes = meberPos;
    }
  }

  private groupChilds(data: WeeklyItemModel[]) {
    this._.orderBy(data, ["memberPosition"]);
    const items = this._.chain(data)
      .groupBy("memberPosition")
      .map((value, key) => ({
        key: key,
        numberOfEmpReq: value[0].numberOfEmpReq,
        numberOfEmpHired: value[0].numberOfEmpHired,
        // items: value,
        color: this.getRandomColor()
      }))
      .value();
    return items;
  }

  getTransform(index: number): string {
    const angle = -70;
    const rot = index * 15 + angle;
    const t = `rotate(${rot}deg)   translate(15px)   rotate(0deg) `;
    return t;
  }

  getImgStyle(index: number) {
    const color = this.getRandomColor();
    const left = index + 9;
    const val = {
      border: `1px solid ${color}`,
      left: `${left}px`
    };
    return val;
  }

  getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
}
