











import { Component, Mixins, Prop } from "vue-property-decorator";
import { CardMixin } from "@/mixins";
@Component({
  components: {}
})
export default class IconTypeButtonTemplateComponent extends Mixins(
  CardMixin
) {}
