





































import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import {
  WeeklyItemModelByKeyItem,
  WeeklyItemModel,
  GroupByFilterEnum
} from "@/models";
import cardTemplate from "./weeklyCard.vue";
import { Increment } from "@/common";
import { ShiftTypeHeader, MemberPositionHeader } from "./headers";
@Component({
  name: "CardGroup",
  components: {
    cardTemplate,
    Increment,
    ShiftTypeHeader,
    MemberPositionHeader
  }
})
export default class CardGroup extends Vue {
  @Prop() child!: WeeklyItemModelByKeyItem;
  @Prop() type!: string;
  private isChildsOpen = true;
  private color = "";
  GroupByFilterEnum = GroupByFilterEnum;
  @Watch("child", { immediate: true, deep: true }) childChange() {
    this.getColor();
  }
  @Emit("open-edit-shift")
  private onEditShift(data: WeeklyItemModel) {
    return data;
  }
  private onCardActionClick(data: WeeklyItemModel) {
    this.onEditShift(data);
  }
  private valueChange(value: number) {
    this.child.numberOfEmpHired = value;
    this.getColor();
  }
  updateOpenstatus(value: boolean) {
    this.isChildsOpen = value;
  }
  private getColor() {
    this.color =
      this.child.numberOfEmpReq == this.child.numberOfEmpHired
        ? "green"
        : this.child.numberOfEmpReq > this.child.numberOfEmpHired
        ? "red"
        : this.child.numberOfEmpReq < this.child.numberOfEmpHired
        ? "purple"
        : "";
  }

  getClass() {
    let value = `level${this.child.level}`;
    if (this.child.level > 1) {
      value += " " + this.color;
    }
    return value;
  }

  editItem() {
    console.log("edit item ", this.child);
  }
}
