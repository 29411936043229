




















import { Component, Mixins } from "vue-property-decorator";
import { CardMixin } from "@/mixins";
import ProfileLogoTemplateComponent from "@/common/template/profile-logo/component.vue";
@Component({
  components: { ProfileLogoTemplateComponent }
})
export default class StaffCardTemplateComponent extends Mixins(CardMixin) {}
