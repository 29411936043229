




import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class/lib/bindings";
import { SideBarType, SideMenuItem } from "@/models";

const SideBar = namespace("SideBar");
@Component
export default class ActivityTemplate extends Vue {
  @SideBar.Action
  private toggleSideMenu!: (type: SideMenuItem) => void;

  smthToClick() {
    this.toggleSideMenu({ type: SideBarType.AddLicense, item: {} });
  }
}
