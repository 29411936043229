





















import ProfileLogoTemplateComponent from "@/common/template/profile-logo/component.vue";
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import { CardMixin } from "@/mixins";
import { namespace } from "vuex-class";
import { SideMenuItem, SideBarType } from "@/models";
const SideBar = namespace("SideBar");
@Component({
  components: {
    ProfileLogoTemplateComponent
  }
})
export default class EditShiftCardTemplate extends Mixins(CardMixin) {
  @Prop() data!: any;

  private onClick() {
    this.$emit("remove-card", this.$vnode.key);
  }
}
