















import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { FilterBase, FilterModel, FilterProperties } from "@/models";
@Component({ name: "FilterByIntegerRange" })
export default class FilterByIntegerRangeComponent extends Vue
  implements FilterBase {
  @Prop() filter!: FilterModel;
  @Prop() data!: FilterProperties;

  startInt?: number | undefined | null = null;
  endInt?: number | undefined | null = null;

  @Watch("data", { immediate: true, deep: true })
  changeData() {
    this.setDefaultValue();
  }

  @Watch("filter", { immediate: true, deep: true })
  changeFilter() {
    this.setDefaultValue();
  }

  validate() {
    console.log("integer range by validate");
  }
  collectValue(): FilterProperties | undefined {
    console.log("integer range by collectValue", this.startInt, this.endInt);
    let startVal = undefined;
    let endVal = undefined;
    if (this.startInt != null) {
      startVal = this.numberOnly(this.startInt);
    }
    if (this.endInt != null) {
      endVal = this.numberOnly(this.endInt);
    }
    if (!startVal && !endVal) {
      return undefined;
    }
    return {
      startIntValue: startVal,
      endIntValue: endVal,
      key: this.data.key
    } as FilterProperties;
  }

  clearValue() {
    this.startInt = null;
    this.endInt = null;
  }

  numberOnly(value: number | undefined) {
    if (!value || (value && !value.toString().match(/^\d+$/))) {
      return undefined;
    } else {
      return parseInt(value.toString(), 10);
    }
  }

  setDefaultValue() {
    if (this.data && this.filter && this.filter.filterBy.length) {
      const val = this._.find(this.filter.filterBy, f => {
        return f.key == this.data.key;
      });
      console.log("setDefaultValue", val);
      if (val) {
        this.startInt = val.startIntValue;
        this.endInt = val.endIntValue;
      } else {
        this.startInt = undefined;
        this.endInt = undefined;
      }
    }
  }
}
