

































import { Component, Vue, Watch, Prop, Ref, Emit } from "vue-property-decorator";
import {
  FilterModel,
  FilterByEnum,
  FilterProperties,
  DropdownSelect
} from "@/models";
import FilterByWrapperComponent from "@/common/filter/template/filterBy/filterByWrapper.vue";
@Component({
  components: { "filter-wrapper": FilterByWrapperComponent }
})
export default class FilterComponent extends Vue {
  @Ref("filterBy") readonly filterBys!: Array<FilterByWrapperComponent>;
  @Prop() filter!: FilterModel;
  @Prop() type!: string;
  @Prop() sourceData!: object[];

  FilterByEnum = FilterByEnum;

  secondColumn?: FilterProperties[];
  firstColumn?: FilterProperties[];
  thirdColumn?: FilterProperties[];
  isFilterLoaded = false;

  @Watch("sourceData", { immediate: true, deep: true })
  changeSourceData() {
    this.buidlDefaultByFilter();
  }
  @Watch("type", { immediate: true, deep: true })
  changeType() {
    console.log("filter By filter component", this.type);
    this.buidlDefaultByFilter();
  }
  @Watch("filter", { immediate: true, deep: true })
  changefilter() {
    console.log("filter By filter component", this.filter);
  }

  @Emit("filterLoaded") loadedFiler(value: boolean) {
    return value;
  }

  validate() {
    console.log("filter by wrapper validate");
  }
  collectValue() {
    if (this.filterBys.length) {
      const filterByValues = [] as FilterProperties[];
      this._.forEach(this.filterBys, filter => {
        const val = filter.collectValue();
        if (val && val != undefined) {
          filterByValues.push(val);
        }
      });
      this.filter.filterBy = filterByValues;
    }
  }

  clearValue() {
    if (this.filterBys.length) {
      this._.forEach(this.filterBys, filter => {
        filter.clearValue();
      });
    }
  }

  buidlDefaultByFilter() {
    this.isFilterLoaded = false;
    this.firstColumn = [];
    this.secondColumn = [];
    this.thirdColumn = [];
    if (this.type) {
      const filterDefault = [
        {
          key: FilterByEnum.Permissions,
          text: "Permission",
          columnType: "searchStaff",
          column: 2
        } as FilterProperties,
        {
          key: FilterByEnum.AccessLevels,
          text: "Access Level",
          columnType: "searchStaff",
          column: 1
        } as FilterProperties,
        {
          key: FilterByEnum.Title,
          text: "Title",
          columnType: "searchStaff",
          column: 2
        } as FilterProperties,
        {
          key: FilterByEnum.DisabledStaff,
          text: "Disabled Staff",
          columnType: "searchStaff",
          column: 1
        } as FilterProperties,
        {
          key: FilterByEnum.FTEType,
          text: "FTE",
          columnType: "searchStaff",
          column: 2
        } as FilterProperties,
        {
          key: FilterByEnum.Group,
          text: "Group",
          columnType: "searchStaff",
          column: 2
        } as FilterProperties,
        {
          key: FilterByEnum.ShiftType,
          text: "Shift Type",
          columnType: "day|weekly|month",
          column: 2
        } as FilterProperties,
        {
          key: FilterByEnum.Position,
          text: "By Position",
          columnType: "day|weekly|month",
          column: 2
        } as FilterProperties,
        {
          key: FilterByEnum.PositionCategory,
          text: "Position Category",
          columnType: "day|weekly|month",
          column: 2
        } as FilterProperties,
        {
          key: FilterByEnum.FTEPercent,
          text: "FTE %",
          columnType: "day|weekly|month",
          column: 2
        } as FilterProperties,
        {
          key: FilterByEnum.Employee,
          text: "Employee",
          columnType: "day|weekly|month",
          column: 2
        } as FilterProperties,
        {
          key: FilterByEnum.OpenShifts,
          text: "Open Shifts",
          columnType: "day|weekly|month",
          column: 1
        } as FilterProperties,
        {
          key: FilterByEnum.HideOpenShifts,
          text: "Hide Open Shifts",
          columnType: "day|weekly|month",
          column: 1
        } as FilterProperties,
        {
          key: FilterByEnum.Overtime,
          text: "OT",
          columnType: "day|weekly|month",
          column: 1
        } as FilterProperties,
        {
          key: FilterByEnum.ShiftBeginTime,
          text: "Shift Begin Time",
          columnType: "day|weekly|month",
          column: 1
        } as FilterProperties
      ];

      let filterByType = this._.filter(filterDefault, s => {
        return s.columnType.indexOf(this.type) > -1;
      });
      filterByType = this._.sortBy(filterByType, "text");

      if (filterByType.length && this.sourceData) {
        this._.forEach(filterByType, f => {
          let values = this._.map(this.sourceData, f.key);
          if (f.key === FilterByEnum.Permissions) {
            values = JSON.parse(JSON.stringify(values));
            // to map 2D arrays. for ex.: [[1, 2], [2, 3], [...]]
            values = this._.flatten(values);
          }
          const uniqueValues = this._.orderBy(this._.uniq(values));
          const val = this._.map(uniqueValues, c => {
            return {
              value: c,
              text: c
            } as DropdownSelect;
          });
          f.selectorValues = val;
          console.log("select by KEY: ", f.key, val);
        });
      }

      this._.forEach(filterByType, ft => {
        if (ft.column == 1) {
          this.firstColumn?.push(ft);
        } else if (ft.column == 2) {
          this.secondColumn?.push(ft);
        } else {
          this.thirdColumn?.push(ft);
        }
      });
    } else {
      this.firstColumn = [];
      this.secondColumn = [];
      this.thirdColumn = [];
    }

    this.isFilterLoaded = true;
    this.loadedFiler(this.isFilterLoaded);
    console.log("filters", this.firstColumn, this.secondColumn);
  }
}
