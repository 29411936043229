import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { FilterModel, SettingsSchedulerModel } from "@/models";

@Module({ namespaced: true })
export default class CalenarFilterModule extends VuexModule {
  filter: FilterModel = {
    view: "1",
    showDepIcons: false,
    dontEnclude: false,
    darkMode: false,
    showSummary: false,
    shiftGroupBy: false,
    positionGroupBy: false,
    amPmGroupBy: false,
    staffCategoryGroupBy: false,
    sortBy: [],
    groupBy: {},
    type: "",
    filterBy: []
  };

  type = "";
  noOfTotals = 0;
  noOfStatistics = 0;
  noOfPeriods = 0;

  schedulerFilter: SettingsSchedulerModel = {
    noOfWeeks: 4,
    showIcons: true,
    showDepartments: true,
    mode: 0,
    startTime: 7,
    hourBlock: 6,
    positionPeriods: [],
    viewStatistics: false,
    viewStatisticsTop: false,
    viewPeriods: false,
    viewPeriodsTop: false,
    viewTotals: false,
    viewTotalsTop: false,
    hideColumns: [],
    display: 0,
    selectedShifts: [],
    selectedPositions: [],
    selectedTitles: [],
    selectedCategories: [],
    selectedFtes: []
  };

  selectedDate = new Date();

  @Mutation
  setFilter(model: FilterModel): void {
    this.filter = { ...model };
  }

  @Mutation
  setType(type: string): void {
    this.filter.type = type;
    this.type = type;
  }

  @Mutation
  setDate(date: Date): void {
    this.selectedDate = date;
  }

  @Mutation
  setNoOfTotals(rows: number): void {
    this.noOfTotals = rows;
  }

  @Mutation
  setNoOfStatistics(rows: number): void {
    this.noOfStatistics = rows;
  }

  @Mutation
  setNoOfPeriods(rows: number): void {
    this.noOfPeriods = rows;
  }

  @Mutation
  setFilterView(model: FilterModel): void {
    this.filter.view = model.view;
  }

  @Mutation
  setSchedulerFilter(model: SettingsSchedulerModel): void {
    this.schedulerFilter = { ...model };
  }

  @Action({ rawError: true })
  changeType(type: string) {
    this.context.commit("setType", type);
  }

  @Action({ rawError: true })
  changeNoOfTotals(rows: number) {
    this.context.commit("setNoOfTotals", rows);
  }

  @Action({ rawError: true })
  changeNoOfStatistics(rows: number) {
    this.context.commit("setNoOfStatistics", rows);
  }

  @Action({ rawError: true })
  changeNoOfPeriods(rows: number) {
    this.context.commit("setNoOfPeriods", rows);
  }

  @Action({ rawError: true })
  changeFilter(model: FilterModel) {
    this.context.commit("setFilter", model);
  }

  @Action({ rawError: true })
  changeFilterView(model: FilterModel) {
    this.context.commit("setFilterView", model);
  }

  @Action({ rawError: true })
  changeSchedulerFilter(model: SettingsSchedulerModel) {
    this.context.commit("setSchedulerFilter", model);
  }

  @Action({ rawError: true })
  changeDate(date: Date) {
    this.context.commit("setDate", date);
  }

  get currentFilter() {
    return this.filter;
  }

  get currentDate() {
    return this.selectedDate;
  }

  get currentSchedulerFilter() {
    return this.schedulerFilter;
  }

  get currentType() {
    return this.type;
  }

  get currentNoOfTotals() {
    return this.noOfTotals;
  }

  get currentNoOfStatistics() {
    return this.noOfStatistics;
  }

  get currentNoOfPeriods() {
    return this.noOfPeriods;
  }
}
