





























import ShiftTypeButtonTemplateComponent from "@/common/template/shift-type-button/component.vue";
import LocationTemplateComponent from "@/common/template/location-template/component.vue";
import IconInfoTemplateComponent from "@/common/template/icon-info-template/component.vue";
import IconTypeTemplateComponent from "@/common/template/icon-type-template/component.vue";
import ActionTemplateComponent from "@/common/template/action-template/component.vue";
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import { CardMixin } from "@/mixins";
import { namespace } from "vuex-class";
import { SideMenuItem, SideBarType } from "@/models";
const SideBar = namespace("SideBar");
@Component({
  components: {
    LocationTemplateComponent,
    ShiftTypeButtonTemplateComponent,
    IconInfoTemplateComponent,
    IconTypeTemplateComponent,
    ActionTemplateComponent
  }
})
export default class ActionCardTemplate extends Mixins(CardMixin) {
  @Prop() data!: any; // fix
  @Prop() type!: string;
  @Prop() index!: number;
  @Prop() isApprove!: boolean;
  @SideBar.Action
  private toggleSideMenu!: (type: SideMenuItem) => void;
  @SideBar.Getter
  private currentActiveMenuItem!: any;

  private onClick() {
    this.currentActiveMenuItem.selected = this.index;
    this.toggleSideMenu({
      type: this.type == 'shift' ? 
        SideBarType.EditEmployeeShift : 
        SideBarType.EditEmployeeIcon,
      item: this.currentActiveMenuItem
    });
  }
  private onDeleteClick(event: any) {
    this.currentActiveMenuItem.selected = this.index;
    this.toggleSideMenu({
      type: this.type == 'shift' ? 
        SideBarType.DeleteEmployeeShift : 
        SideBarType.DeleteEmployeeIcon,
      item: this.currentActiveMenuItem
    });
    event.stopImmediatePropagation();
  }
  private onManageClick(event: any) {
    this.currentActiveMenuItem.selected = this.index;
    const action = this.currentActiveMenuItem.actions[this.index]
    this.currentActiveMenuItem.shiftId = action.shiftId;
    this.toggleSideMenu({
      type: SideBarType.EditShift,
      item: this.currentActiveMenuItem
    });
    event.stopImmediatePropagation();
  }
  private onApprovalClick(event: any) {
    this.currentActiveMenuItem.selected = this.index;
    this.toggleSideMenu({
      type: SideBarType.ApprovalEmployeeIcon,
      item: this.currentActiveMenuItem
    });
    event.stopImmediatePropagation();
  }
}
