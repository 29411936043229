









import ProfileLogoTemplateComponent from "@/common/template/profile-logo/component.vue";
import ShiftTypeButtonTemplateComponent from "@/common/template/shift-type-button/component.vue";
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import { CardMixin } from "@/mixins";
@Component({
  components: {
    ProfileLogoTemplateComponent,
    ShiftTypeButtonTemplateComponent
  }
})
export default class ShiftEmployeeCardTemplate extends Mixins(CardMixin) {}
