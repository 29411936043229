






















import { Component, Vue } from "vue-property-decorator";

@Component
export default class EmployeeProfileNavComponent extends Vue {
  name = "EmployeeProfileNavComponent";
  path = `/employee-profile/${this.$route.params.id}`;
}
