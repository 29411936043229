














import { Component, Mixins } from "vue-property-decorator";
import { SideBarType, SideMenuItem } from "@/models";
import { namespace } from "vuex-class";
const SideBar = namespace("SideBar");
import { BaseCardHeaderMixin } from "@/mixins";

@Component
export default class ShiftTypeHeader extends Mixins(BaseCardHeaderMixin) {
  @SideBar.Action
  private toggleSideMenu!: (type: SideMenuItem) => void;

  cardClick(event: MouseEvent) {
    console.log("card click", event);

    this.toggleSideMenu({
      type: SideBarType.AddEmployeeShift,
      item: this.data
    });
  }
}
