














import { Component, Vue, Emit } from "vue-property-decorator";
import { namespace } from "vuex-class";
const SideBar = namespace("SideBar");
import { SideBarType, SideMenuItem } from "@/models";
@Component
export default class HeaderTemplate extends Vue {
  private data = {};
  private location = "SW West";
  private department = "Radiology";
  @SideBar.Action
  private toggleSideMenu!: (type: SideMenuItem) => void;

  toggleAddDepartmentMenu() {
    this.toggleSideMenu({ type: SideBarType.AddDepartment, item: {} });
  }
}
