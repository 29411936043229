

























































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  Sort,
  Filter,
  Selection,
  VirtualScroll,
  Page
} from "@syncfusion/ej2-vue-grids";
import { UspActivityLogByEmployee } from "@/models/employee-profile/employee.response.model";
import ProfileService from "@/services/ProfileService";
import { namespace } from "vuex-class/lib/bindings";
import moment from "moment";

const Profile = namespace("Profile");

@Component({
  provide: {
    grid: [Sort, Filter, Selection, VirtualScroll, Page]
  }
})
export default class DepartmentAssignmentsComponent extends Vue {
  customAttributes = { class: "customcss" };
  pageSettings: object = {
    pageSizes: [12, 50, 100, 200],
    pageCount: 4
  };
  filterForm = {
    startDate: new Date(),
    endDate: new Date()
  };
  @Profile.Getter
  loadedState!: boolean;

  @Profile.Getter
  currentUserId!: number;

  allActivities: Partial<UspActivityLogByEmployee>[] = [
    {
      activityLogId: 0,
      doneByUserID: null,
      doneToUserID: null,
      activity: null,
      doneByUserFirstName: "",
      doneByUserLastName: "",
      doneToShiftID: null,
      shiftDate: "",
      activityDate: null,
      userScheduleId: null,
      shiftType: "",
      memberPositionId: null,
      departmentID: null
    }
  ];
  tableActivities: Partial<UspActivityLogByEmployee>[] = [
    {
      activityLogId: 0,
      doneByUserID: null,
      doneToUserID: null,
      activity: null,
      doneByUserFirstName: "",
      doneByUserLastName: "",
      doneToShiftID: null,
      shiftDate: "",
      activityDate: null,
      userScheduleId: null,
      shiftType: "",
      memberPositionId: null,
      departmentID: null
    }
  ];

  wrapSettings = { wrapMode: "Content" };
  created() {
    this.fetchLog();
  }
  @Watch("loadedState")
  async fetchLog() {
    if (!this.loadedState) {
      this.allActivities = (
        await ProfileService.getActivityLogByEmployee(this.currentUserId)
      ).data;
      this.tableActivities = [...this.allActivities];
    }
  }
  valueAccess(field: string, data: UspActivityLogByEmployee) {
    return `${data[field as keyof UspActivityLogByEmployee]} ${
      data.doneByUserLastName
    }`;
  }

  updateFilter(e: Event) {
    e.preventDefault();
    const filterStartDate = moment(this.filterForm.startDate);
    const filterEndDate = moment(this.filterForm.endDate);
    if (filterStartDate.isSameOrBefore(filterEndDate)) {
      this.tableActivities = this.allActivities.filter(item => {
        if (item.activityDate) {
          const shiftDate = moment(item.activityDate);
          if (
            shiftDate.isSameOrAfter(filterStartDate) &&
            shiftDate.isSameOrBefore(filterEndDate)
          ) {
            return item;
          }
        }
      });
    } else {
      alert("Invalid date range");
    }
  }
}
