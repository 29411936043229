












import { Component, Mixins } from "vue-property-decorator";
import { CardMixin } from "@/mixins";
import { namespace } from "vuex-class";
const SideBar = namespace("SideBar");
import { SideMenuItem, SideBarType } from "@/models";
@Component({
  components: {}
})
export default class ShiftCardStaffTemplateComponent extends Mixins(CardMixin) {
  @SideBar.Action
  private toggleSideMenu!: (type: SideMenuItem) => void;

  editItem() {
    this.toggleSideMenu({
      type: SideBarType.EditShift,
      item: this.data
    });
  }
}
