


















import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
import { UserModel } from "@/models";

@Component
export default class NotAuthtScreenComponent extends Vue {
  version!: string | undefined;
  @Auth.Getter
  private isLoggedIn!: boolean;
  @Auth.Getter
  private currentUser!: UserModel;

  created() {
    this.version = process.env.VUE_APP_VERSION;
    this.redirectToDefaultUrl();
  }

  private redirectToDefaultUrl(): void {
    if (
      this.isLoggedIn &&
      this.currentUser !== undefined &&
      this.currentUser !== null
    ) {
      if (this.currentUser.default_screen) {
        this.$router.push(this.currentUser.default_screen);
      } else {
        this.$router.push("/day");
      }
    }
  }
}
