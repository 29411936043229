import { AccessLevel } from "@/models/departments/departments.employee.enums";
import { MemberAccessType } from "@/models/employee-profile/employee.response.model";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const DepartmentModule = namespace("DepartmentModule");

@Component
export default class PermissionMixin extends Vue {
  @DepartmentModule.Getter
  userLoginTypeIdForDepartment!: AccessLevel;

  /**
   * Don't show UI for StaffLogin (LoginTypeID == 20)
   */
  get permissionNotForStaff() {
    return this.userLoginTypeIdForDepartment !== AccessLevel.StaffLogin;
  }
  /**
   * Show UI only for SiteAdmin (LoginTypeID == 18)
   */
  get permissionOnlyForAdmin() {
    return this.userLoginTypeIdForDepartment === AccessLevel.SiteAdmin;
  }

  /**
   * Show action UI if logged user is Manager and have Manager access for department in table
   */
  actionPermissionForDepartmentManager(memberAccessType: MemberAccessType) {
    if (this.userLoginTypeIdForDepartment === AccessLevel.UnitManager) {
      return memberAccessType === MemberAccessType.Manager;
    } else {
      return true;
    }
  }
}
