import httpAuth from "../common/http/http-common";

class UserService {
  getAll() {
    return httpAuth().get("/students");
  }

  get(id: number) {
    return httpAuth().get(`/students/${id}`);
  }

  create(data: string) {
    return httpAuth().post("/students", data);
  }

  update(id: string, data: string) {
    return httpAuth().put(`/students/${id}`, data);
  }

  delete(id: string) {
    return httpAuth().delete(`/students/${id}`);
  }

  deleteAll() {
    return httpAuth().delete(`/students`);
  }

  findByTitle(title: string) {
    return httpAuth().get(`/students?title=${title}`);
  }
}

export default new UserService();
