import { DropdownSelect } from "../common/dropdown.model";

export interface ActivityLog {
  date: string;
  activity: string;
  title: string;
  doneBy: string;
  shiftID: string;
  dateOfShift: string;
  positionID: string;
  forShift: string;
  doneByUserID: string;
}

export interface DepartmentAssignments {
  location: string;
  dept: string;
  title: string;
  skills: string;
  access: string;
  team: string;
  group: string;
}

export interface UspEmployeeDetailByUserId {
  userID: number;
  user_Fname: string | null;
  user_Lname: string;
  user_Name: string;
  user_Status: number | null;
  user_Staff_Type: string;
  user_Address1: string;
  user_Address2: string;
  user_Address3: string;
  user_City: string;
  user_State: string;
  user_Zip: string;
  user_Cell: string;
  user_Phone: string;
  user_Fax: string;
  user_Email: string;
  user_Password: string;
  user_Pager: string;
  user_DOB: string | null;
  user_SSN: string;
  user_Gender: string;
  user_DateMod: string | null;
  user_RecStat: number | null;
  companyID: number | null;
  sessionID: number | null;
  memberPositionID: number | null;
  userLocationID: number;
  userDepartmentID: number | null;
  user_Group_Assignment: number | null;
  memberAccessID: number | null;
  badgeID: string;
  ptoBalanceDate: string | null;
  ptoBalanceMinutes: number | null;
  user_Notes: string;
  user_Email2: string;
  educationLevelId: number | string;
  hideAccount: number | null;
  chargeCode: string;
  masterUserId: string | null;
  lastReleaseNoteDate: string | null;
  maxWeeklyHours: number | null;
  fteTypeId: number | null;
  maxDailyHours: number | null;
  breakLenInHours: number | null;
  maxHrsInPeriod: number | null;
  periodLenInDays: number | null;
  agencyID: number | null;
  staffTypeId: number | null;
  passcrypto: string;
  seniority: number | null;
  profilePictureFilename: string;
  modifiedTimestamp: string | null;
  createdTimestamp: string | null;
  user_MName: string;
  user_HireDate: string | null;
  loginDisabled: boolean;
  lastLoginTimestamp: string | null;
  lastLoginAttempt: string | null;
  lastPasswordChange: string | null;
  loginFailCount: number | null;
  enableSMS: boolean | null;
}

export interface UspEmployeeCertsByUserId {
  userID: number;
  userProfile_CertName?: string;
  userProfile_CertId: number;
  userProfile_CertExp: string;
  companyCertID: number;
  userProfile_CertState: string;
  userProfile_CertNo: string;
  userProfile_RecStat?: number;
  userProfile_CertImage?: string;
  userProfile_Note?: string;
  renew_CertId?: number;
  imageHasErrorLoading?: boolean;
}

export interface UspLocationDepartmentsByCompanyId {
  companyDepartmentLocationId: number;
  companyDepartmentId: number;
  locationName: string;
  companyDepartmentName: string;
  departmentFullName: string;
  companyDepartmentSortOrder: number | null;
}

export interface UspActivityLogByEmployee {
  activityLogId: number;
  doneByUserID: number | null;
  doneByUserFirstName: string | null;
  doneByUserLastName: string | null;
  doneToUserID: number | null;
  doneToUserFirstName: string | null;
  doneToUserLastName: string | null;
  activity: string | null;
  doneToShiftID: number | null;
  shiftDate: string | null;
  shiftBeginTime: string | null;
  shiftEndTime: string | null;
  shiftStartDate: string | null;
  shiftEndDate: string | null;
  shiftTypeId: number | null;
  shiftType: string | null;
  activityDate: string | null;
  userScheduleId: number | null;
  memberPositionId: number | null;
  companyDepartmentLocationId: number | null;
  departmentID: number | null;
}

export interface UspDepartmentAssignmentsByEmployee {
  userId: number;
  locationId: number;
  locationName: string | null;
  companyDepartmentId: number;
  companyDepartmentName: string | null;
  memberPosition: string;
  memberPositionArray?: string[];
  userAccessDesc: string | null;
  memberPositionID: number;
  userAccessType: MemberAccessType | null;
  staffTitleId: number | null;
  staffTitle: string | null;
  shiftGroupId: number | null;
  companyDepartmentStaffID: number;
  unitdefault?: number;
  defaultTeamId: number | null;
  staffCategory?: string;
  staffCategoryId: number;
  teamName: string;
  serviceLine?: string;
  memberPositionIdArray?: number[];
  companyServiceLineName?: string;
}

export interface DepartmentSettingsDropdownsData {
  titleDropdownData: DropdownSelect[];
  categoryDropdownData: DropdownSelect[];
  teamDropdownData: DropdownSelect[];
  groupDropdownData: DropdownSelect[];
  accessDropdownData: DropdownSelect[];
  positionDropdownData: DropdownSelect[];
  shiftTypeDropdownData: DropdownSelect[];
  fteTypeDropdownData: DropdownSelect[];
}

export interface DepartmentAssignmentUpsert {
  userId: number;
  locationId: number;
  companyDepartmentId: number;
  memberPositionIDArray: number[];
  staffTitleId: number | null;
  unitdefault: number | null;
  staffTitle: string | null;
  shiftGroupId: number | null;
  staffCategoryId: number | null;
  defaultTeamId: number | null;
  userAccessType: number | null;
  companyDepartmentStaffID: number;
}

export interface DepartmentAssignmentGroup {
  departmentAssignmentGroupId: number;
  departmentAssignmentGroupName: string;
}

export enum MemberAccessType {
  Manager = 3,
  Charge = 1
}
