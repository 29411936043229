import httpAuth from "../common/http/http-common";
import moment from "moment";
import {
  ParameterSchedulerModel,
  ParameterShiftTotalsModel,
  ParameterShiftStatisticsModel,
  ParameterShiftPeriodsModel
} from "@/models";

class SchedulerService {
  getSchedulerInfo(model: ParameterSchedulerModel) {
    return httpAuth().get(`/scheduler/SchedulerInfo`, {
      params: {
        departmentId: model.departmentId,
        startDate: moment(model.startDate).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: moment(model.endDate).format("YYYY-MM-DDTHH:mm:ss"),
        mode: model.mode
      }
    });
  }
  getShiftTotalsInfo(model: ParameterShiftTotalsModel) {
    return httpAuth().get(`/scheduler/ShiftTotalsInfo`, {
      params: {
        departmentId: model.departmentId,
        startDate: moment(model.startDate).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: moment(model.endDate).format("YYYY-MM-DDTHH:mm:ss")
      }
    });
  }
  getShiftStatisticsInfo(model: ParameterShiftStatisticsModel) {
    return httpAuth().get(`/scheduler/ShiftStatisticsInfo`, {
      params: {
        departmentId: model.departmentId,
        startDate: moment(model.startDate).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: moment(model.endDate).format("YYYY-MM-DDTHH:mm:ss")
      }
    });
  }
  getShiftPeriodsInfo(model: ParameterShiftPeriodsModel) {
    return httpAuth().get(`/scheduler/ShiftPeriodsInfo`, {
      params: {
        positionArray: model.positionArray,
        startDate: moment(model.startDate).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: moment(model.endDate).format("YYYY-MM-DDTHH:mm:ss")
      }
    });
  }
}

export default new SchedulerService();
