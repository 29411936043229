import { VuexModule, Module, Action } from "vuex-module-decorators";
import { HubConnectionBuilder, HubConnectionState } from "@aspnet/signalr";
const API_URL = process.env.VUE_APP_API_URL;
const connection = new HubConnectionBuilder()
  .withUrl(API_URL + "/notify-hub")
  .build();
@Module({ namespaced: true })
class TaskHub extends VuexModule {
  startedPromise: Promise<unknown> = new Promise<unknown>(resolve => {
    resolve(undefined);
  });
  @Action({ rawError: true })
  StartHub() {
    if (connection.state === HubConnectionState.Connected) {
      console.log("Hub is connected not need for start new one ");
    } else {
      console.log("StartHub");
      connection.start().catch(err => console.error(err));
    }
  }

  @Action({ rawError: true })
  StopHub() {
    if (connection && connection.state === HubConnectionState.Connected) {
      connection.stop();
    }
  }
  @Action({ rawError: true })
  AddedTask(callback: (task: object) => void) {
    connection.on("AddedTask", callback);
  }

  @Action({ rawError: true })
  TaskIsDone(callback: (task: object) => void) {
    connection.on("TaskIsDone", callback);
  }

  @Action({ rawError: true })
  AddTask(curretnTaskId: number) {
    console.log("AddTask", curretnTaskId);
    connection.invoke("AddTask", {
      id: curretnTaskId,
      desc: `Starting laundry task ${curretnTaskId}`,
      done: false
    });
  }

  private start() {
    this.startedPromise = connection.start().catch(err => {
      console.error("Failed to connect with hub", err);
      return new Promise((resolve, reject) =>
        setTimeout(
          () =>
            this.start()
              .then(resolve)
              .catch(reject),
          5000
        )
      );
    });
    return this.startedPromise;
  }
}

export default TaskHub;
