






































import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import { BaseMixin } from "@/mixins";
import Frag from "vue-frag";
import moment from "moment";
import {
  SettingsSchedulerModel,
  ParameterShiftStatisticsModel,
  ShiftStatisticsItemModelByUserId
} from "@/models";
import {
  createSpinner,
  showSpinner,
  hideSpinner
} from "@syncfusion/ej2-popups";
import { namespace } from "vuex-class";
const CalendarModule = namespace("CalendarModule");
const DepartmentModule = namespace("DepartmentModule");
const CalendarFilterModule = namespace("CalendarFilterModule");
@Component({
  directives: {
    Frag
  }
})
export default class SchedulerStatisticsComponent extends Mixins(BaseMixin) {
  @CalendarModule.Action
  private loadingShiftStatisticsInfoByDateRange!: (
    model: ParameterShiftStatisticsModel
  ) => Promise<ShiftStatisticsItemModelByUserId[]>;

  @DepartmentModule.Getter
  currentDepartmentId!: number;
  @CalendarFilterModule.Getter
  currentSchedulerFilter!: SettingsSchedulerModel;
  @CalendarFilterModule.Action
  private changeNoOfStatistics!: (rows: number) => void;

  private shiftStatistics = [];
  private selectedPositions: Array<string> = [];
  private selectedShifts: Array<string> = [];
  private isLoading = false;
  private threshold = 0.5;

  @Prop() noOfColumns!: number;
  @Prop() noOfWeeks!: number;
  @Prop() onTop!: boolean;
  @Prop() Columns!: Array<number | string>;
  @Prop() getBodyCSS!: (
    index: number,
    name: string,
    rowindex: number,
    length: number
  ) => Array<string>;
  @Prop() isDate!: (value: string | number) => boolean;
  @Prop() selectedDate!: Date;
  @Prop() freezeTable!: () => void;
  @Prop() onReload!: boolean;
  @Prop() isHidden!: () => boolean;

  @Watch("onReload")
  changeOnReload() {
    if (this.isLoading === false && this.onReload === true) {
      this.reload();
    }
  }
  @Watch("currentSchedulerFilter")
  changeFilterModel() {
    this.applyFilters();
  }

  mounted() {
    const { createSpinner, showSpinner, hideSpinner } = this.$globalLoader;
    createSpinner([
      {
        target: document.getElementById("statistics") as HTMLElement
      }
    ]);
    this.reload();
    this.applyFilters();
  }

  get shiftStatisticsArray() {
    let start = moment()
      .startOf("day")
      .add(this.currentSchedulerFilter.startTime, "hours");
    const shiftStatistics = [] as any;
    const that = this as any;
    for (let i = 0; i < 24 / this.currentSchedulerFilter.hourBlock; i++) {
      const end = moment(start).add(
        this.currentSchedulerFilter.hourBlock,
        "hours"
      );
      shiftStatistics.push({
        label: `Employees working ${start.format("hA")} - ${end.format("hA")}`,
        start: start.format("HH:00"),
        end: end.format("HH:00"),
        schedules: {}
      });
      start = moment(end);
    }
    this.shiftStatistics
      .filter(post => this.filter(post))
      .forEach(function(shiftStatistic: any) {
        for (const key in shiftStatistic.schedules) {
          shiftStatistics.forEach(function(statistic: any) {
            const empStartDate = that.afterMidnight(shiftStatistic.start)
              ? moment(key)
                  .add(1, "days")
                  .format("YYYY-MM-DD")
              : key;
            const startDate =
              that.afterMidnight(statistic.start) &&
              that.afterMidnight(statistic.end)
                ? moment(key)
                    .add(1, "days")
                    .format("YYYY-MM-DD")
                : key;
            const hired = shiftStatistic["schedules"][key].hired;
            if (
              key in statistic["schedules"] &&
              "hired" in statistic["schedules"][key]
            ) {
              statistic["schedules"][key]["hired"] +=
                hired *
                that.percentEmpUsedEx(
                  startDate,
                  statistic.start,
                  statistic.end,
                  empStartDate,
                  shiftStatistic.start,
                  shiftStatistic.end,
                  that.threshold
                );
            } else {
              statistic["schedules"][key] = {
                hired:
                  hired *
                  that.percentEmpUsedEx(
                    startDate,
                    statistic.start,
                    statistic.end,
                    empStartDate,
                    shiftStatistic.start,
                    shiftStatistic.end,
                    that.threshold
                  )
              };
            }
          });
        }
      });
    return shiftStatistics;
  }

  private reload() {
    console.log("dep reload", this.currentDepartmentId);
    this.shiftStatistics = []; // fix
    showSpinner(document.getElementById("statistics") as HTMLElement);
    const that = this as any;
    if (
      this.currentDepartmentId !== undefined &&
      this.currentDepartmentId !== 0
    ) {
      this.isLoading = true;
      const data: ParameterShiftStatisticsModel = {
        startDate: moment(this.selectedDate)
          .startOf("day")
          .toDate(),
        endDate: moment(this.selectedDate)
          .add(this.noOfWeeks, "w")
          .endOf("day")
          .toDate(),
        departmentId: this.currentDepartmentId
      };
      this.loadingShiftStatisticsInfoByDateRange(data).then(
        (d: ShiftStatisticsItemModelByUserId[]) => {
          d.forEach(function(arrayItem) {
            try {
              that.shiftStatistics.push(
                JSON.parse(arrayItem.jsonData as string)
              );
            } catch (error) {
              console.error("Error parsing " + arrayItem.jsonData);
            }
          });
          this.isLoading = false;
          this.changeNoOfStatistics((24 / this.currentSchedulerFilter.hourBlock) + 1);
          hideSpinner(document.getElementById("statistics") as HTMLElement);
          this.freezeTable();
        }
      );
    }
  }
  private filter(shiftStatistic: any): boolean {
    const shifts = this.selectedShifts;
    const positions = this.selectedPositions;
    if (shifts.length === 0 && positions.length === 0) {
      return true;
    }
    if (shifts.includes(shiftStatistic.shift)) {
      return true;
    }
    if (positions.includes(shiftStatistic.position)) {
      return true;
    }
    return false;
  }
  private applyFilters() {
    this.noOfWeeks = this.currentSchedulerFilter.noOfWeeks;
    this.selectedShifts = this.currentSchedulerFilter.selectedShifts;
    this.selectedPositions = this.currentSchedulerFilter.selectedPositions;
  }
}
