import { ToastOptions } from "@/models";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true })
export default class ToastModule extends VuexModule {
  currentToast = {};

  @Mutation
  updatedCurrentToast(newToast: ToastOptions) {
    this.currentToast = newToast;
  }

  @Action({ rawError: true })
  showToast(toastOptions: ToastOptions) {
    this.context.commit("updatedCurrentToast", toastOptions);
  }

  get currentToastOptions() {
    return this.currentToast;
  }
}
