












































































































import { Component, Vue, Watch } from "vue-property-decorator";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";
import { namespace } from "vuex-class/lib/bindings";
import {
  DepartmentAssignmentUpsert,
  DepartmentSettingsDropdownsData,
  UspDepartmentAssignmentsByEmployee
} from "@/models/employee-profile/employee.response.model";
import ProfileService from "@/services/ProfileService";
const Profile = namespace("Profile");
const SideBar = namespace("SideBar");
@Component({
  components: {
    "side-menu-layout": SideMenuLayout
  }
})
export default class EditDepartmentSettingsComponent extends Vue {
  @Profile.Getter
  currentUserId!: number;

  @SideBar.Getter
  currentActiveMenuItem!: UspDepartmentAssignmentsByEmployee;
  dropdownFields = {
    text: "text",
    value: "value"
  };
  @Profile.Action
  updateDepAssignment!: (depAssignment: DepartmentAssignmentUpsert) => void;
  loadedState = false;
  departmentSettingsDropdownData: DepartmentSettingsDropdownsData = {
    groupDropdownData: [
      {
        value: 0,
        text: ""
      }
    ],
    titleDropdownData: [
      {
        value: 0,
        text: ""
      }
    ],
    teamDropdownData: [
      {
        value: 0,
        text: ""
      }
    ],
    accessDropdownData: [
      {
        value: 0,
        text: ""
      }
    ],
    categoryDropdownData: [
      {
        value: 0,
        text: ""
      }
    ],
    positionDropdownData: [
      {
        value: 0,
        text: ""
      }
    ],
    shiftTypeDropdownData: [
      {
        value: 0,
        text: ""
      }
    ],
    fteTypeDropdownData: [
      {
        value: 0,
        text: ""
      }
    ]
  };
  multiselectModel: string[] = ["RN"];
  departmentSettingsForm!: UspDepartmentAssignmentsByEmployee;
  async onSubmit() {
    this.departmentSettingsForm.unitdefault = Number(
      this.departmentSettingsForm.unitdefault
    );
    const {
      unitdefault,
      userId,
      locationId,
      companyDepartmentId,
      companyDepartmentStaffID,
      defaultTeamId,
      staffCategoryId,
      userAccessType,
      shiftGroupId,
      staffTitle,
      staffTitleId
    } = this.departmentSettingsForm;
    const postModel: DepartmentAssignmentUpsert = {
      memberPositionIDArray: this.getMemberPositionID(),
      unitdefault,
      userId,
      locationId,
      companyDepartmentId,
      companyDepartmentStaffID,
      defaultTeamId,
      staffCategoryId,
      userAccessType,
      shiftGroupId,
      staffTitle,
      staffTitleId
    };
    this.updateDepAssignment(postModel);
  }

  getMemberPositionID() {
    return this.departmentSettingsDropdownData.positionDropdownData.reduce(
      (acc: number[], position) => {
        if (this.multiselectModel.includes(position.text)) {
          acc.push(+position.value);
        }
        return acc;
      },
      []
    );
  }
  created() {
    this.departmentSettingsForm = { ...this.currentActiveMenuItem };
  }
  @Watch("currentActiveMenuItem", { immediate: true, deep: true })
  async updateDropdowns() {
    this.loadedState = true;
    this.multiselectModel = [];
    this.departmentSettingsDropdownData = (
      await ProfileService.getDropdownsDataForDepartmentSettings(
        this.currentActiveMenuItem.companyDepartmentId
      )
    ).data;
    this.departmentSettingsForm = { ...this.currentActiveMenuItem };
    if (this.currentActiveMenuItem.memberPositionArray) {
      this.multiselectModel = [
        ...this.currentActiveMenuItem.memberPositionArray
      ];
    }
    this.loadedState = false;
  }
}
