import { RouteConfig } from "vue-router";
import { UserListComponent, UserEditComponent } from "@/components/user";

const path = "/users";

export const USER_ROUTES: RouteConfig[] = [
  {
    path,
    name: "UserListComponent",
    component: UserListComponent
  },
  {
    path: path + "/list",
    name: "UserListComponent",
    component: UserListComponent
  },
  {
    path: path + "/edit/:id",
    name: "UserEditComponent",
    component: UserEditComponent
  },
  {
    path: path + "/edit",
    name: "UserEditComponent",
    component: UserEditComponent
  }
];
