

































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { BaseSideBarMixin } from "@/mixins";
import { namespace } from "vuex-class";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";
const SideBar = namespace("SideBar");
const ShiftModule = namespace("ShiftModule");
const CalendarModule = namespace("CalendarModule");
const DepartmentModule = namespace("DepartmentModule");
const Toast = namespace("ToastModule");
import { StaffActionCardTemplate, CalendarTemplateComponent } from "@/common";
import {
  ChangeEventArgs as ChangeEventArgsDD,
  DropDownList
} from "@syncfusion/ej2-vue-dropdowns";
import {
  DepartmentEmployeeModel,
  MemeberPostion,
  SchedulerActionModel,
  ShiftTypeRequest,
  BaseResponse,
  ShiftType,
  SideBarType,
  SideMenuItemOptions,
  ParameterCalendarModel,
  CalendarComponentModel,
  AddUserToSchedulerModel,
  ShiftEmployeeDropDownRequest,
  ToastOptions
} from "@/models";
import {
  ValidationObserver,
  ValidationProvider,
  ValidationObserverInstance
} from "vee-validate";
import {
  createSpinner,
  showSpinner,
  hideSpinner
} from "@syncfusion/ej2-popups";
import moment from "moment";
@Component({
  components: {
    "side-menu-layout": SideMenuLayout,
    ValidationObserver,
    ValidationProvider,
    StaffActionCardTemplate,
    CalendarTemplateComponent
  }
})
export default class AddEmployeeToShiftComponent extends Mixins(
  BaseSideBarMixin
) {
  @Ref("valObs") readonly valObs!: ValidationObserverInstance;
  private loadingShiftType = false;
  private loadingPosition = false;
  private shiftTypeId: number | null = null;
  private positionId: number | null = null;
  private name = "";
  private localShiftFields = { 
    text: "shiftType", 
    value: "shiftTypeId" 
  };
  private localPositionFields = { 
    text: "text", 
    value: "value" 
  };
  shiftTypes: ShiftType[] = [];
  positions: MemeberPostion[] = [];
  specialDates: Record<string, any>[] = []; // fix

  @Toast.Action
  private showToast!: (toastOptions: ToastOptions) => void;

  @SideBar.Getter
  private currentActiveMenuItem!: SchedulerActionModel;

  @DepartmentModule.Getter
  private currentDepartment!: DepartmentEmployeeModel;
  @ShiftModule.Action
  private getShiftType!: (
    model: ShiftTypeRequest
  ) => Promise<ShiftType[]>;
  @ShiftModule.Action
  private getPositionsByEmployee!: (
    model: ShiftEmployeeDropDownRequest
  ) => Promise<MemeberPostion[]>;

  @CalendarModule.Getter
  private currentDates!: Date[];
  @CalendarModule.Action
  private changeDates!: (dates: Date[]) => void;
  @CalendarModule.Action
  private loadingEmployeePositionShiftsInfoByDateRange!: (
    model: ParameterCalendarModel
  ) => Promise<CalendarComponentModel[]>;
  @CalendarModule.Getter
  private isShowError!: boolean;
  @CalendarModule.Action
  private cleanError!: () => Promise<void>;
  @CalendarModule.Action
  private addUserToScheduler!: (
    model: AddUserToSchedulerModel
  ) => Promise<BaseResponse>;

  private model: SchedulerActionModel = { ...this.currentActiveMenuItem };

  @Watch("currentActiveMenuItem", { immediate: true, deep: true })
  async changeCurrentActiveMenuItem() {
    console.log("currentActiveMenuItem change", this.currentActiveMenuItem);
    this.name =
      this.currentActiveMenuItem.user_FName +
      " " +
      this.currentActiveMenuItem.user_LName;

    this.model = { ...this.currentActiveMenuItem };
  }

  @Watch("currentDepartment", { immediate: true, deep: true })
  async changeCurrentDepartment() {
    this.getRefreshShiftTypes();
    this.getRefreshPositions();
  }

  async mounted() {
    if (this.valObs) {
      this.valObs.reset();
    }
    const { createSpinner, showSpinner, hideSpinner } = this.$globalLoader;
    createSpinner([
      {
        target: document.getElementById("side-menu") as HTMLElement
      }
    ]);
  }
  async onSubmit() {
    console.log("on submit");
    const isValid = await this.valObs.validate();
    if (!isValid) {
      return;
    }
    if (
      this.shiftTypeId !== null &&
      this.positionId !== null &&
      this.model.userId !== undefined
    ) {
      showSpinner(document.getElementById("side-menu") as HTMLElement);
      const model: AddUserToSchedulerModel = {
        userId: this.model.userId,
        shiftTypeId: this.shiftTypeId,
        positionId: this.positionId,
        dateArray: this.currentDates
          .map(d => moment(d).format("YYYY-MM-DD"))
          .toString()
      };
      this.addUserToScheduler(model).then(() => {
        hideSpinner(document.getElementById("side-menu") as HTMLElement);
        console.log("shiftEmployeeUpset response");
        if (this.isShowError) {
          this.cleanError();
          this.showToast({
            title: "Error",
            content: "Error occurred",
            cssClass: "e-toast-danger"
          });
        } else {
          this.showToast({
            title: "Success",
            content: "Shift Employee save success",
            cssClass: "e-toast-success"
          });
          this.closeMenu({
            success: true,
            reload: true
          });
        }
      });
    }
  }
  private getRefreshPositions() {
    this.positions = [];
    this.loadingPosition = true;
    if (this.currentDepartment && 
        this.currentActiveMenuItem &&
        this.currentDepartment.companyDepartmentId &&
        this.currentActiveMenuItem.userId) {
      const data: ShiftEmployeeDropDownRequest = {
        userId: this.currentActiveMenuItem.userId,
        departmentId: this.currentDepartment.companyDepartmentId
      };
      this.getPositionsByEmployee(data)
        .then(positions => {
          this.positions = positions;
        })
        .finally(() => {
          this.loadingPosition = false;
        });
    }
  }
  private getRefreshShiftTypes() {
    this.shiftTypes = [];
    this.loadingShiftType = true;
    this.getShiftType({
      departmentId: this.currentDepartment.companyDepartmentId
    })
      .then(st => {
        this.shiftTypes = st;
      })
      .finally(() => {
        this.loadingShiftType = false;
      });
  }
  private back() {
    this.toggleSideBarMenu({
      type: SideBarType.EmployeeDay,
      item: this.currentActiveMenuItem
    });
  }
  private onChange(args: any) {
    this.reload();
  }
  private reload() {
    const that = this as any;
    if (
      this.shiftTypeId !== null &&
      this.positionId !== null &&
      this.model.userId !== undefined &&
      this.model.startDate !== undefined &&
      this.model.endDate !== undefined
    ) {
      this.specialDates = [];
      showSpinner(document.getElementById("side-menu") as HTMLElement);
      const data: ParameterCalendarModel = {
        userId: this.model.userId,
        shiftTypeId: this.shiftTypeId,
        positionId: this.positionId,
        startDate: this.model.startDate,
        endDate: this.model.endDate,
        includeUnderStaffed: true
      };
      this.loadingEmployeePositionShiftsInfoByDateRange(data).then(
        (d: CalendarComponentModel[]) => {
          d.forEach(function(arrayItem) {
            if (arrayItem !== null) {
              const json = JSON.parse(arrayItem.jsonData as string);
              that.specialDates = [...json];
            }
          });
          hideSpinner(document.getElementById("side-menu") as HTMLElement);
        }
      );
    }
  }
}
