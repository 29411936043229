














































import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import { BaseMixin } from "@/mixins";
import Frag from "vue-frag";
import moment from "moment";
import {
  ParameterShiftPeriodsModel,
  ShiftPeriodsItemModelByUserId,
  SettingsSchedulerModel
} from "@/models";
import {
  createSpinner,
  showSpinner,
  hideSpinner
} from "@syncfusion/ej2-popups";
import { namespace } from "vuex-class";
const CalendarModule = namespace("CalendarModule");
const CalendarFilterModule = namespace("CalendarFilterModule");
@Component({
  directives: {
    Frag
  }
})
export default class SchedulerPeriodsComponent extends Mixins(BaseMixin) {
  @CalendarModule.Action
  private loadingShiftPeriodsInfoByDateRange!: (
    model: ParameterShiftPeriodsModel
  ) => Promise<ShiftPeriodsItemModelByUserId[]>;
  @CalendarFilterModule.Getter
  currentSchedulerFilter!: SettingsSchedulerModel;
  @CalendarFilterModule.Action
  private changeNoOfPeriods!: (rows: number) => void;

  private shiftPeriods = [];
  private isLoading = false;

  @Prop() noOfColumns!: number;
  @Prop() noOfWeeks!: number;
  @Prop() onTop!: boolean;
  @Prop() Columns!: Array<number | string>;
  @Prop() getBodyCSS!: (
    index: number,
    name: string,
    rowindex: number,
    length: number
  ) => Array<string>;
  @Prop() isDate!: (value: string | number) => boolean;
  @Prop() selectedDate!: Date;
  @Prop() freezeTable!: () => void;
  @Prop() onReload!: boolean;
  @Prop() isHidden!: () => boolean;

  @Watch("onReload")
  changeOnReload() {
    if (this.isLoading === false && this.onReload === true) {
      this.reload();
    }
  }

  mounted() {
    const { createSpinner, showSpinner, hideSpinner } = this.$globalLoader;
    createSpinner([
      {
        target: document.getElementById("periods") as HTMLElement
      }
    ]);
    this.reload();
  }

  get filterWatcher() {
    return this.currentSchedulerFilter?.positionPeriods;
  }
  get shiftPeriodsArray() {
    const shiftPeriods = [] as any;
    this.shiftPeriods.forEach(function(shiftPeriod: any) {
      const start = moment(
        moment().format("YYYY-MM-HH") + " " + shiftPeriod.start
      );
      const end = moment(moment().format("YYYY-MM-HH") + " " + shiftPeriod.end);
      const period = shiftPeriods.find(
        (period: any) => period.position === shiftPeriod.position
      ) as any;
      if (period !== undefined) {
        period["periods"].push({
          label: `Shift Period ${start.format("h:mm A")} - ${end.format(
            "h:mm A"
          )}`,
          schedules: { ...shiftPeriod.schedules }
        });
      } else {
        shiftPeriods.push({
          position: shiftPeriod.position,
          label: `Position ${shiftPeriod.position}`,
          periods: [
            {
              label: `Shift Period ${start.format("h:mm A")} - ${end.format(
                "h:mm A"
              )}`,
              schedules: { ...shiftPeriod.schedules }
            }
          ]
        });
      }
    });
    return shiftPeriods;
  }

  private reload() {
    console.log("dep reload", this.currentSchedulerFilter.positionPeriods);
    this.shiftPeriods = []; // fix
    const that = this as any;
    if (this.currentSchedulerFilter?.positionPeriods?.length > 0) {
      showSpinner(document.getElementById("periods") as HTMLElement);
      this.isLoading = true;
      const data: ParameterShiftPeriodsModel = {
        positionArray: this.currentSchedulerFilter.positionPeriods.toString(),
        startDate: moment(this.selectedDate)
          .startOf("day")
          .toDate(),
        endDate: moment(this.selectedDate)
          .add(this.noOfWeeks, "w")
          .endOf("day")
          .toDate()
      };
      this.loadingShiftPeriodsInfoByDateRange(data).then(
        (d: ShiftPeriodsItemModelByUserId[]) => {
          d.forEach(function(arrayItem) {
            try {
              that.shiftPeriods.push(JSON.parse(arrayItem.jsonData as string));
            } catch (error) {
              console.error("Error parsing " + arrayItem.jsonData);
            }
          });
          this.isLoading = false;
          this.changeNoOfPeriods([
            ...new Set(this.shiftPeriods.map((item:any) => item.shift))
          ].length + this.shiftPeriods.length);
          hideSpinner(document.getElementById("periods") as HTMLElement);
          this.freezeTable();
        }
      );
    }
  }
}
