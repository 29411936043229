import { Component, Watch, Mixins } from "vue-property-decorator";
import moment from "moment";
import {
  WeeklyItemModel,
  DateFilterModel,
  FilterModel,
  SortByFilterEnum,
  FilterByEnum,
  UspDepartmentStaffSearch
} from "@/models";
import { namespace } from "vuex-class";
const CalendarFilterModule = namespace("CalendarFilterModule");
import BaseMixin from "./baseMixin";
@Component
export default class BaseCalendarMixin extends Mixins(BaseMixin) {
  @CalendarFilterModule.Getter
  currentFilter!: FilterModel;

  kanbanData: WeeklyItemModel[] = [];
  originalData: WeeklyItemModel[] = [];
  searchStaffOriginalData: UspDepartmentStaffSearch[] = [];
  filteredSearchStaffData: UspDepartmentStaffSearch[] = [];
  type = "";

  currentSortBy: string[] = [];

  isFiltered = false;

  @Watch("currentFilter")
  changeFilterModel() {
    if (this.type === "searchStaff") {
      this.applySearchStaffFilter();
    } else {
      this.applayCurrentFilters().then(c => {
        this.isFiltered = c;
      });
    }
  }

  applySearchStaffFilter() {
    if (this.currentFilter) {
      if (this.currentFilter.filterBy) {
        let filteredData = JSON.parse(
          JSON.stringify(this.searchStaffOriginalData)
        );
        this._.forEach(this.currentFilter.filterBy, f => {
          filteredData = this._.filter(filteredData, find => {
            if (f.key === FilterByEnum.DisabledStaff) {
              return f.startIntValue === 1 ? find.loginDisabled : 0;
            }

            if (f.key === FilterByEnum.Permissions) {
              const arrList = find.positionListArray.map((item: string) => {
                return this._.indexOf(f.values as string[], item) > -1;
              });
              return this._.includes(arrList, true);
            }
            return (
              this._.indexOf(
                f.values,
                find[f.key as keyof UspDepartmentStaffSearch]
              ) > -1
            );
          });
        });
        this.filteredSearchStaffData = filteredData;
      }
    }
  }
  async applayCurrentFilters() {
    if (this.currentFilter) {
      if (this.currentFilter.filterBy) {
        let filtredData = JSON.parse(JSON.stringify(this.originalData));
        await this._.forEach(this.currentFilter.filterBy, f => {
          let startTT: Date | undefined = undefined;
          let endTT: Date | undefined = undefined;
          if (f.key === FilterByEnum.ShiftBeginTime) {
            if (f.startTimeValue) {
              startTT = moment(f.startTimeValue, "HH:mm").toDate();
            }
            if (f.endTimeValue) {
              endTT = moment(f.endTimeValue, "HH:mm").toDate();
            }
          }

          filtredData = this._.filter(filtredData, find => {
            if (f.key === FilterByEnum.OpenShifts) {
              return f.startIntValue === 1 ? find.isOpenShift : true;
            } else if (f.key === FilterByEnum.HideOpenShifts) {
              return f.startIntValue === 1 ? !find.isOpenShift : true;
            } else if (f.key === FilterByEnum.Overtime) {
              return f.startIntValue === 1 ? find.overtime > 0 : true;
            } else if (f.key === FilterByEnum.ShiftBeginTime) {
              const startDt = moment(
                moment(find.shiftStartDate).format("HH:mm"),
                "HH:mm"
              );
              return (
                (startTT &&
                  endTT &&
                  moment(startTT).diff(startDt) <= 0 &&
                  moment(endTT).diff(startDt) >= 0) ||
                (!endTT && startTT && moment(startTT).diff(startDt) <= 0) ||
                (!startTT && endTT && moment(endTT).diff(startDt) >= 0)
              );
            } else {
              return (
                this._.indexOf(f.values, find[f.key as keyof WeeklyItemModel]) >
                -1
              );
            }
          });
        });
        this.kanbanData = filtredData;
      }

      if (this.currentFilter.sortBy) {
        const sortBy: string[] = [];
        await this._.forEach(this.currentFilter.sortBy, s => {
          if (s === SortByFilterEnum.MemberPositionSortOrder) {
            sortBy.push("memberPositionSortOrder");
          }
          if (s === SortByFilterEnum.MemberPosition) {
            sortBy.push("memberPosition");
          }
          if (s === SortByFilterEnum.ShiftBeginTime) {
            sortBy.push("shiftStartDate");
          }
          if (s === SortByFilterEnum.PositionCategory) {
            sortBy.push("shiftTypeCategoryId");
          }
          if (s === SortByFilterEnum.EmployeeName) {
            sortBy.push("user_FName");
            sortBy.push("user_LName");
          }
          if (s === SortByFilterEnum.TeamSortOrder) {
            sortBy.push("shiftTypeSortOrder");
          }
          if (s === SortByFilterEnum.ShiftTypeSortOrder) {
            sortBy.push("shiftTypeSortOrder");
          }
        });

        if (sortBy.length) {
          this.currentSortBy = sortBy;
          this.kanbanData = this._.sortBy(this.kanbanData, sortBy);
        } else {
          this.currentSortBy = [];
        }
      }
      console.log("filtered data", this.kanbanData);
    }
    this.isFiltered = true;
    return this.isFiltered;
  }

  getStartEndDate(
    date: Date,
    addDays: number,
    type = "weekly"
  ): DateFilterModel {
    const data: DateFilterModel = {
      startDate: new Date(),
      endDate: new Date(),
      type: ""
    };

    const weekStart =
      type === "weekly"
        ? this.getWeekStart(date)
        : this.type === "month"
        ? moment(date)
            .startOf("month")
            .format("YYYY/MM/DD")
        : moment(date).format("YYYY/MM/DD");
    const start = moment(weekStart).format("YYYY/MM/DD") + " 00:00:00";
    const end =
      this.type === "month"
        ? moment(weekStart)
            .endOf("month")
            .format("YYYY/MM/DD") + " 23:59:59"
        : moment(weekStart)
            .add("d", addDays)
            .format("YYYY/MM/DD") + " 23:59:59";

    data.startDate = moment(start).toDate();
    data.endDate = moment(end).toDate();

    return data;
  }

  parseCalendarModel(data: WeeklyItemModel[], currentDate: Date) {
    if (data) {
      data.forEach(d => {
        d.name =
          d.userId == null || d.userId == undefined
            ? ""
            : `${d.user_FName} ${d.user_LName}`;
        const startDt = moment(d.shiftStartDate);
        const endDt = moment(d.shiftEndDate);
        const currDt = moment(currentDate);
        d.shiftDateDt = moment(d.shiftDate).toDate();
        d.shiftStartDate = startDt.toDate();
        d.shiftEndDate = endDt.toDate();
        d.StartTime = startDt.toDate();
        d.EndTime = endDt.toDate();
        d.Id = d.userId;
        d.isOpenShift = d.userId == null || d.userId == undefined;

        d.showNextDay =
          moment(endDt.format("YYYY/MM/DD")).isAfter(
            moment(currDt.format("YYYY/MM/DD"))
          ) &&
          moment(currDt.format("YYYY/MM/DD")).diff(
            moment(endDt.format("YYYY/MM/DD")),
            "days"
          ) < 0;
        d.showPreviousDay =
          moment(startDt.format("YYYY/MM/DD")).isBefore(
            moment(currDt.format("YYYY/MM/DD"))
          ) &&
          moment(startDt.format("YYYY/MM/DD")).diff(
            moment(currDt.format("YYYY/MM/DD")),
            "days"
          ) < 0;
      });

      console.log("parseCalendarModel", data);
    }
    return data;
  }
}
