




import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
@Component
export default class TimeScaleTemplate extends Vue {
  private data = {};

  private getMajorTime(date: Date): string {
    return moment(date).format("HH:mm");
  }
  private getMinorTime(date: Date): string {
    return moment(date).format("HH:mm");
  }
}
