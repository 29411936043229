






import { Component, Vue } from "vue-property-decorator";
@Component
export default class EmployeeScheduleComponent extends Vue {
  testString = "test";
}
