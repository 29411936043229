import httpAuth from "../common/http/http-common";
const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
  requestHttp = httpAuth({ requiresAuth: false });

  login(username: string, password: string) {
    // localStorage.setItem("user", JSON.stringify({ username, password }));
    // return Promise.resolve({ username, password });
    return this.requestHttp
      .post(API_URL + "/login/Authenticate", {
        username,
        password
      })
      .then(response => {
        console.log("response login: ", response.data);
        if (response.data.token) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem(
            "user",
            JSON.stringify({
              userId: response.data.id,
              username: response.data.username
            })
          );
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }

  register(username: string, email: string, password: string) {
    return this.requestHttp.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }
}

export default new AuthService();
