






















































































































import {
  Component,
  Mixins,
  Watch,
  Ref,
  Prop,
  Emit
} from "vue-property-decorator";
import { FilterMixin } from "@/mixins";
import { namespace } from "vuex-class";
const SideBar = namespace("SideBar");
const CalendarFilterModule = namespace("CalendarFilterModule");
import {
  SideBarType,
  FilterModel,
  SideMenuItem,
  SettingsSchedulerModel
} from "@/models";
import moment from "moment";
import {
  GroupByFilterComponent,
  SortByFilterComponent,
  SchedulerFilterComponent,
  FilterComponent
} from "./template";
import { DatePicker } from "@syncfusion/ej2-vue-calendars";
import { ChangeEventArgs as ChangeEventArgsDD } from "@syncfusion/ej2-vue-dropdowns";
import { Spinner } from "@/common";
import { setTime } from "node_modules/@syncfusion/ej2-vue-schedule";
@Component({
  components: {
    GroupByFilterComponent,
    SortByFilterComponent,
    SchedulerFilterComponent,
    FilterComponent,
    Spinner
  }
})
export default class HeaderFilterComponent extends Mixins(FilterMixin) {
  @Prop() sourceData!: object[];
  @Ref("sortBy") readonly sortBy!: SortByFilterComponent;
  @Ref("filterComponent") readonly filterComponent!: FilterComponent;
  @Ref() readonly spinner!: Spinner;
  viewExampleData: object[] = [];
  private height = "200px";
  private fieldsCust: object = {
    text: "key",
    value: "id"
  };
  @Ref("datepicker") readonly datepicker!: DatePicker;
  @CalendarFilterModule.Getter
  currentFilter!: FilterModel;
  @CalendarFilterModule.Getter
  currentSchedulerFilter!: SettingsSchedulerModel;
  @CalendarFilterModule.Getter
  currentDate!: Date;
  @CalendarFilterModule.Action
  changeFilter!: (model: FilterModel) => Promise<void>;
  @CalendarFilterModule.Action
  changeFilterView!: (model: FilterModel) => Promise<void>;
  @CalendarFilterModule.Action
  changeSchedulerFilter!: (model: SettingsSchedulerModel) => void;
  @SideBar.Action
  private toggleSideMenu!: (type: SideMenuItem) => void;

  originalModel?: FilterModel | SettingsSchedulerModel | null = null;

  @Emit("filterInputChange") inputChange(inputVal: string) {
    return inputVal;
  }

  filterLoaded(value: boolean) {
    if (value && this.spinner) {
      this.spinner.hideSpinner(
        document.getElementById("loader") as HTMLElement
      );
    }
  }
  showSpin() {
    if (
      this.type !== "scheduler" &&
      this.filterComponent &&
      !this.filterComponent.isFilterLoaded
    ) {
      setTimeout(() => {
        console.log("showSpin", this.spinner);
        if (this.spinner) {
          this.spinner.createSpinner({
            target: document.getElementById("loader") as HTMLElement
          });
          this.spinner.showSpinner(
            document.getElementById("loader") as HTMLElement
          );
        }
      }, 0);
    }
  }
  @Watch("currentFilter")
  changeFilterModel() {
    console.log("changeFilterModel", this.currentFilter);
    this.originalModel = JSON.parse(JSON.stringify(this.currentFilter));
  }

  @Watch("currentDate", { immediate: true, deep: true })
  changeCurrentDate() {
    console.log("changeCurrentDate", this.currentDate);
    this.originalDate = this.currentDate;
  }

  @Watch("$route", { immediate: true, deep: true })
  onRouteChange() {
    this.resetState();
    this.isFilterOpen = false;
  }

  @Watch("type")
  onTypeChange() {
    if (this.type === "scheduler") {
      if (this.currentSchedulerFilter) {
        this.originalModel = JSON.parse(
          JSON.stringify(this.currentSchedulerFilter)
        );
      }
    } else {
      for (let i = 0; i < 5; i++) {
        this.viewExampleData.push({
          key: `view ${i}`,
          id: i.toString()
        });
      }
      if (this.currentFilter) {
        this.originalModel = JSON.parse(JSON.stringify(this.currentFilter));
      }
      this.createDefultFilters();
      this.$root.$on("updated-next-day-from-card", (value: number) => {
        console.log("updated-next-day-from-card", value);
        this.changeValue(value);
      });
    }
  }

  onViewChange(args: ChangeEventArgsDD) {
    if (args.itemData) {
      console.log("onViewChange", args);
      this.changeFilterView(this.originalModel as FilterModel);
    }
  }

  clearFilter() {
    if (this.type !== "scheduler") {
      this.filterComponent.clearValue();
      (this.originalModel as FilterModel).sortBy = [];
      (this.originalModel as FilterModel).filterBy = [];
      this.changeFilter(this.originalModel as FilterModel);
    } else {
      (this.originalModel as SettingsSchedulerModel).selectedShifts = [];
      (this.originalModel as SettingsSchedulerModel).selectedPositions = [];
      (this.originalModel as SettingsSchedulerModel).selectedTitles = [];
      (this.originalModel as SettingsSchedulerModel).selectedCategories = [];
      (this.originalModel as SettingsSchedulerModel).selectedFtes = [];
    }
  }
  saveFilter() {
    console.log("saveFilter sortby", this.sortBy);
    console.log("saveFilter filterbywrapper", this.filterComponent);
    this.filterComponent?.collectValue();
    if (this.type !== "scheduler") {
      let sortBy: string[] = [];
      if (this.sortBy) {
        sortBy = this.sortBy.collectValue();
      }
      (this.originalModel as FilterModel).sortBy = sortBy;
    }

    console.log(
      "saveFilter",
      this.type == "scheduler"
        ? this.currentSchedulerFilter
        : this.currentFilter,
      this.originalModel
    );
    const prop = this.getChangedSettings(
      this.originalModel,
      this.type == "scheduler"
        ? this.currentSchedulerFilter
        : this.currentFilter
    );
    console.log("saveFilter prop", prop);
    if (prop.length > 0) {
      if (this.type == "scheduler") {
        this.changeSchedulerFilter(
          this.originalModel as SettingsSchedulerModel
        );
      } else {
        this.changeFilter(this.originalModel as FilterModel);
      }
    }
    this.toggleFilterBody();
  }

  toggleDailyIcons() {
    this.$root.$emit("open-daily-icons", true);
  }

  toggleAddDepartmentMenu() {
    this.toggleSideMenu({
      type: SideBarType.AddShift,
      item: {
        shiftDate: moment(this.originalDate).format("MM/DD/YYYY"),
        positions: []
      }
    });
  }
  applyFilterStaff(event: Event) {
    const inputVal = (event.target as HTMLInputElement).value;
    this.inputChange(inputVal);
  }
  createDefultFilters() {
    if (this.currentFilter && !this.currentFilter.sortBy) {
      this.currentFilter.sortBy = [];
    }
  }

  openDatePicker() {
    console.log("openDatePicker");
    if (this.datepicker) {
      this.datepicker.show();
    }
  }
}
