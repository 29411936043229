





























































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { BaseSideBarMixin } from "@/mixins";
import { namespace } from "vuex-class";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";
const SideBar = namespace("SideBar");
const ShiftModule = namespace("ShiftModule");
const DepartmentModule = namespace("DepartmentModule");
const Toast = namespace("ToastModule");
import { ChangeEventArgs } from "@syncfusion/ej2-vue-calendars";
import {
  ChangeEventArgs as ChangeEventArgsDD,
  PopupEventArgs
} from "@syncfusion/ej2-vue-dropdowns";
import {
  MemeberPostion,
  ShiftInsertModel,
  DepartmentEmployeeModel,
  ShiftType,
  ShiftTypeRequest,
  ShiftPostionInsertModel,
  BaseResponse,
  ToastOptions
} from "@/models";
import {
  ValidationObserver,
  ValidationProvider,
  ValidationObserverInstance
} from "vee-validate";

import moment from "moment";

@Component({
  components: {
    "side-menu-layout": SideMenuLayout,
    ValidationObserver,
    ValidationProvider
  }
})
export default class AddShiftComponent extends Mixins(BaseSideBarMixin) {
  @Ref("valObs") readonly valObs!: ValidationObserverInstance;
  loadingShiftType = false;
  positionError = "";
  localShiftFields = { text: "shiftType", value: "shiftTypeId" };
  localPositionFields = { text: "memberPosition", value: "memberPositionId" };

  shiftTypes: ShiftType[] = [];
  positions: MemeberPostion[] = [];

  selectedShiftType!: ShiftType;
  isModelCahnge = false;

  isPosionChange = false;

  selectedPositions: ShiftPostionInsertModel[] = [];

  @SideBar.Getter
  private currentActiveMenuItem!: ShiftInsertModel;

  model: ShiftInsertModel = { ...this.currentActiveMenuItem };
  originalModel!: ShiftInsertModel;
  @Toast.Action
  private showToast!: (toastOptions: ToastOptions) => void;

  @DepartmentModule.Getter
  currentDepartment!: DepartmentEmployeeModel;
  @ShiftModule.Action
  getShiftType!: (model: ShiftTypeRequest) => Promise<ShiftType[]>;
  @ShiftModule.Action
  shiftsUpsert!: (model: ShiftInsertModel) => Promise<BaseResponse>;
  @ShiftModule.Getter
  isShowError!: boolean;
  @ShiftModule.Action
  cleanError!: () => Promise<void>;
  @ShiftModule.Action
  getPositions!: (departmentId: number) => Promise<MemeberPostion[]>;

  @Watch("currentActiveMenuItem", { immediate: true, deep: true })
  changeCurrentActiveMenuItem() {
    console.log("currentActiveMenuItem change", this.currentActiveMenuItem);
    // this.model = { ...this.currentActiveMenuItem };
  }

  @Watch("model", { immediate: true, deep: true })
  changeModel(newVal: ShiftInsertModel) {
    console.log("model change", newVal);

    this.isModelCahnge = true;
    console.log("isModelCahnge", this.isModelCahnge);
  }

  async mounted() {
    this.model = { ...this.currentActiveMenuItem };
    this.getRefreshShiftTypes();
    this.getRefreshPositions();
    if (this.valObs) {
      this.valObs.reset();
    }
  }

  @Watch("currentDepartment", { immediate: true, deep: true })
  changeCurrentDepartment() {
    console.log("currentDepartment change", this.currentDepartment);
  }
  async onSubmit() {
    console.log("onSubmit", this.model);
    const isValid = await this.valObs.validate();
    if (!isValid) {
      return;
    }
    const validPositions = this.checkIfPositionSelected();
    if (!validPositions) {
      this.positionError = "Please select at least one position";
      return;
    }

    if (!this.model.shiftId || this.model.shiftId == null) {
      this.model.locationId = this.currentDepartment.companyDepartmentLocationId;
      this.model.departmentId = this.currentDepartment.companyDepartmentId;

      const dt = moment(this.model.shiftDate).format("MM/DD/yyyy");

      if (this.selectedShiftType.shiftPeriodStartTime) {
        const startTime = this.getTimeString(
          this.selectedShiftType.shiftPeriodStartTime_ms
        );
        this.model.shiftPeriodStart = moment(
          dt + (startTime != "" ? " " + startTime : "")
        ).toDate();
      }

      if (this.selectedShiftType.shiftPeriodEndTime) {
        const endTime = this.getTimeString(
          this.selectedShiftType.shiftPeriodEndTime_ms
        );

        this.model.shiftPeriodEnd = moment(
          dt + (endTime != "" ? " " + endTime : "")
        ).toDate();
      }

      this.model.shiftStartDate = moment(
        dt + " " + this.model.shiftBeginTime
      ).toDate();
      this.model.shiftEndDate = moment(
        dt + " " + this.model.shiftEndTime
      ).toDate();

      if (
        moment(this.model.shiftStartDate).diff(
          moment(this.model.shiftEndDate)
        ) >= 0
      ) {
        this.model.shiftEndDate = moment(this.model.shiftEndDate)
          .add("d", 1)
          .toDate();
      }

      this.model.shiftDurationMinutes = moment
        .duration(
          moment(this.model.shiftEndDate).diff(
            moment(this.model.shiftStartDate)
          )
        )
        .asMinutes();
    }
    this.model.shiftRecStat = 1;
    console.log(this.model);
    this.shiftsUpsert(this.model).then(() => {
      if (this.isShowError) {
        this.cleanError();
        // this.$toasted.show("Error occurred", {
        //   position: "top-right",
        //   theme: "outline",
        //   duration: 5000
        // });
        this.showToast({
          title: "Error",
          content: "Error occurred",
          cssClass: "e-toast-danger"
        });
      } else {
        // this.$toasted.show("Shift save success", {
        //   position: "top-right",
        //   theme: "outline",
        //   duration: 5000
        // });
        this.showToast({
          title: "Success",
          content: "Shift save success",
          cssClass: "e-toast-success"
        });
        this.closeMenu({
          success: true,
          reload: true
        });
      }
    });
  }

  changeReqired(pos: ShiftPostionInsertModel) {
    console.log("change pos", pos);
    if (!pos.numberOfEmpReq || !pos.numberOfEmpReq.toString().match(/^\d+$/)) {
      pos.numberOfEmpReq = 0;
    } else {
      pos.numberOfEmpReq = parseInt(pos.numberOfEmpReq.toString(), 10);
    }
  }

  checkIfPositionSelected() {
    if (!this.model.positions.length) {
      //At least one position need to be selected.
      return false;
    }

    return true;
  }
  onOpen(e: PopupEventArgs) {
    const allItems = e.popup.element.querySelectorAll(".e-list-item");
    if (allItems.length) {
      const ids = this.model.positions.map(c => c.positionId);
      this._.forEach(allItems, item => {
        const filt = this._.find(ids, i => {
          return i !== null && i.toString() === item.getAttribute("data-value");
        });
        if (filt) {
          if (!item.classList.contains("e-hide")) {
            item.classList.add("e-hide");
          }
        } else {
          item.classList.remove("e-hide");
        }
      });
    }
  }

  onPositionChange(e: ChangeEventArgsDD) {
    this.isPosionChange = false;
  }

  removePosition(pos: ShiftPostionInsertModel, index: number) {
    if (pos.positionId === null || pos.isAdded) {
      this.model.positions.splice(index, 1);
    } else {
      pos.isDeleted = true;
      pos.isUpdated = true;
    }

    this.isPosionChange = !this.checkINewPOsition();
  }

  checkINewPOsition(): boolean {
    const newItems = this._.filter(this.model.positions, p => {
      return p.positionId === null;
    });
    return newItems.length === 0;
  }
  addNewPosition() {
    this.isPosionChange = true;
    this.positionError = "";
    this.model.positions.push({
      positionName: "",
      positionId: null,
      numberOfEmpReq: 0,
      isAdded: true,
      isUpdated: false,
      isDeleted: false
    });

    console.log("Add new Position", this.model);
  }

  onShiftTypeChange(args: ChangeEventArgsDD) {
    if (args.itemData) {
      this.selectedShiftType = args.itemData as ShiftType;
      this.model.shiftBeginTime = (args.itemData as ShiftType).shiftTimeOfDayStart;
      this.model.shiftEndTime = (args.itemData as ShiftType).shiftTimeOfDayEnd;
    }
    console.log(
      "onShiftTypeChange",
      this.selectedShiftType.shiftPeriodStartTime
    );
  }
  onDateChange(args: ChangeEventArgs) {
    this.getRefreshShiftTypes();
  }
  getRefreshPositions() {
    if (this.currentDepartment && this.currentDepartment.companyDepartmentId) {
      this.getPositions(this.currentDepartment.companyDepartmentId).then(
        positions => {
          this.positions = positions;
        }
      );
    }
  }
  getRefreshShiftTypes() {
    if (this.model.shiftDate) {
      this.shiftTypes = [];
      this.loadingShiftType = true;

      this.resetFormValidation();

      this.getShiftType({
        departmentId: this.currentDepartment.companyDepartmentId,
        shiftDate: new Date(this.model.shiftDate)
      })
        .then(st => {
          this.shiftTypes = st;
          console.log(" this.shiftTypes", this.shiftTypes);
        })
        .finally(() => {
          this.loadingShiftType = false;
          console.log("finaly after sfift type get");
        });
    }
  }

  resetFormValidation() {
    this.model.shiftBeginTime = "";
    this.model.shiftEndTime = "";
    this.model.shiftTypeId = null;

    setTimeout(() => {
      this.valObs.reset();
    }, 0);
  }
}
