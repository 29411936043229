




























import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
import { RegistrationModel } from "@/models";

@Component
export default class Registration extends Vue {
  private user: RegistrationModel = {
    username: "",
    password: "",
    email: ""
  } as RegistrationModel;
  private message = "";
  private loading = false;

  @Auth.Getter
  private isLoggedIn!: boolean;

  @Auth.Action
  private register!: (data: RegistrationModel) => Promise<void>;

  private regUser() {
    this.loading = true;
    this.$validator.validateAll().then((isValid: boolean) => {
      if (!isValid) {
        this.loading = false;
        return;
      }

      if (this.user.username && this.user.password) {
        this.register(this.user).then(
          () => {
            this.$router.push("/");
            this.loading = false;
          },
          () => {
            this.loading = false;
            // this.message = error;
          }
        );
      }
    });
  }
}
