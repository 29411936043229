





































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { BaseSideBarMixin } from "@/mixins";
import { namespace } from "vuex-class";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";
const SideBar = namespace("SideBar");
const ShiftModule = namespace("ShiftModule");
const DepartmentModule = namespace("DepartmentModule");
import {
  WeeklyItemModel,
  IconItem,
  EditIconData,
  ShiftEmployeeDropDownRequest,
  IconItemUpset,
  IconUpsetRequest,
  BaseResponse,
  DepartmentEmployeeModel,
  DeleteDailyIconRequest
} from "@/models";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  IconCardTemplateComponent,
  IconEditFormTemplateComponent
} from "@/common";
import moment from "moment";
import { Dialog } from "@syncfusion/ej2-vue-popups";
@Component({
  components: {
    "side-menu-layout": SideMenuLayout,
    ValidationObserver,
    ValidationProvider,
    IconCardTemplateComponent,
    IconEditFormTemplateComponent
  }
})
export default class UpsetIconComponent extends Mixins(BaseSideBarMixin) {
  confirmDlgButtons = [
    {
      click: this.deleteIcon,
      buttonModel: { content: "Yes", isPrimary: true }
    },
    { click: this.closePopover, buttonModel: { content: "No" } }
  ];
  confirmHeader = "Delete Item`(s)";
  animationSettings = { effect: "None" };
  confirmContent =
    "<span>Are you sure you want to permanently delete these icon ?</span>";
  confirmCloseIcon = true;
  isModal = true;
  confirmWidth = "400px";
  target = ".main-content";
  @Ref("icon-form") readonly iconForm!: IconEditFormTemplateComponent;
  @Ref("confirmDialog") readonly confirmDialog!: Dialog;
  @SideBar.Getter
  private currentActiveMenuItem!: WeeklyItemModel;
  @SideBar.Getter
  private currentsSubItem!: IconItem;

  @DepartmentModule.Getter
  currentDepartment!: DepartmentEmployeeModel;
  loading = false;

  model!: IconItemUpset;

  @ShiftModule.Action
  getEditIconData!: (
    model: ShiftEmployeeDropDownRequest
  ) => Promise<EditIconData>;
  @ShiftModule.Action
  iconDailyUpset!: (model: IconUpsetRequest) => Promise<BaseResponse>;
  @ShiftModule.Action
  deleteDailyIcon!: (model: DeleteDailyIconRequest) => Promise<BaseResponse>;
  @Watch("currentsSubItem", { immediate: true, deep: true })
  changeCurrentActiveMenuItem() {
    console.log("currentsSubItem change", this.currentsSubItem);
    this.checkIfIconExist();
  }

  async onSubmit() {
    console.log(
      "upset icon ",
      this.currentActiveMenuItem,
      this.currentsSubItem
    );
    if (this.iconForm) {
      const valid = await this.iconForm.valObs.validate();
      if (!valid) {
        return;
      }

      const model = {
        userId: this.currentActiveMenuItem.userId,
        userShiftId: this.currentActiveMenuItem.userShiftId,
        shiftStartDate: this.currentActiveMenuItem.shiftStartDate,
        shiftEndDate: this.currentActiveMenuItem.shiftEndDate,
        workrule: this.model.workrule,
        departmentId: this.currentActiveMenuItem.departmentId,
        currentDepartmentName: this.currentDepartment.companyDepartmentName,
        amount: this.model.amount,
        userScheduleType: this.model.userScheduleType,
        userScheduleTypeName: this.model.userScheduleTypeName,
        hoursType: this.model.hoursType,
        payCodeDepartment: this.model.payCodeDepartment,
        shiftIncentiveDepartment: this.model.shiftIncentiveDepartment,
        userScheduleTypeSubtype: this.model.userScheduleTypeSubtype,
        beginTime: this.model.beginTime,
        endTime: this.model.endTime,
        notes: this.model.notes,
        userScheduleId: this.currentsSubItem.userScheduleId
      } as IconUpsetRequest;
      console.log("request", model);
      this.iconDailyUpset(model).then(c => {
        console.log("response from icon update", c);
        if (c.success) {
          this.closeMenu({
            success: true,
            reload: true
          });
        } else {
          console.log("error upset image");
        }
      });
    }
  }
  dialogClose() {
    console.log("dialogClose");
  }
  closePopover() {
    console.log("closePopover");
    if (this.confirmDialog) {
      this.confirmDialog.hide();
    }
  }
  deleteIcon() {
    const model = {
      userId: this.currentActiveMenuItem.userId,
      departmentId: this.currentActiveMenuItem.departmentId,
      currentDepartmentName: this.currentDepartment.companyDepartmentName,
      userScheduleId: this.currentsSubItem.userScheduleId,
      userScheduleType: this.currentsSubItem.userScheduleType,
      userScheduleTypeName: this.currentsSubItem.scheduleTypeName,
      userScheduleDate: this.currentsSubItem.userScheduleDate
    } as DeleteDailyIconRequest;
    this.deleteDailyIcon(model).then(c => {
      console.log("deleteIcon response ", c);
      if (c.success) {
        if (this.confirmDialog) {
          this.confirmDialog.hide();
        }
        this.closeMenu({
          success: true,
          reload: true
        });
      } else {
        console.log("error delete icon");
      }
    });
  }
  deleteIconPopover() {
    console.log("delete poover");
    if (this.confirmDialog) {
      this.confirmDialog.show();
    }
  }

  checkIfIconExist() {
    const dt = moment().format("MM/DD/yyyy");
    let beginDt = undefined;
    if (this.currentsSubItem && this.currentsSubItem.userScheduleBeginTime) {
      beginDt = moment(
        dt + " " + this.currentsSubItem.userScheduleBeginTime
      ).toDate();
    }
    let endDt = undefined;
    if (this.currentsSubItem && this.currentsSubItem.userScheduleEndTime) {
      endDt = moment(
        dt + " " + this.currentsSubItem.userScheduleEndTime
      ).toDate();
    }
    this.model = {
      userScheduleType: this.currentsSubItem
        ? this.currentsSubItem.userScheduleType
        : undefined,
      hoursType: this.currentsSubItem
        ? this.currentsSubItem.userScheduleHours
        : undefined,
      payCodeDepartment: undefined,
      shiftIncentiveDepartment: undefined,
      userScheduleTypeSubtype: undefined,
      beginTime: beginDt,
      endTime: endDt,
      workrule: false,
      userScheduleTypeName: undefined,
      notes: this.currentsSubItem
        ? this.currentsSubItem.userScheduleNotes
        : undefined,
      amount: undefined
    };
  }
}
