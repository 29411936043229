import { RouteConfig } from "vue-router";
import { MonthComponent } from "@/components/calendar/month";

const path = "/month";

export const MONTH_ROUTES: RouteConfig[] = [
  {
    path,
    name: "MonthComponent",
    component: MonthComponent
  }
];
