








































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
import { UserModel } from "@/models";

@Component
export default class Login extends Vue {
  private user: UserModel = {
    username: "",
    password: "",
    remember: false
  } as UserModel;
  private message = "";
  private loading = false;

  @Auth.Getter
  private isLoggedIn!: boolean;

  @Auth.Getter
  private errorMessage!: string;

  @Auth.Action
  private login!: (data: UserModel) => Promise<void>;
  mounted() {
    const lastInfo = localStorage.getItem("auth_data");
    if (lastInfo && lastInfo !== undefined) {
      const authData = JSON.parse(lastInfo);
      this.user.username = authData.username;
      this.user.remember = authData.remember;
    }
  }
  created() {
    if (this.isLoggedIn) {
      this.$router.push("/day");
    }
  }
  private authUser() {
    this.loading = true;
    this.$validator.validateAll().then((isValid: boolean) => {
      console.log("ayth", this.user, isValid);
      if (!isValid) {
        this.loading = false;
        return;
      }

      if (this.user.username && this.user.password) {
        if (this.user.remember === true) {
          localStorage.setItem(
            "auth_data",
            JSON.stringify({
              username: this.user.username,
              remember: this.user.remember
            })
          );
        } else {
          if (localStorage.getItem("auth_data")) {
            localStorage.removeItem("auth_data");
          }
        }
        this.login(this.user)
          .then(
            response => {
              console.log("response login", response);
              if (response != undefined) {
                this.$router.push("/");
              }
              this.loading = false;
            }
            // error => {
            //   this.loading = false;
            //   // this.message = error;
            // },
          )
          .finally(() => {
            this.loading = false;
          });
      }
    });
  }
}
