import httpAuth from "../common/http/http-common";
import * as _ from "lodash";
import {
  ShiftTypeRequest,
  ShiftInsertModel,
  ShiftEmployeeDropDownRequest,
  ShiftEmployeeUpset,
  IconUpsetRequest,
  DeleteDailyIconRequest,
  ShiftInformation
} from "@/models";
import moment from "moment";
class ShiftService {
  getShiftType(model: ShiftTypeRequest) {
    return httpAuth().get(`/shift/GetDepartmentShiftTypes`, {
      params: {
        departmentId: model.departmentId,
        shiftDate:
          model.shiftDate == undefined
            ? null
            : moment(model.shiftDate).format("YYYY-MM-DDTHH:mm:ss")
      }
    });
  }

  getPositions(departmentId: number) {
    return httpAuth().get(`/shift/GetUspDepartmentPositions`, {
      params: {
        departmentId
      }
    });
  }

  getPositionsByEmployee(model: ShiftEmployeeDropDownRequest) {
    return httpAuth().get("/shift/GetPositionDropDownData", {
      params: {
        userId: model.userId,
        departmentId: model.departmentId
      }
    });
  }

  shiftsUpsert(model: ShiftInsertModel) {
    return httpAuth().post(`/shift/ShiftsUpsert`, {
      shiftId: model.shiftId,
      locationId: model.locationId,
      departmentId: model.departmentId,
      shiftTypeId: model.shiftTypeId,
      shiftDate: moment(model.shiftDate).format("YYYY-MM-DDTHH:mm:ss"),
      shiftBeginTime: model.shiftBeginTime,
      shiftEndTime: model.shiftEndTime,
      numberOfEmployeesNotified: model.numberOfEmployeesNotified,
      numberOfEmployeesAccepted: model.numberOfEmployeesAccepted,
      shiftContactId: model.shiftContactId,
      shiftNotes: model.shiftNotes,
      shiftRecStat: model.shiftRecStat,
      shiftSessionID: model.shiftSessionID,
      dateEntered: model.dateEntered
        ? moment(model.dateEntered).format("YYYY-MM-DDTHH:mm:ss")
        : undefined,
      shiftRecStatFilled: model.shiftRecStatFilled,
      enteredBy: model.enteredBy,
      shiftStartDate: moment(model.shiftStartDate).format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      shiftEndDate: moment(model.shiftEndDate).format("YYYY-MM-DDTHH:mm:ss"),
      shiftDurationMinutes: model.shiftDurationMinutes,
      weekOf: moment(model.weekOf).format("YYYY-MM-DDTHH:mm:ss"),
      shiftStartDateOnly: moment(model.shiftStartDateOnly).format("YYYY-MM-DD"),
      sSTShiftID: model.sSTShiftID,
      shiftPeriodStart: moment(model.shiftPeriodStart).format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      shiftPeriodEnd: moment(model.shiftPeriodEnd).format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      positions: model.positions,
      parLevel: model.parLevel
    });
  }

  getShiftEmployeeDropDownData(model: ShiftEmployeeDropDownRequest) {
    return httpAuth().get("/shift/GetShiftEmployeeDropDownData", {
      params: {
        userId: model.userId,
        departmentId: model.departmentId,
        locationId: model.locationId
      }
    });
  }

  getShiftEmployeeEditIconData(model: ShiftEmployeeDropDownRequest) {
    return httpAuth().get("/shift/GetShiftEmployeeEditIconData", {
      params: {
        departmentId: model.departmentId,
        userScheduleTypeId: model.userScheduleTypeId
      }
    });
  }

  shiftEmployeeUpset(model: ShiftEmployeeUpset) {
    const iconModel = model.icon;
    return httpAuth().post(`/shift/ShiftEmployeeUpset`, {
      icon: iconModel
        ? {
            userScheduleType: iconModel.userScheduleType,
            hoursType: iconModel.hoursType,
            payCodeDepartment: iconModel.payCodeDepartment,
            shiftIncentiveDepartment: iconModel.shiftIncentiveDepartment,
            userScheduleTypeSubtype: iconModel.userScheduleTypeSubtype,
            beginTime: iconModel.beginTime
              ? moment(iconModel.beginTime).format("YYYY-MM-DDTHH:mm:ss")
              : undefined,
            endTime: iconModel.endTime
              ? moment(iconModel.endTime).format("YYYY-MM-DDTHH:mm:ss")
              : undefined,
            notes: iconModel.notes
          }
        : undefined,
      userShiftId: model.userShiftId,
      userId: model.userId,
      memberPositionId: model.memberPositionId,
      shiftDate: moment(model.shiftDate).format("YYYY-MM-DDTHH:mm:ss"),
      shiftStartDate: moment(model.shiftStartDate).format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      shiftEndDate: moment(model.shiftEndDate).format("YYYY-MM-DDTHH:mm:ss"),
      departmentId: model.departmentId,
      locationId: model.locationId,
      shiftBeginTime: moment(model.shiftStartDate).format("HH:mm"),
      shiftEndTime: moment(model.shiftEndDate).format("HH:mm"),
      shiftId: model.shiftId,
      shiftTypeId: model.shiftTypeId,
      shiftEmpReqId: model.shiftEmpReqId,
      shiftDateDt: moment(model.shiftDateDt).format("YYYY-MM-DDTHH:mm:ss"),
      workrule: model.workrule,
      currentDepartmentName: model.currentDepartmentName
    });
  }

  getEditIconData(departmentId: number, userScheduleTypeId: number) {
    return httpAuth().get(`/shift/GetEditIconData`, {
      params: {
        departmentId,
        userScheduleTypeId
      }
    });
  }

  iconDailyUpset(model: IconUpsetRequest) {
    return httpAuth().post(`/shift/UpsetDailyIcon`, {
      userScheduleType: model.userScheduleType,
      hoursType: model.hoursType,
      payCodeDepartment: model.payCodeDepartment,
      shiftIncentiveDepartment: model.shiftIncentiveDepartment,
      userScheduleTypeSubtype: model.userScheduleTypeSubtype,
      beginTime: model.beginTime
        ? moment(model.beginTime).format("YYYY-MM-DDTHH:mm:ss")
        : undefined,
      endTime: model.endTime
        ? moment(model.endTime).format("YYYY-MM-DDTHH:mm:ss")
        : undefined,
      notes: model.notes,
      userShiftId: model.userShiftId,
      userId: model.userId,
      shiftStartDate: moment(model.shiftStartDate).format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      shiftEndDate: moment(model.shiftEndDate).format("YYYY-MM-DDTHH:mm:ss"),
      departmentId: model.departmentId,
      workrule: model.workrule,
      currentDepartmentName: model.currentDepartmentName,
      userScheduleId: model.userScheduleId
    });
  }

  deleteDailyIcon(model: DeleteDailyIconRequest) {
    return httpAuth().post(`/shift/DeleteDailyIcon`, model);
  }

  getShiftInformation(shiftId: number) {
    return httpAuth().get("/shift/GetShiftInformation", {
      params: {
        shiftId
      }
    });
  }

  shiftUpsetInformation(model: ShiftInformation) {
    return httpAuth().post(`/shift/ShiftUpsetInformation`, {
      shiftId: model.shiftId,
      locationId: model.locationId,
      departmentId: model.departmentId,
      shiftTypeId: model.shiftTypeId,
      shiftDate: moment(model.shiftDate).format("YYYY-MM-DDTHH:mm:ss"),
      shiftBeginTime: model.shiftBeginTime,
      shiftEndTime: model.shiftEndTime,
      shiftStartDate: moment(model.shiftStartDate).format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      shiftEndDate: moment(model.shiftEndDate).format("YYYY-MM-DDTHH:mm:ss"),
      positions: model.positions,
      attributes: model.attributes
    });
  }

  deleteEmployeeShift(shiftEmpReqId: number) {
    return httpAuth().post(`/shift/DeleteEmployeeShift`, {
      shiftEmpReqId
    });
  }

  deleteShiftAttribute(attributeId: number) {
    return httpAuth().post(`/shift/DeleteShiftAttribute`, {
      attributeId
    });
  }
}

export default new ShiftService();
