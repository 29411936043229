







































import { Component, Vue } from "vue-property-decorator";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";

@Component({
  components: {
    "side-menu-layout": SideMenuLayout
  }
})
export default class AddNewDepartmentComponent extends Vue {
  tableData = {
    fields: ["department", "actions"],
    items: [{ department: "Clinical Support Analysis" }],
    tableVariants: [
      "primary",
      "secondary",
      "info",
      "danger",
      "warning",
      "success",
      "light",
      "dark"
    ],
    striped: false,
    bordered: false,
    borderless: false,
    outlined: false,
    small: false,
    hover: false,
    dark: false,
    fixed: false,
    footClone: false,
    headVariant: null,
    tableVariant: "",
    noCollapse: false
  };
}
