



import { Vue, Component, Ref, Watch } from "vue-property-decorator";
import { ToastComponent } from "@syncfusion/ej2-vue-notifications";
import { namespace } from "vuex-class";
import { ToastOptions } from "@/models";

const ToastModule = namespace("ToastModule");
@Component({
  components: {
    "ejs-toast": ToastComponent
  }
})
export default class GlobalToast extends Vue {
  @Ref() toast!: ToastComponent;
  @ToastModule.Getter
  currentToastOptions!: ToastOptions;
  toastOptions: ToastOptions = {
    title: "",
    content: "",
    position: { X: "Center", Y: "Bottom" },
    showCloseButton: true,
    timeOut: 5000,
    cssClass: "e-toast-info"
  };

  @Watch("currentToastOptions", { deep: true })
  updateToast(newToastSettings: ToastOptions) {
    this.toast.setProperties(
      { ...this.toastOptions, ...newToastSettings },
      false
    );
    this.toast.show();
  }
}
