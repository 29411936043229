

















import { Component, Mixins, Prop } from "vue-property-decorator";
import { CardMixin } from "@/mixins";
import { namespace } from "vuex-class";
const SideBar = namespace("SideBar");
import { SideMenuItem, SideBarType } from "@/models";
@Component({
  components: {}
})
export default class ActionTemplateComponent extends Mixins(CardMixin) {
  @Prop() isApprove!: boolean;

  private onRemove(event: any) {
    this.$emit("remove-action", event);
  }
  private onApprove(event: any) {
    this.$emit("approve-action", event);
  }
}
