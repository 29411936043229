import {
  UspDepartmentStaffSearch,
  StaffToShiftRequest,
  AddStaffToShiftRequest,
  AccessLevel
} from "@/models";
import httpAuth from "../common/http/http-common";

class StaffService {
  getAllStaffByDepartmentId(departmentId: number, access: AccessLevel) {
    return httpAuth().get<UspDepartmentStaffSearch[]>(
      "/staff/GetAllStaffByDepartmentId",
      {
        params: {
          departmentId,
          access
        }
      }
    );
  }

  getStaffToShift(model: StaffToShiftRequest) {
    return httpAuth().get("/staff/GetStaffToShift", {
      params: {
        departmentId: model.departmentId,
        shiftEmpReqId: model.shiftEmpReqId
      }
    });
  }
  addStaffToShift(model: AddStaffToShiftRequest) {
    return httpAuth().post("/staff/AddStaffToShift", model);
  }
}

export default new StaffService();
