export enum UserScheduleTypeGroupEnum {
  Inactive = 0,
  General = 1,
  System = 2,
  Paycode = 3,
  Incentive = 4,
  Workrule = 5
}

export enum LookupTypeEnum {
  Amount = 1,
  AttributeStatus = 2,
  AttributeUserStatus = 3,
  AttributeLinkType = 4,
  State = 5
}

export enum SortByFilterEnum {
  MemberPositionSortOrder = "MemberPositionSortOrder",
  MemberPosition = "MemberPosition",
  ShiftBeginTime = "ShiftBeginTime",
  PositionCategory = "PositionCategory",
  EmployeeName = "EmployeeName",
  TeamSortOrder = "TeamSortOrder",
  ShiftTypeSortOrder = "ShiftTypeSortOrder"
}

export enum FilterByEnum {
  ShiftType = "shiftType",
  Position = "memberPosition",
  PositionCategory = "positionCategoryName",
  FTEPercent = "ftePercent",
  Employee = "name",
  OpenShifts = "openShifts",
  HideOpenShifts = "hideOpenShifts",
  Overtime = "overtime",
  ShiftBeginTime = "shiftBeginTime",
  // SearchStaff filters
  Permissions = "positionListArray",
  Title = "title",
  Group = "shiftGroupDescription",
  AccessLevels = "userAccessDesc",
  DisabledStaff = "disabledStaff",
  FTEType = "fteType"
}

export enum GroupByFilterEnum {
  ShiftDateDt = "shiftDateDt",
  ShiftType = "shiftType",
  Position = "memberPosition"
}
