














import { Component, Vue } from "vue-property-decorator";
import { CalendarEventModel } from "@/models";
import {
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda
} from "@syncfusion/ej2-vue-schedule";

@Component({
  provide: {
    schedule: [Day, Week, WorkWeek, Month, Agenda]
  }
})
export default class Calendar extends Vue {
  private currentView = "Month";
  private selectedDate: Date = new Date();
  private eventSettings: object = {
    dataSource: [
      {
        Id: 1,
        Subject: "Meeting",
        StartTime: new Date(),
        EndTime: new Date()
      }
    ]
  };
}
interface CalendarEvent {
  nativeEvent: MouseEvent;
  event: CalendarEventModel;
}
