export interface SideMenu {
  addDepartmentMenu: boolean;
  editDepartmentMenu: boolean;
}

export enum SideBarType {
  None = "",
  AddDepartment = "AddDepartment",
  EditDepartment = "EditDepartment",
  EditDepartmentSettings = "EditDepartmentSettings",
  ManageAccessLevel = "ManageAccessLevel",
  SendNewMessage = "SendNewMessage",
  AddLicense = "AddLicense",
  AddEmployeeShift = "AddEmployeeShift",
  DeleteEmployeeShift = "DeleteEmployeeShift",
  AddEmployeeToShift = "AddEmployeeToShift",
  AddShift = "AddShift",
  EditShift = "EditShift",
  EditEmployeeDetails = "EditEmployeeDetails",
  AddStaffToShift = "AddStaffToShift",
  UpsetIcon = "UpsetIcon",
  ActionScheduler = "ActionScheduler",
  DailyIcons = "DailyIcons",
  ResetPassword = "ResetPassword",
  EmployeeDay = "EmployeeDay",
  AddEmployeeIcon = "AddEmployeeIcon",
  EditEmployeeIcon = "EditEmployeeIcon",
  DeleteEmployeeIcon = "DeleteEmployeeIcon",
  ApprovalEmployeeIcon = "ApprovalEmployeeIcon",
  EditEmployeeShift = "EditEmployeeShift"
}

export interface SideMenuItem {
  type: SideBarType;
  item: object;
  subItem?: object;
  options?: SideMenuItemOptions;
}

export interface SideMenuItemOptions {
  success?: boolean;
  reload?: boolean;
  mainPage?: boolean;
}
