import { RouteConfig } from "vue-router";
import {
  ProfileBaseComponent,
  DepartmentAssignmentsComponent,
  EmployeeDetailsComponent,
  EmployeeScheduleComponent,
  ActivityLogComponent,
  EmployeeLicensesComponent
} from "@/components/employee-profile";

const path = "/employee-profile/:id";

export const PROFILE_ROUTES: RouteConfig[] = [
  {
    path,
    name: "ProfileBaseComponent",
    component: ProfileBaseComponent,
    redirect: path + "/details",
    children: [
      {
        path: path + "/details",
        name: "Employee Details",
        component: EmployeeDetailsComponent
      },
      {
        path: path + "/schedule",
        name: "Employee Schedule",
        component: EmployeeScheduleComponent
      },
      {
        path: path + "/assignments",
        name: "Department Assignments",
        component: DepartmentAssignmentsComponent
      },
      {
        path: path + "/log",
        name: "Activity Log",
        component: ActivityLogComponent
      },
      {
        path: path + "/licenses",
        name: "Licenses & Certifications",
        component: EmployeeLicensesComponent
      }
    ]
  }
];
