











import ProfileLogoTemplateComponent from "@/common/template/profile-logo/component.vue";
import StaffInfoTemplateComponent from "@/common/template/staff-info-template/component.vue";
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import { CardMixin } from "@/mixins";
@Component({
  components: {
    ProfileLogoTemplateComponent,
    StaffInfoTemplateComponent
  }
})
export default class StaffActionCardTemplate extends Mixins(CardMixin) {}
