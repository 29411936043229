















































import { Component, Mixins } from "vue-property-decorator";
import { BaseSideBarMixin } from "@/mixins";
import { namespace } from "vuex-class";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";

const SideBar = namespace("SideBar");
const CalendarModule = namespace("CalendarModule");
import { ShiftEmployeeUpset } from "@/models";
import { ActionCardTemplate } from "@/common";
import StaffActionCardTemplate from "@/common/template/staff-action-card/component.vue";
import { SideMenuItem, SideBarType } from "@/models";
@Component({
  components: {
    "side-menu-layout": SideMenuLayout,
    ActionCardTemplate,
    StaffActionCardTemplate
  }
})
export default class EmployeeDayComponent extends Mixins(BaseSideBarMixin) {
  @SideBar.Getter
  private currentActiveMenuItem!: any; // FIX
  @CalendarModule.Action
  private changeDates!: (dates: Date[]) => void;

  mounted() {
    console.log("clearing selected dates...");
    this.changeDates([this.currentActiveMenuItem.selectedDate]);
  }

  private hasApproval(index: number): boolean {
    return this.currentActiveMenuItem.actions != undefined && 
      this.currentActiveMenuItem.actions[index].type === "icon" &&
      this.currentActiveMenuItem.actions[index].userScheduleType === 3;
  }
  private onNewShiftClick() {
    this.toggleSideBarMenu({
      type: SideBarType.AddEmployeeToShift,
      item: this.currentActiveMenuItem
    });
  }
  private onNewIconClick() {
    this.toggleSideBarMenu({
      type: SideBarType.AddEmployeeIcon,
      item: this.currentActiveMenuItem
    });
  }
}
