import { RouteConfig } from "vue-router";
import { WeeklyComponent } from "@/components/calendar/weekly";

const path = "/weekly";

export const WEEKLY_ROUTES: RouteConfig[] = [
  {
    path,
    name: "WeeklyComponent",
    component: WeeklyComponent
  }
];
