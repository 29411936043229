import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import CalendarService from "@/services/CalendarService";
import SchedulerService from "@/services/SchedulerServices"; // fix
import {
  WeeklyItemModel,
  DateFilterModel,
  ParameterSchedulerModel,
  SchedulerItemModelByUserId,
  ParameterShiftTotalsModel,
  ParameterShiftStatisticsModel,
  ShiftTotalsItemModelByUserId,
  ShiftStatisticsItemModelByUserId,
  ParameterCalendarModel,
  CalendarComponentModel,
  AddUserToSchedulerModel,
  ParameterShiftPeriodsModel,
  ShiftPeriodsItemModelByUserId
} from "@/models";

@Module({ namespaced: true })
class CalendarModule extends VuexModule {
  dates: Date[] = [];
  failureMessage = "";
  showError = false;

  @Mutation
  failure(error: string): void {
    this.failureMessage = error;
    this.showError = true;
  }
  @Mutation
  getShiftsInfoData(data: WeeklyItemModel[]): void {
    console.log("getShiftsInfoData", data);
  }
  @Mutation
  getSchedulerInfoData(data: SchedulerItemModelByUserId[]): void {
    console.log("getSchedulerInfoData", data);
  }
  @Mutation
  getShiftTotalsInfoData(data: ShiftTotalsItemModelByUserId[]): void {
    console.log("getShiftTotalsInfoData", data);
  }
  @Mutation
  getShiftStatisticsInfoData(data: ShiftStatisticsItemModelByUserId[]): void {
    console.log("getShiftStatisticsInfoData", data);
  }
  @Mutation
  getShiftPeriodsInfoData(data: ShiftPeriodsItemModelByUserId[]): void {
    console.log("getShiftPeriodsInfoData", data);
  }
  @Mutation
  getEmployeePositionShiftsInfo(data: CalendarComponentModel[]): void {
    console.log("getEmployeePositionShiftsInfo", data);
  }
  @Mutation
  updateDates(dates: Date[]) {
    this.dates.length = 0;
    this.dates.push(...dates);
  }
  @Mutation
  cleanError() {
    this.failureMessage = "";
    this.showError = false;
  }

  @Action({ rawError: true })
  async loadingShiftsInfoByDateRange(model: DateFilterModel) {
    try {
      const response = await CalendarService.getShiftsInfoByDateRange(model);
      this.context.commit("getShiftsInfoData", response.data);
      return response.data;
    } catch (error) {
      this.context.commit("failure", error);
    }
  }
  @Action({ rawError: true })
  async loadingSchedulerInfoByDateRange(model: ParameterSchedulerModel) {
    try {
      const response = await SchedulerService.getSchedulerInfo(model);
      this.context.commit("getSchedulerInfoData", response.data);
      return response.data;
    } catch (error) {
      this.context.commit("failure", error);
    }
  }
  @Action({ rawError: true })
  async loadingShiftTotalsInfoByDateRange(model: ParameterShiftTotalsModel) {
    try {
      const response = await SchedulerService.getShiftTotalsInfo(model);
      this.context.commit("getShiftTotalsInfoData", response.data);
      return response.data;
    } catch (error) {
      this.context.commit("failure", error);
    }
  }
  @Action({ rawError: true })
  async loadingShiftStatisticsInfoByDateRange(
    model: ParameterShiftStatisticsModel
  ) {
    try {
      const response = await SchedulerService.getShiftStatisticsInfo(model);
      this.context.commit("getShiftStatisticsInfoData", response.data);
      return response.data;
    } catch (error) {
      this.context.commit("failure", error);
    }
  }
  @Action({ rawError: true })
  async loadingShiftPeriodsInfoByDateRange(model: ParameterShiftPeriodsModel) {
    try {
      const response = await SchedulerService.getShiftPeriodsInfo(model);
      this.context.commit("getShiftPeriodsInfoData", response.data);
      return response.data;
    } catch (error) {
      this.context.commit("failure", error);
    }
  }
  @Action({ rawError: true })
  async loadingEmployeePositionShiftsInfoByDateRange(
    model: ParameterCalendarModel
  ) {
    try {
      const response = await CalendarService.getEmployeePositionShiftsInfo(
        model
      );
      this.context.commit("getEmployeePositionShiftsInfo", response.data);
      return response.data;
    } catch (error) {
      this.context.commit("failure", error);
    }
  }
  @Action({ rawError: true })
  async addUserToScheduler(model: AddUserToSchedulerModel) {
    try {
      const response = await CalendarService.departmentScheduleShiftUpsert(
        model
      );
      console.log("departmentScheduleShiftUpsert", response.data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error: ", message);
      this.context.commit("failure", message);
    }
  }
  @Action({ rawError: true })
  changeDates(dates: Date[]) {
    this.context.commit("updateDates", dates);
  }

  get currentDates() {
    return this.dates;
  }
  get isShowError() {
    return this.showError;
  }
}

export default CalendarModule;
