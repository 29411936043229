














































































import { Component, Vue } from "vue-property-decorator";
import { UserListModel } from "@/models";
import { Sort, Filter, Selection, Page } from "@syncfusion/ej2-vue-grids";
import { EmpTemplate, ActionTemplate } from "./template";
import { namespace } from "vuex-class";
const Users = namespace("Users");

@Component({
  provide: {
    grid: [Filter, Selection, Sort, Page]
  }
})
export default class UserListComponent extends Vue {
  @Users.Action
  private loadingUsers!: () => Promise<void>;
  @Users.Getter
  private users!: UserListModel[];

  private filterOptions: object = {
    type: "Menu"
  };
  private filter: object = {
    type: "CheckBox"
  };
  private pageSettings: object = {
    pageSizes: [12, 50, 100, 200],
    pageCount: 4
  };
  private selectionSettings: object = {
    persistSelection: true,
    type: "Multiple",
    checkboxOnly: true
  };
  private loading = false;
  empTemplate() {
    return {
      template: EmpTemplate
    };
  }
  actionTemplate() {
    return {
      template: ActionTemplate
    };
  }
  created() {
    this.initialize();
  }

  private refresh() {
    this.initialize();
  }
  private initialize() {
    // this.users = [];
    this.loading = true;
    this.loadingUsers().then(() => {
      console.log("loadingUsers");
      this.loading = false;
    });
    // for (let i = 0; i < 250; i++) {
    //   this.items.push({
    //     id: i,
    //     name: "name" + i,
    //     username: "username" + i,
    //     email: "email@email." + i,
    //   });
    // }
  }
}
