





import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class ValueTemplate extends Vue {
  private data: object = {};
}
