var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.model)?_c('side-menu-layout',{attrs:{"id":"side-menu"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('a',{staticClass:"link",on:{"click":_vm.employeeDay}},[_vm._v("Schedule Editor")]),_vm._v("> Edit Shift")]},proxy:true},{key:"container",fn:function(){return [_c('ShiftEmployeeCardTemplate',{staticClass:"employee-card",attrs:{"data":_vm.model}}),_c('ValidationObserver',{ref:"valObs",staticClass:"add-shift",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Location:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{attrs:{"dataSource":_vm.locations,"allowFiltering":true,"fields":_vm.dropdownDefaultFields,"enabled":_vm.locations.length > 1},model:{value:(_vm.model.locationId),callback:function ($$v) {_vm.$set(_vm.model, "locationId", $$v)},expression:"model.locationId"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Department","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Department:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{attrs:{"dataSource":_vm.departments,"change":_vm.onDepartmentChange,"allowFiltering":true,"fields":_vm.dropdownDefaultFields,"enabled":_vm.departments.length > 1},model:{value:(_vm.model.departmentId),callback:function ($$v) {_vm.$set(_vm.model, "departmentId", $$v)},expression:"model.departmentId"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Date:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input empl-date"},[_c('ejs-datepicker',{attrs:{"format":'MM/dd/yyyy',"showClearButton":false,"disabled":true},model:{value:(_vm.model.shiftDate),callback:function ($$v) {_vm.$set(_vm.model, "shiftDate", $$v)},expression:"model.shiftDate"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Start Time"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Start Time:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-timepicker',{attrs:{"showClearButton":false,"format":'HH:mm',"step":15},model:{value:(_vm.model.shiftStartDate),callback:function ($$v) {_vm.$set(_vm.model, "shiftStartDate", $$v)},expression:"model.shiftStartDate"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"End Time"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("End Time:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-timepicker',{attrs:{"showClearButton":false,"format":'HH:mm',"step":15},model:{value:(_vm.model.shiftEndDate),callback:function ($$v) {_vm.$set(_vm.model, "shiftEndDate", $$v)},expression:"model.shiftEndDate"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Position:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{attrs:{"dataSource":_vm.positions,"change":_vm.onPositionChange,"allowFiltering":true,"fields":_vm.dropdownDefaultFields,"enabled":_vm.positions.length > 1},model:{value:(_vm.model.memberPositionId),callback:function ($$v) {_vm.$set(_vm.model, "memberPositionId", $$v)},expression:"model.memberPositionId"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label"},[_vm._v("Icon:")]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{ref:"iconDD",attrs:{"dataSource":_vm.icons,"change":_vm.onIconChange,"itemTemplate":_vm.iTemplate,"valueTemplate":_vm.vTemplate,"allowFiltering":true,"cssClass":'icon-drop',"fields":_vm.dropdownDefaultFields,"disabled":_vm.loadingIconRules}})],1)]),(_vm.hasIconRules)?_c('div',{staticClass:"rule-block"},[(_vm.isHoursRequired)?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Hours/Day","rules":_vm.hoursRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Hours/Day:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{attrs:{"dataSource":_vm.dataForIcon.hoursTypes,"allowFiltering":true,"fields":_vm.dropdownDefaultFields},model:{value:(_vm.model.icon.hoursType),callback:function ($$v) {_vm.$set(_vm.model.icon, "hoursType", $$v)},expression:"model.icon.hoursType"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}):_vm._e(),(_vm.showSubType)?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Type","rules":_vm.typeRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Type:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{attrs:{"dataSource":_vm.dataForIcon.userScheduleTypeSubtypes,"allowFiltering":true,"fields":_vm.dropdownDefaultFields},model:{value:(_vm.model.icon.userScheduleTypeSubtype),callback:function ($$v) {_vm.$set(_vm.model.icon, "userScheduleTypeSubtype", $$v)},expression:"model.icon.userScheduleTypeSubtype"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}):_vm._e(),(_vm.showPayCodes)?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label"},[_vm._v("Pay Code:")]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{attrs:{"dataSource":_vm.dataForIcon.payCodeDepartments,"allowFiltering":true,"fields":_vm.dropdownInactiveDepFields},model:{value:(_vm.model.icon.payCodeDepartment),callback:function ($$v) {_vm.$set(_vm.model.icon, "payCodeDepartment", $$v)},expression:"model.icon.payCodeDepartment"}})],1)]):_vm._e(),(_vm.showIncentives)?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label"},[_vm._v("Inactive Deparment:")]),_c('div',{staticClass:"form-input"},[_c('ejs-dropdownlist',{attrs:{"dataSource":_vm.dataForIcon.shiftIncentiveDepartments,"allowFiltering":true,"fields":_vm.dropdownInactiveDepFields},model:{value:(_vm.model.icon.shiftIncentiveDepartment),callback:function ($$v) {_vm.$set(_vm.model.icon, "shiftIncentiveDepartment", $$v)},expression:"model.icon.shiftIncentiveDepartment"}})],1)]):_vm._e(),(_vm.isStartEndRequired)?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Begin Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Begin Time:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-timepicker',{attrs:{"showClearButton":false,"format":'HH:mm',"step":15},model:{value:(_vm.model.icon.beginTime),callback:function ($$v) {_vm.$set(_vm.model.icon, "beginTime", $$v)},expression:"model.icon.beginTime"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}):_vm._e(),(_vm.isStartEndRequired)?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"End Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("End Time:"),_c('sup',[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ejs-timepicker',{attrs:{"showClearButton":false,"format":'HH:mm',"step":15},model:{value:(_vm.model.icon.endTime),callback:function ($$v) {_vm.$set(_vm.model.icon, "endTime", $$v)},expression:"model.icon.endTime"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}):_vm._e(),_c('ValidationProvider',{staticClass:"form-group textarea-block",attrs:{"name":"Note","rules":_vm.noteRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Notes:"),(_vm.isNotesRequired)?_c('sup',[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form-input"},[_c('ejs-textbox',{attrs:{"multiline":true,"cssClass":'multi-notes',"rows":4,"placeHolder":_vm.lblDisplayMessageDesc},model:{value:(_vm.model.icon.notes),callback:function ($$v) {_vm.$set(_vm.model.icon, "notes", $$v)},expression:"model.icon.notes"}}),(errors[0])?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"section"},[_c('div',{staticClass:"post-open"},[_c('ejs-checkbox',{attrs:{"label":"Post Open Shift"},model:{value:(_vm.postOpenShifts),callback:function ($$v) {_vm.postOpenShifts=$$v},expression:"postOpenShifts"}})],1),_c('div',{staticClass:"continue"},[_c('b-button',{staticClass:"add-pos",attrs:{"variant":"link"},on:{"click":_vm.onContinue}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v("Add")])],1)]),_c('div',{staticClass:"actions"}),_c('div',{staticClass:"edit-actions"},[_c('transition-group',{attrs:{"name":"list"}},[_vm._l((_vm.editShifts),function(editShift,index){return [_c('EditShiftCardTemplate',{key:index,staticClass:"shift-card",attrs:{"data":editShift},on:{"remove-card":_vm.onRemove}})]})],2)],1)]}}],null,false,1629343936)})]},proxy:true},{key:"button",fn:function(){return [_c('ejs-button',{staticClass:"green",attrs:{"type":"submit","disabled":_vm.editShifts.length < 1},on:{"click":_vm.onSubmit}},[_vm._v("SAVE")])]},proxy:true}],null,false,3878417970)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }