













import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
import { Header, Navigation } from "./";
import SideBarComponent from "@/common/side-bars/component.vue";
import { GlobalToast } from "@/common";
@Component({
  components: {
    Header,
    Navigation,
    SideBarComponent,
    GlobalToast
  }
})
export default class BaseComponent extends Vue {
  @Auth.Getter
  private isLoggedIn!: boolean;
  created() {
    if (!this.isLoggedIn) {
      this.$router.push("/login");
    }
  }
}
