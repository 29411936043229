




































































































































































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import { BaseSideBarMixin } from "@/mixins";
import { namespace } from "vuex-class";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";
const SideBar = namespace("SideBar");
const ShiftModule = namespace("ShiftModule");
const DepartmentModule = namespace("DepartmentModule");
const Toast = namespace("ToastModule");
import {
  IconDropDownTemplate,
  ShiftCardTemplate,
  ActionCardTemplate,
  StaffActionCardTemplate,
  ShiftEmployeeCardTemplate,
  EditShiftCardTemplate
} from "@/common";
import {
  ChangeEventArgs as ChangeEventArgsDD,
  DropDownList
} from "@syncfusion/ej2-vue-dropdowns";
import {
  DepartmentEmployeeModel,
  ShiftEmployeeUpset,
  ShiftType,
  ShiftTypeRequest,
  ShiftEmployeeDropDownRequest,
  ShiftEmployeeDropDownData,
  DropdownSelectNumber,
  DropdownSelectWithImage,
  ShiftEmployeeEditIconData,
  UserScheduleTypeGroupEnum,
  BaseResponse,
  SideBarType,
  SideMenuItemOptions,
  ToastOptions
} from "@/models";
import {
  ValidationObserver,
  ValidationProvider,
  ValidationObserverInstance
} from "vee-validate";
import {
  createSpinner,
  showSpinner,
  hideSpinner
} from "@syncfusion/ej2-popups";
import moment from "moment";
@Component({
  components: {
    "side-menu-layout": SideMenuLayout,
    ValidationObserver,
    ValidationProvider,
    IconDropDownTemplate,
    ShiftCardTemplate,
    ActionCardTemplate,
    StaffActionCardTemplate,
    ShiftEmployeeCardTemplate,
    EditShiftCardTemplate
  }
})
export default class AddEmployeeShiftComponent extends Mixins(
  BaseSideBarMixin
) {
  iTemplate() {
    return {
      template: IconDropDownTemplate
    };
  }
  vTemplate() {
    return {
      template: IconDropDownTemplate
    };
  }
  @Ref("valObs") readonly valObs!: ValidationObserverInstance;
  @Ref("iconDD") readonly iconDD!: DropDownList;

  @Toast.Action
  private showToast!: (toastOptions: ToastOptions) => void;

  private currentUserId = 0;
  private locations: DropdownSelectNumber[] = [];
  private positions: DropdownSelectNumber[] = [];
  private departments: DropdownSelectNumber[] = [];
  private categories: DropdownSelectNumber[] = [];
  private hours: DropdownSelectNumber[] = [];
  private icons: DropdownSelectWithImage[] = [];
  private dataForIcon: ShiftEmployeeEditIconData = {
    userScheduleTypeSubtypes: [],
    hoursTypes: []
  };
  private dropdownInactiveDepFields = {
    value: "chargeCode",
    text: "departmentDisplayText"
  };
  private loadingShiftType = false;
  private loadingIconRules = false;
  private hasIconRules = false;
  private showIncentives = false;
  private showPayCodes = false;
  private showWorkRule = false;
  private showSubType = false;
  private typeRules = "";
  private isNotesRequired = false;
  private noteRules = "";
  private isHoursRequired = false;
  private hoursRules = "";
  private isStartEndRequired = false;
  private postOpenShifts = false;
  private lblDisplayMessageDesc = "";
  private lblDisplayHoursMessage = "";
  private amount?: number;
  private model: any; // FIX
  private editShifts: any[] = []; // FIX
  private selectIcon!: DropdownSelectWithImage;

  @SideBar.Getter
  private currentActiveMenuItem!: any;

  @DepartmentModule.Getter
  private currentDepartment!: DepartmentEmployeeModel;

  @ShiftModule.Getter
  private isShowError!: boolean;
  @ShiftModule.Action
  private getShiftType!: (model: ShiftTypeRequest) => Promise<ShiftType[]>;
  @ShiftModule.Action
  private getShiftEmployeeDropDownData!: (
    model: ShiftEmployeeDropDownRequest
  ) => Promise<ShiftEmployeeDropDownData>;
  @ShiftModule.Action
  private getShiftEmployeeEditIconData!: (
    model: ShiftEmployeeDropDownRequest
  ) => Promise<ShiftEmployeeEditIconData>;
  @ShiftModule.Action
  private cleanError!: () => Promise<void>;
  @ShiftModule.Action
  private shiftEmployeeUpset!: (
    model: ShiftEmployeeUpset
  ) => Promise<BaseResponse>;

  created() {
    this.currentActiveMenuItem.actions[
      this.currentActiveMenuItem.selected
    ].name = this.currentActiveMenuItem.name;
    /* eslint-disable @typescript-eslint/camelcase */
    this.currentActiveMenuItem.actions[this.currentActiveMenuItem.selected][
      "user_FName"
    ] = this.currentActiveMenuItem["user_FName"];
    /* eslint-disable @typescript-eslint/camelcase */
    this.currentActiveMenuItem.actions[this.currentActiveMenuItem.selected][
      "user_LName"
    ] = this.currentActiveMenuItem["user_LName"];
    this.model = {
      ...this.currentActiveMenuItem.actions[this.currentActiveMenuItem.selected]
    };
  }
  mounted() {
    if (this.valObs) {
      this.valObs.reset();
    }
    const { createSpinner, showSpinner, hideSpinner } = this.$globalLoader;
    createSpinner([
      {
        target: document.getElementById("side-menu") as HTMLElement
      }
    ]);
    this.getDropDownData();
  }

  private onSubmit() {
    //const isValid = await this.valObs.validate();
    console.log("onSubmit", this.valObs);
    // if (!isValid) {
    //   return;
    // }
    const dt = moment(this.model.shiftDate).format("MM/DD/yyyy");

    this.model.shiftBeginTime = moment(this.model.shiftStartDate).format(
      "HH:mm:ss"
    );
    this.model.shiftEndTime = moment(this.model.shiftEndDate).format(
      "HH:mm:ss"
    );

    this.model.shiftStartDate = moment(
      dt + " " + this.model.shiftBeginTime
    ).toDate();
    this.model.shiftEndDate = moment(
      dt + " " + this.model.shiftEndTime
    ).toDate();

    if (
      moment(this.model.shiftStartDate).diff(moment(this.model.shiftEndDate)) >=
      0
    ) {
      this.model.shiftEndDate = moment(this.model.shiftEndDate)
        .add("d", 1)
        .toDate();
    }
    this.model.currentDepartmentName = this.currentDepartment.companyDepartmentName;
    console.log("shiftEmployeeUpset", this.model);
    this.shiftEmployeeUpset(this.model).then(() => {
      console.log("shiftEmployeeUpset response");
      if (this.isShowError) {
        this.cleanError();
        // this.$toasted.show("Error occurred", {
        //   position: "top-right",
        //   theme: "outline",
        //   duration: 5000
        // });
        this.showToast({
          title: "Error",
          content: "Error occurred",
          cssClass: "e-toast-danger"
        });
      } else {
        // this.$toasted.show("Shift Employee save success", {
        //   position: "top-right",
        //   theme: "outline",
        //   duration: 5000
        // });
        this.showToast({
          title: "Success",
          content: "Shift Employee save success",
          cssClass: "e-toast-success"
        });
        this.closeMenu({
          success: true,
          reload: true
        });
      }
    });
  }
  private async onContinue() {
    const isValid = await this.valObs.validate();
    console.log("onContinue", this.valObs);
    if (!isValid) {
      return;
    }
    this.editShifts.push({
      name: this.model.name,
      /* eslint-disable @typescript-eslint/camelcase */
      user_FName: this.model["user_FName"],
      /* eslint-disable @typescript-eslint/camelcase */
      user_LName: this.model["user_LName"],
      location: this.model.locationName,
      department: this.model.departmentName,
      date: this.model.shiftDate,
      startTime: moment(this.model.shiftStartDate).format("HH:mm"),
      endTime: moment(this.model.shiftEndDate).format("HH:mm"),
      position: this.model.memberPosition,
      icon: {
        id: this.icons[(this.$refs.iconDD as any).ej2Instances.index]?.value,
        title: this.icons[(this.$refs.iconDD as any).ej2Instances.index]?.text,
        url: this.icons[(this.$refs.iconDD as any).ej2Instances.index]?.imageUrl
      },
      isOpenShift: this.postOpenShifts
      // TODO: Add more data to object when needed
    });
  }
  private onRemove(index: number) {
    this.editShifts.splice(index, 1);
  }
  private onIconChange(args: ChangeEventArgsDD) {
    this.hasIconRules = false;
    this.checkIfIconExist();
    console.log("onIconChange", args);
    if (args.itemData && args.itemData.value) {
      showSpinner(document.getElementById("side-menu") as HTMLElement);
      const value = parseInt(args.itemData.value.toString(), 10);
      this.selectIcon = {
        value: value,
        text: args.itemData.text as string,
        imageUrl: ""
      };
      if (value == -1) {
        if (this.model.icon) {
          this.model.icon.userScheduleType = null;
          this.model.icon.userScheduleTypeName = "";
        }
        this.iconDD.clear();
        args.cancel = true;
        this.dataForIcon.userScheduleTypeSubtypes = [];
        this.dataForIcon.hoursTypes = [];
        this.dataForIcon.shiftIncentiveDepartments = [];
        this.dataForIcon.payCodeDepartments = [];
        this.dataForIcon.iconRules = undefined;
        this.dataForIcon.reassignCategories = [];
        this.applyIconRules();
        hideSpinner(document.getElementById("side-menu") as HTMLElement);
        return;
      }
      this.loadingIconRules = true;
      if (this.model.icon) {
        this.model.icon.userScheduleType = value;
        this.model.icon.userScheduleTypeName = args.itemData.text;
      }
      this.model.workrule = this.showWorkRule;
      this.model.amount = this.amount;
      this.getShiftEmployeeEditIconData({
        departmentId: this.model.departmentId,
        userScheduleTypeId: value
      })
        .then(res => {
          console.log("responmse dta", res, UserScheduleTypeGroupEnum.Inactive);
          this.dataForIcon.userScheduleTypeSubtypes =
            res.userScheduleTypeSubtypes;
          this.dataForIcon.hoursTypes = res.hoursTypes;
          this.dataForIcon.iconRules = res.iconRules;
          this.dataForIcon.shiftIncentiveDepartments =
            res.shiftIncentiveDepartments;
          this.dataForIcon.payCodeDepartments = res.payCodeDepartments;
          this.dataForIcon.reassignCategories = res.reassignCategories;
          this.applyIconRules();
        })
        .finally(() => {
          this.loadingIconRules = false;
          hideSpinner(document.getElementById("side-menu") as HTMLElement);
        });
    }
  }
  private onDepartmentChange(args: ChangeEventArgsDD) {
    console.log("ondepartmentchange", args);
    // TODO: FIX
  }
  private onPositionChange(args: ChangeEventArgsDD) {
    console.log("onPositionChange", args);
    // TODO: FIX
  }
  private getDropDownData() {
    if (
      this.currentActiveMenuItem !== null &&
      this.currentActiveMenuItem.userid !== null &&
      this.model.departmentId !== undefined &&
      this.model.locationId !== undefined
    ) {
      showSpinner(document.getElementById("side-menu") as HTMLElement);
      this.getShiftEmployeeDropDownData({
        userId: this.currentActiveMenuItem.userId,
        departmentId: this.model.departmentId,
        locationId: this.model.locationId
      }).then(r => {
        console.log("getShiftEmployeeDropDownData: ", r);
        this.positions = [];
        this.locations = [];
        this.departments = [];
        this.icons = [];
        this.positions = r.reassignPositions;
        this.locations = r.reassignLocations;
        this.departments = r.reassignDepartments;
        this.icons.push({
          imageUrl: "",
          value: -1,
          text: ""
        });
        this.icons = [...this.icons, ...r.iconsData];
        hideSpinner(document.getElementById("side-menu") as HTMLElement);
      });
    }
  }
  private employeeDay() {
    this.toggleSideBarMenu({
      type: SideBarType.EmployeeDay,
      item: this.currentActiveMenuItem
    });
  }
  private applyIconRules() {
    if (this.dataForIcon.iconRules) {
      this.showIncentives =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Inactive &&
        (this.dataForIcon.iconRules.isDepartmentRequired ?? false);
      this.showPayCodes =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Paycode &&
        (this.dataForIcon.iconRules.isDepartmentRequired ?? false);
      this.showWorkRule =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
        UserScheduleTypeGroupEnum.Workrule;
      this.isStartEndRequired =
        this.dataForIcon.iconRules.isStartEndTimeRequired ?? false;
      this.isHoursRequired =
        this.dataForIcon.iconRules.isHoursRequired ?? false;
      this.showSubType = this.dataForIcon.userScheduleTypeSubtypes.length > 1;
      this.isNotesRequired =
        this.dataForIcon.iconRules.isNotesRequired ?? false;
      this.lblDisplayMessageDesc = this.dataForIcon.iconRules.messageDesc;
      this.lblDisplayHoursMessage = this.dataForIcon.iconRules.hoursMessage;

      this.amount =
        this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Inactive ||
        (this.dataForIcon.iconRules.userScheduleTypeRecStat ===
          UserScheduleTypeGroupEnum.Paycode &&
          (this.dataForIcon.iconRules.isDepartmentRequired ?? false))
          ? this.dataForIcon.iconRules.amount
          : undefined;

      this.typeRules = this.showSubType ? "required" : "";
      this.hoursRules = this.isHoursRequired ? "required" : "";
      this.noteRules = this.isNotesRequired ? "required" : "";
      if (this.valObs) {
        this.valObs.reset();
      }
      setTimeout(() => {
        this.hasIconRules = true;
      }, 0);
    } else {
      this.hasIconRules = false;
    }
  }
  private checkIfIconExist() {
    if (!this.model.icon) {
      this.model.icon = {
        userScheduleType: null,
        hoursType: undefined,
        payCodeDepartment: undefined,
        shiftIncentiveDepartment: undefined,
        userScheduleTypeSubtype: undefined,
        beginTime: undefined,
        endTime: undefined
      };
    }
  }

  getSideBarType(): SideBarType {
    return SideBarType.ActionScheduler;
  }
}
