













































































































import { Component, Mixins } from "vue-property-decorator";
import actiontemplate from "./templates/action-template.vue";
import {
  Filter,
  Group,
  Selection,
  Sort,
  VirtualScroll
} from "@syncfusion/ej2-vue-grids";
import { namespace } from "vuex-class";
import { SideBarType, SideMenuItem } from "@/models";
import { UspDepartmentAssignmentsByEmployee } from "@/models/employee-profile/employee.response.model";
import { PermissionMixin } from "@/mixins";
import { TooltipPlugin } from "@syncfusion/ej2-vue-popups";
const SideBar = namespace("SideBar");
const Profile = namespace("Profile");
@Component({
  provide: {
    grid: [Sort, Filter, Selection, VirtualScroll, Group]
  },
  components: {
    TooltipPlugin
  }
})
export default class DepartmentAssignmentsComponent extends Mixins(
  PermissionMixin
) {
  testString = "test";
  customAttributes = { class: "customcss" };

  setTooltipContent(text: string) {
    (this.$refs.tooltip as any).content = text;
  }
  @SideBar.Action
  private toggleSideMenu!: (type: SideMenuItem) => void;
  @Profile.Getter
  departmentAssignments!: UspDepartmentAssignmentsByEmployee[];
  actionTemplate() {
    return { template: actiontemplate };
  }
  checkContent(e: any) {
    if (e.target.innerText === "") {
      e.cancel = true;
    }
  }
  groupOptions = {
    showDropArea: false,
    columns: ["locationName", "companyRegionName", "companyServiceLineName"]
  };
  tooltipcontent(args: any) {
    (this.$refs.overviewgrid as any).$el.addEventListener(
      "mouseover",
      (args: any) => {
        if (args.target.closest("td")) {
          (this.$refs.tooltip as any).content = args.target.innerText; // changing the tooltip content with the cell value
        }
      }
    );
  }

  toggleAddDepartmentMenu() {
    this.toggleSideMenu({ type: SideBarType.EditDepartment, item: {} });
  }
}
