import Vue from "vue";
import Vuex from "vuex";

import Auth from "./modules/auth.module";
import User from "./modules/user";
import Users from "./modules/user.module";
import TaskHub from "./modules/hubs/task.module";
import CalendarModule from "./modules/calendar.module";
import DepartmentModule from "./modules/department.module";
import SideBar from "./modules/sidebar.module";
import Profile from "./modules/profile.module";
import ShiftModule from "./modules/shift.module";
import StaffModule from "./modules/staff.module";
import ToastModule from "./modules/toast.module";
import CalendarFilterModule from "./modules/calendar.filter.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Auth,
    User,
    Users,
    CalendarModule,
    TaskHub,
    SideBar,
    DepartmentModule,
    Profile,
    ShiftModule,
    CalendarFilterModule,
    StaffModule,
    ToastModule
  }
});
