import { RouteConfig } from "vue-router";
import { BaseComponent } from "./";
import { DEFAULT_SCREEN_ROUTES } from "@/components/defaultScreen";
import { Home } from "@/components/home";
import { Calendar } from "@/components/calendar";
import { WEEKLY_ROUTES } from "@/components/calendar/weekly/routes";
import { MONTH_ROUTES } from "@/components/calendar/month/routes";
import { DAY_ROUTES } from "@/components/calendar/day/routes";
import { USER_ROUTES } from "@/components/user/routes";
import { PROFILE_ROUTES } from "@/components/employee-profile/routes";
import { SCHEDULER_ROUTES } from "@/components/calendar/scheduler/routes";
import { STAFF_ROUTES } from "@/components/staff-search/routes";

const BASE_ROUTES: RouteConfig[] = [
  {
    path: "",
    name: "base",
    component: BaseComponent,
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home
      },
      {
        path: "/calendar",
        name: "Calendar",
        component: Calendar
      },
      ...MONTH_ROUTES,
      ...WEEKLY_ROUTES,
      ...DAY_ROUTES,
      ...PROFILE_ROUTES,
      ...SCHEDULER_ROUTES,
      ...STAFF_ROUTES,
      ...USER_ROUTES,
      // this is default route!!! strictly last!!!
      ...DEFAULT_SCREEN_ROUTES
    ]
  }
];

export default BASE_ROUTES;
