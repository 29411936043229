



































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { BaseSideBarMixin } from "@/mixins";
import { namespace } from "vuex-class";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";
const SideBar = namespace("SideBar");
const ShiftModule = namespace("ShiftModule");
const CalendarModule = namespace("CalendarModule");
const DepartmentModule = namespace("DepartmentModule");
const Toast = namespace("ToastModule");
import {
  StaffActionCardTemplate,
  CalendarTemplateComponent,
  IconDropDownTemplate
} from "@/common";
import {
  ChangeEventArgs as ChangeEventArgsDD,
  DropDownList
} from "@syncfusion/ej2-vue-dropdowns";
import {
  DepartmentEmployeeModel,
  SchedulerActionModel,
  BaseResponse,
  SideBarType,
  SideMenuItemOptions,
  DropdownSelectWithImage,
  ShiftEmployeeDropDownRequest,
  ShiftEmployeeDropDownData,
  ShiftEmployeeEditIconData,
  UserScheduleTypeGroupEnum,
  IconItemUpset,
  ToastOptions
} from "@/models";
import {
  ValidationObserver,
  ValidationProvider,
  ValidationObserverInstance
} from "vee-validate";
import {
  createSpinner,
  showSpinner,
  hideSpinner
} from "@syncfusion/ej2-popups";
import moment from "moment";
@Component({
  components: {
    "side-menu-layout": SideMenuLayout,
    ValidationObserver,
    ValidationProvider,
    StaffActionCardTemplate,
    CalendarTemplateComponent,
    IconDropDownTemplate
  }
})
export default class ApprovalEmployeeIconComponent extends Mixins(BaseSideBarMixin) {
  iTemplate() {
    return {
      template: IconDropDownTemplate
    };
  }
  vTemplate() {
    return {
      template: IconDropDownTemplate
    };
  }
  @Ref("valObs") readonly valObs!: ValidationObserverInstance;
  @Ref("iconDD") readonly iconDD!: DropDownList;

  private icons: DropdownSelectWithImage[] = [];
  private specialDates: Record<string, any>[] = []; // fix
  private selectIcon!: DropdownSelectWithImage;
  private icon?: IconItemUpset;
  private approvalId = 0;

  private approval: object[] = [
    { key: "Approve", id: 0 },
    { key: "Deny", id: 1 }
  ];
  private fieldsCust: object = {
    text: "key",
    value: "id"
  };

  @Toast.Action
  private showToast!: (toastOptions: ToastOptions) => void;

  @SideBar.Getter
  private currentActiveMenuItem!: any; // fix

  @CalendarModule.Getter
  private isShowError!: boolean;
  @CalendarModule.Action
  private cleanError!: () => Promise<void>;

  created() {
    const selected = this.currentActiveMenuItem?.selected ?? -1;
    const icon = this.currentActiveMenuItem.actions[selected] as any;
    this.icons.push({
      imageUrl: icon.url,
      value: icon.userScheduleType,
      text: icon.title
    });
    this.icon = {
      userScheduleType: icon.userScheduleType,
      notes: icon.userScheduleNotes,
      beginTime: icon.scheduleBeginTime,
      endTime: icon.scheduleEndTime,
      hoursType: icon.userScheduleHours,
      userScheduleTypeSubtype: icon.userSubtype,
      scheduleDate: icon.scheduleDate
    };
  }
  mounted() {
    if (this.valObs) {
      this.valObs.reset();
    }
    const { createSpinner, showSpinner, hideSpinner } = this.$globalLoader;
    createSpinner([
      {
        target: document.getElementById("side-menu") as HTMLElement
      }
    ]);
  }
 
  private async onSubmit() {
    // fix
    console.log("on submit");
    const isValid = await this.valObs.validate();
    if (!isValid) {
      return;
    }
  }
  private employeeDay() {
    this.toggleSideBarMenu({
      type: SideBarType.EmployeeDay,
      item: this.currentActiveMenuItem
    });
  }
}
