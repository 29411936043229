import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { SideBarType, SideMenuItem, SideMenuItemOptions } from "@/models";

@Module({ namespaced: true })
class SidebarModule extends VuexModule {
  activeMenu: SideBarType = SideBarType.None;
  item = {};
  subItem?: object = {};
  options?: SideMenuItemOptions = {};
  @Mutation
  updateSideMenuState(newState: SideMenuItem) {
    console.log("updateSideMenuState", newState);
    this.options = newState.options;
    this.item = newState.item;
    this.subItem = newState.subItem;
    setTimeout(() => {
      this.activeMenu = newState.type;
    }, 1);
  }

  @Mutation
  updateSideMenuStateOptions(newState: SideMenuItemOptions) {
    console.log("updateSideMenuStateOptions", newState);
    this.options = newState;
  }

  @Action({ rawError: true })
  updateSideMenuOptions(type: SideMenuItemOptions) {
    this.context.commit("updateSideMenuStateOptions", type);
  }

  @Action({ rawError: true })
  resetState() {
    this.context.commit("updateSideMenuState", {
      type: SideBarType.None,
      item: {},
      subItem: {},
      options: {}
    });
  }

  @Action({ rawError: true })
  toggleSideMenu(type: SideMenuItem) {
    this.context.commit("updateSideMenuState", type);
  }

  get currentActiveMenu() {
    return this.activeMenu;
  }
  get currentActiveMenuItem() {
    return this.item;
  }
  get currentActiveMenuOptions() {
    return this.options;
  }
  get currentSubItem() {
    return this.subItem;
  }
}

export default SidebarModule;
