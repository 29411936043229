


















































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import SideMenuLayout from "@/common/side-bars/template/side-menu-layout.vue";
import { namespace } from "vuex-class";
import { UspDepartmentAssignmentsByEmployee } from "@/models/employee-profile/employee.response.model";
import ProfileService from "@/services/ProfileService";

const Profile = namespace("Profile");

@Component({
  components: {
    "side-menu-layout": SideMenuLayout
  }
})
export default class EditDepartmentComponent extends Vue {
  @Profile.Getter
  private departmentAssignments!: UspDepartmentAssignmentsByEmployee[];
  @Profile.Getter
  private currentUserId!: number;
  @Profile.Action
  insertDeptAssignment!: (departmentId: number) => void;
  @Profile.Action
  private addDepartmentGroupToUser!: (deptGroupId: number) => void;
  @Ref() unassignedDept!: HTMLElement;
  @Ref() assignedDept!: HTMLElement;
  assignedDepartments = {
    fields: [
      {
        key: "serviceLine",
        label: "Service Line",
        class: "text-center"
      },
      { key: "department", label: "Department", class: "text-center" }
    ],
    items: [
      {
        serviceLine: "Nursing",
        department: "MSICU"
      }
    ]
  };

  unassignedDepartments = {
    fields: [
      {
        key: "serviceLine",
        label: "Service Line",
        class: "text-center"
      },
      {
        key: "department",
        label: "Department",
        class: "text-center"
      },
      { key: "actions", label: "Actions", class: "text-center" }
    ],
    items: [{}]
  };
  departmentAssignmentGroups = {
    fields: [
      { key: "groupName", label: "Group", class: "text-center" },
      { key: "actions", label: "Actions", class: "text-center" }
    ],
    items: [{}]
  };

  addNewDeptAssignment(data: any) {
    this.insertDeptAssignment(data.item.departmentId);
  }

  addNewDeptGroup(data: any) {
    this.addDepartmentGroupToUser(data.item.groupId);
  }

  @Watch("departmentAssignments", { deep: true })
  UpdateTableOnChange() {
    this.loadDepartments();
  }
  mounted() {
    this.loadDepartments();
  }

  async loadDepartments() {
    const { createSpinner, showSpinner, hideSpinner } = this.$globalLoader;
    createSpinner([
      {
        target: this.unassignedDept
      },
      {
        target: this.assignedDept
      }
    ]);
    showSpinner([this.assignedDept, this.unassignedDept]);
    this.assignedDepartments.items = this.departmentAssignments.map(item => {
      return {
        serviceLine: item.companyServiceLineName ?? "-",
        department: item.companyDepartmentName ?? "-"
      };
    });
    ProfileService.getUnassignedDepartmentsByUserId(this.currentUserId).then(
      resp => {
        this.unassignedDepartments.items = resp.data.map(item => {
          return {
            serviceLine: item.companyServiceLineName ?? "-",
            department: item.companyDepartmentName ?? "-",
            departmentId: item.companyDepartmentId
          };
        });
        hideSpinner(this.unassignedDept);
      }
    );

    ProfileService.getDepartmentAssignmentGroupsByUserId(
      this.currentUserId
    ).then(resp => {
      this.departmentAssignmentGroups.items = resp.data.map(item => {
        return {
          groupName: item.departmentAssignmentGroupName,
          groupId: item.departmentAssignmentGroupId
        };
      });
      hideSpinner(this.assignedDept);
    });
  }
}
