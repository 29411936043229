
























import { Component, Vue, Watch, Prop, Ref } from "vue-property-decorator";
import {
  FilterModel,
  FilterBase,
  FilterByEnum,
  FilterProperties
} from "@/models";
import FilterBySelector from "@/common/filter/template/filterBy/filterBySelector.vue";
import FilterByIntegerRange from "@/common/filter/template/filterBy/filterByIntegerRange.vue";
import FilterByBoolean from "@/common/filter/template/filterBy/filterByBoolean.vue";
import FilterByTimeRange from "@/common/filter/template/filterBy/filterByTimeRange.vue";
@Component({
  components: {
    FilterBySelector,
    FilterByIntegerRange,
    FilterByBoolean,
    FilterByTimeRange
  }
})
export default class FilterByWrapperComponent extends Vue
  implements FilterBase {
  @Ref("filterBy") readonly filterBy!: FilterBase;
  @Prop() filter!: FilterModel;
  @Prop() data!: FilterProperties;

  FilterByEnum = FilterByEnum;

  @Watch("data", { immediate: true, deep: true })
  changefilter() {
    console.log("filter By data wrapper", this.data);
  }

  validate() {
    console.log("filter by wrapper validate");
  }
  collectValue(): FilterProperties | undefined {
    if (this.filterBy) {
      const values = this.filterBy.collectValue();
      if (values) {
        console.log("collectValue ", values);
        return values;
      }
    }
    return undefined;
  }
  clearValue() {
    this.filterBy.clearValue();
  }
}
