








import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { FilterBase, FilterModel, FilterProperties } from "@/models";
@Component({ name: "FilterByBoolean" })
export default class FilterByBooleanComponent extends Vue
  implements FilterBase {
  @Prop() filter!: FilterModel;
  @Prop() data!: FilterProperties;

  selected = false;

  @Watch("data", { immediate: true, deep: true })
  changeData() {
    this.setDefaultValue();
  }

  @Watch("filter", { immediate: true, deep: true })
  changeFilter() {
    this.setDefaultValue();
  }

  validate() {
    console.log("integer range by validate");
  }
  collectValue(): FilterProperties | undefined {
    console.log("integer range by collectValue", this.selected);
    if (!this.selected) {
      return undefined;
    }
    return {
      startIntValue: this.selected ? 1 : 0,
      key: this.data.key
    } as FilterProperties;
  }

  clearValue() {
    this.selected = false;
  }

  numberOnly(value: number | undefined) {
    if (!value || (value && !value.toString().match(/^\d+$/))) {
      return undefined;
    } else {
      return parseInt(value.toString(), 10);
    }
  }

  setDefaultValue() {
    if (this.data && this.filter && this.filter.filterBy.length) {
      const val = this._.find(this.filter.filterBy, f => {
        return f.key == this.data.key;
      });
      console.log("setDefaultValue", val);
      if (val) {
        this.selected = val.startIntValue === 1;
      } else {
        this.selected = false;
      }
    }
  }
}
