








import { Component, Vue, Watch } from "vue-property-decorator";
import { DropdownSelectWithImage } from "@/models";
@Component
export default class IconDropDownComponent extends Vue {
  data: DropdownSelectWithImage = {
    imageUrl: "",
    value: 0,
    text: ""
  };
  @Watch("data") changeData() {
    console.log("data", this.data);
  }
}
