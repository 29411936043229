import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import StaffService from "@/services/StaffService";
import { StaffToShiftRequest, AddStaffToShiftRequest } from "@/models";

@Module({ namespaced: true })
export default class StaffModule extends VuexModule {
  failureMessage = "";
  showError = false;

  get isShowError() {
    return this.showError;
  }

  @Mutation
  failure(error: string): void {
    this.failureMessage = error;
    this.showError = true;
  }

  @Action({ rawError: true })
  cleanError() {
    this.failureMessage = "";
    this.showError = false;
  }

  @Action({ rawError: true })
  async getStaffToShift(model: StaffToShiftRequest) {
    try {
      const response = await StaffService.getStaffToShift(model);
      console.log("getStaffToShift", response);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error getStaffToShift: ", message);
      this.context.commit("failure", message);
    }
    return [];
  }

  @Action({ rawError: true })
  async addStaffToShift(model: AddStaffToShiftRequest) {
    try {
      const response = await StaffService.addStaffToShift(model);
      console.log("addStaffToShift", response);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log("error addStaffToShift: ", message);
      this.context.commit("failure", message);
    }
  }
}
