




































































































































































































import { DepartmentSettingsDropdownsData } from "@/models/employee-profile/employee.response.model";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import ProfileService from "@/services/ProfileService";
import { SettingsSchedulerModel } from "@/models";
import { namespace } from "vuex-class";
import {
  createSpinner,
  showSpinner,
  hideSpinner
} from "@syncfusion/ej2-popups";
const CalendarFilterModule = namespace("CalendarFilterModule");
const DepartmentModule = namespace("DepartmentModule");
@Component
export default class SchedulerFilterComponent extends Vue {
  @Prop() data!: SettingsSchedulerModel;
  @Prop() sourceData!: any[]; // FIX

  private modes: object[] = [
    { key: "Schedule Shifts and Primary Staff", id: 0 },
    { key: "Filter Only Primary Staff Schedules", id: 1 },
    { key: "Filter Only Secondary Staff Schedules", id: 2 },
    { key: "View All Primary and Secondary Staff Schedules", id: 3 }
  ];
  private dropdownData: any = {
    // fix
    shiftData: [],
    positionData: [],
    titleData: [],
    categoryData: [],
    fteData: [],
    periodData: []
  };
  private weeks: object[] = [
    { key: "1 Week", id: 1 },
    { key: "2 Weeks", id: 2 },
    { key: "3 Weeks", id: 3 },
    { key: "4 Weeks", id: 4 },
    { key: "5 Weeks", id: 5 },
    { key: "6 Weeks", id: 6 },
    { key: "7 Weeks", id: 7 },
    { key: "8 Weeks", id: 8 }
  ];
  private times: object[] = [
    { key: "12A", id: 0 },
    { key: "1A", id: 1 },
    { key: "2A", id: 2 },
    { key: "3A", id: 3 },
    { key: "4A", id: 4 },
    { key: "5A", id: 5 },
    { key: "6A", id: 6 },
    { key: "7A", id: 7 },
    { key: "8A", id: 8 },
    { key: "9A", id: 9 },
    { key: "10A", id: 10 },
    { key: "11A", id: 11 },
    { key: "12P", id: 12 },
    { key: "1P", id: 13 },
    { key: "2P", id: 14 },
    { key: "3P", id: 15 },
    { key: "4P", id: 16 },
    { key: "5P", id: 17 },
    { key: "6P", id: 18 },
    { key: "7P", id: 19 },
    { key: "8P", id: 20 },
    { key: "9P", id: 21 },
    { key: "10P", id: 22 },
    { key: "11P", id: 23 }
  ];
  private hours: object[] = [
    { key: "2 Hours", id: 2 },
    { key: "4 Hours", id: 4 },
    { key: "6 Hours", id: 6 },
    { key: "8 Hours", id: 8 },
    { key: "12 Hours", id: 12 }
  ];
  private columns: object[] = [
    { key: "FTE", id: "FTE" },
    { key: "Title", id: "Title" },
    { key: "Category", id: "Category" },
    { key: "Seniority", id: "Seniority" },
    { key: "Hours", id: "Hours" }
  ];
  private displays: object[] = [
    { key: "Shift", id: 0 },
    { key: "w/ Position", id: 1 },
    { key: "Shift Times", id: 2 },
    { key: "Team", id: 3 }
  ];
  private height = "200px";
  private viewStatistics = false;
  private viewPeriods = false;
  private viewTotals = false;
  private fieldsCust: object = {
    text: "key",
    value: "id"
  };
  private loaded = false;

  @DepartmentModule.Getter
  currentDepartmentId!: number;

  @Watch("sourceData", { immediate: true })
  updateDropdowns() {
    const positions = [] as any;
    const periods = [] as any;
    const shifts = [] as any;
    this.sourceData.forEach(function(employee: any) {
      Object.keys(employee.schedules).forEach(function(key) {
        if (employee.schedules[key][0].type === "shift") {
          if (!positions.includes(employee.schedules[key][0].position)) {
            positions.push(employee.schedules[key][0].position);
          }
          if (!shifts.includes(employee.schedules[key][0].value)) {
            shifts.push(employee.schedules[key][0].value);
          }
          if (
            !periods.some(
              (a: any) => a.id === employee.schedules[key][0].positionId
            ) &&
            true === employee.schedules[key][0].isShiftPosition
          ) {
            periods.push({
              key: employee.schedules[key][0].position,
              id: employee.schedules[key][0].positionId
            });
          }
        }
      });
    });
    this.dropdownData.categoryData = [
      ...new Set(
        this.sourceData.flatMap(a => (a.category !== "" ? [a.category] : []))
      )
    ];
    this.dropdownData.titleData = [
      ...new Set(
        this.sourceData.flatMap(a => (a.title !== "" ? [a.title] : []))
      )
    ];
    this.dropdownData.fteData = [
      ...new Set(this.sourceData.flatMap(a => (a.fte !== "" ? [a.fte] : [])))
    ];
    this.dropdownData.shiftData = [...shifts];
    this.dropdownData.positionData = [...positions];
    this.dropdownData.periodData = [...periods];
  }

  get selectTotals() {
    return this.data.viewTotals || this.viewTotals;
  }
  get selectStatistics() {
    return this.data.viewStatistics || this.viewStatistics;
  }
  get selectPeriods() {
    return this.data.viewPeriods || this.viewPeriods;
  }

  private onTotalsChange(e: any) {
    if (e.checked == false && this.data) {
      this.data.viewTotalsTop = false;
    }
    this.viewTotals = e.checked;
  }
  private onStatisticsChange(e: any) {
    if (e.checked == false && this.data) {
      this.data.viewStatisticsTop = false;
    }
    this.viewStatistics = e.checked;
  }
  private onPeriodsChange(e: any) {
    if (e.checked == false && this.data) {
      this.data.viewPeriodsTop = false;
    }
    this.viewPeriods = e.checked;
  }
}
