













































































































































































































import { Component, Mixins, Watch, Vue } from "vue-property-decorator";
import {
  createSpinner,
  showSpinner,
  hideSpinner
} from "@syncfusion/ej2-popups";
import { HeaderFilter } from "@/common";
import { SchedulerTotals } from "./totals";
import { SchedulerStatistics } from "./statistics";
import { SchedulerPeriods } from "./periods";
import { BaseCalendarMixin } from "@/mixins";
import { namespace } from "vuex-class";
const SideBar = namespace("SideBar");
const CalendarModule = namespace("CalendarModule");
const DepartmentModule = namespace("DepartmentModule");
const CalendarFilterModule = namespace("CalendarFilterModule");
const Toast = namespace("ToastModule");
import {
  SideBarType,
  SideMenuItem,
  SettingsSchedulerModel,
  SchedulerItemModelByUserId,
  ParameterSchedulerModel,
  SchedulerModel,
  WeeklyItemModel,
  SchedulerActionModel,
  SideMenuItemOptions,
  ToastOptions
} from "@/models";
import moment from "moment";
import { ResizeObserver } from "@juggle/resize-observer";

@Component({
  components: {
    HeaderFilter,
    SchedulerTotals,
    SchedulerStatistics,
    SchedulerPeriods
  }
})
export default class ScheduleComponent extends Mixins(BaseCalendarMixin) {
  private observer: ResizeObserver | null = null;
  private selectedDate = new Date();
  private mode = 0;
  private noOfWeeks = 4;
  private payPeriodDay = 5; // 1=Monday, 7=Sunday, etc.
  private payPeriodWeeks = 2; // 1=weekly, 2=bi-weekly, etc.
  private display = 0;
  private rowIndex = -1;
  private isOpened = false; /// fix
  private onReload = false;
  private isLoading = false;
  private showIcons = true;
  private showDepartments = true;
  private noOfRows = 21;
  private noOfTotals = 0;
  private noOfStatistics = 0;
  private noOfPeriods = 0;
  private viewTotals = "none";
  private viewStatistics = "none";
  private viewPeriods = "none";
  private search = "";
  private selectedCategories: Array<string> = [];
  private selectedPositions: Array<string> = [];
  private selectedTitles: Array<string> = [];
  private selectedShifts: Array<string> = [];
  private hideColumns: Array<string> = [];
  private selectedFtes: Array<number> = [];
  private employees: Array<SchedulerModel> = [];
  private sort = {
    key: "",
    isAsc: false
  };

  @Toast.Action
  private showToast!: (toastOptions: ToastOptions) => void;

  @CalendarModule.Action
  private loadingSchedulerInfoByDateRange!: (
    model: ParameterSchedulerModel
  ) => Promise<SchedulerItemModelByUserId[]>;

  @CalendarFilterModule.Getter
  currentSchedulerFilter!: SettingsSchedulerModel;
  @CalendarFilterModule.Getter
  currentNoOfTotals!: number;
  @CalendarFilterModule.Getter
  currentNoOfStatistics!: number;
  @CalendarFilterModule.Getter
  currentNoOfPeriods!: number;

  @DepartmentModule.Getter
  currentDepartmentId!: number;

  @SideBar.Action
  private toggleSideMenu!: (type: SideMenuItem) => void;

  @SideBar.Getter
  private currentActiveMenuItem!: object;
  @SideBar.Getter
  private currentActiveMenuOptions!: SideMenuItemOptions;

  @CalendarModule.Getter
  private currentDates!: Date[];
  @CalendarModule.Action
  changeDates!: (dates: Date[]) => void;

  @Watch("currentDepartmentId")
  @Watch("selectedDate")
  @Watch("noOfWeeks")
  @Watch("mode")
  onChange() {
    if (!this.isLoading) {
      this.reload();
    }
  }
  @Watch("currentSchedulerFilter", { immediate: true })
  changeFilterModel() {             
    this.applyFilters();
  }
  @Watch("currentActiveMenuItem")
  onActiveMenuItemChange() {
    if (this._.isEmpty(this.currentActiveMenuItem)) {
      this.rowIndex = -1;
      this.isOpened = false;
      this.changeDates([]);
    }
  }
  @Watch("currentActiveMenuOptions")
  onCurrentActiveMenuChange() {
    if (
      this.currentActiveMenuOptions &&
      this.currentActiveMenuOptions.success &&
      this.currentActiveMenuOptions.reload
    ) {
      this.reload();
    }
  }
  @Watch("currentNoOfTotals")
  onCurrentNoOfTotalsChange() {
    if (this.currentNoOfTotals < this.noOfRows) {
      this.noOfTotals = this.currentNoOfTotals;
      this.noOfRows -= this.currentNoOfTotals;
    } else {
      this.noOfRows += this.noOfTotals;
      this.showToast({
        title: "Displaying Shift Totals",
        content: "Cannot display shift totals on top due to the number of rows.",
        cssClass: "e-toast-info",
        position: { X: "Center", Y: "Top" }
      });
    }
    this.applyFilters();
  }
  @Watch("currentNoOfStatistics")
  onCurrentNoOfStatisticsChange() {
    if (this.currentNoOfStatistics < this.noOfRows) {
      this.noOfStatistics = this.currentNoOfStatistics;
      this.noOfRows -= this.currentNoOfStatistics;
    } else {
      this.noOfRows += this.noOfStatistics;
      this.showToast({
        title: "Displaying Shift Statistics",
        content: "Cannot display shift statistics on top due to the number of rows.",
        cssClass: "e-toast-info",
        position: { X: "Center", Y: "Top" }
      });
    }
    this.applyFilters();
  }
  @Watch("currentNoOfPeriods")
  onCurrentNoOfPeriodsChange() {
    if (this.currentNoOfPeriods < this.noOfRows) {
      this.noOfPeriods = this.currentNoOfPeriods;
      this.noOfRows -= this.currentNoOfPeriods;
    } else {
      this.noOfRows += this.currentNoOfPeriods;
      this.showToast({
        title: "Displaying Shift Periods",
        content: "Cannot display shift periods on top due to the number of rows.",
        cssClass: "e-toast-info",
        position: { X: "Center", Y: "Top" }
      });
    }
    this.applyFilters();
  }

  mounted() {
    const { createSpinner, showSpinner, hideSpinner } = this.$globalLoader;
    createSpinner([
      {
        target: document.getElementById("schedule") as HTMLElement
      }
    ]);
    this.type = "scheduler";
    const ro = new ResizeObserver((entries, observer) => {
      this.observer = observer as ResizeObserver;
      if (this.$refs.tableScrollable != undefined) {
        this.freezeTable();
      }
    });
    ro.observe(this.$refs.tableScrollable as Element);
  }
  updated() {
    this.observer?.disconnect();
    const ro = new ResizeObserver((entries, observer) => {
      this.observer = observer as ResizeObserver;
      if (this.$refs.tableScrollable != undefined) {
        this.freezeTable();
      }
    });
    ro.observe(this.$refs.tableScrollable as Element);
  }
  destroyed() {
    this.observer?.disconnect();
  }

  get TotalsTop(): boolean {
    return this.currentNoOfTotals < this.noOfRows;
  }
  get StatisticsTop(): boolean {
    return this.currentNoOfStatistics < this.noOfRows;
  }
  get PeriodsTop(): boolean {
    return this.currentNoOfPeriods < this.noOfRows;
  }
  get NoOfColumns(): number {
    return (
      5 -
      (this.hideColumns.includes("Hours")
        ? this.hideColumns.length - 1
        : this.hideColumns.length)
    );
  }
  get Columns(): Array<number | string> {
    const columns = [];
    let counter = 1;
    for (let i = 0; i < this.noOfWeeks * 7; i++) {
      const date = moment(this.selectedDate).add(i, "days");
      if (
        date.format("dddd") ===
        moment()
          .isoWeekday(this.payPeriodDay)
          .format("dddd")
      ) {
        columns.push(counter++);
      }
      columns.push(date.format("YYYY-MM-DD"));
    }
    return columns;
  }
  get Months(): Array<object> {
    const months = [];
    let currentMonth = "";
    for (let i = 0; i < this.noOfWeeks * 7; i++) {
      const date = moment(this.selectedDate).add(i, "days");
      if (date.format("MMMM") !== currentMonth) {
        months.push({ month: date.format("MMMM"), columns: 1 });
        currentMonth = date.format("MMMM");
      } else {
        months[months.length - 1].columns += 1;
      }
      if (
        date.format("dddd") ===
          moment()
            .isoWeekday(this.payPeriodDay)
            .format("dddd") &&
        !this.isHidden("Hours")
      ) {
        months[months.length - 1].columns += 1;
      }
    }
    return months;
  }
  get sortedItems(): Array<object> {
    const list = this.employees?.slice();
    if (this.sort.key) {
      list.sort((a, b) => {
        a = (a as any)[this.sort.key];
        b = (b as any)[this.sort.key];
        return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1);
      });
    }
    return list?.filter(employee => {
      return (
        (this.selectedTitles.length === 0 ||
          this.selectedTitles.includes(employee.title)) &&
        (this.selectedCategories.length === 0 ||
          this.selectedCategories.includes(employee.category)) &&
        (this.selectedFtes.length === 0 ||
          this.selectedFtes.includes(employee.fte)) &&
        (this.selectedShifts.length === 0 ||
          this.filter(employee, this.selectedShifts, "shift")) &&
        (this.selectedPositions.length === 0 ||
          this.filter(employee, this.selectedPositions, "position")) &&
        employee.name.toLowerCase().includes(this.search.toLowerCase())
      );
    });
  }
  private displayShifts(schedule: any): string | null {
    if (
      (this.selectedShifts.length === 0 ||
        this.selectedShifts.includes(schedule.value)) &&
      (this.selectedPositions.length === 0 ||
        this.selectedPositions.includes(schedule.position)) &&
      (this.showDepartments === false ||
        schedule.departmentId === this.currentDepartmentId)
    ) {
      const marker =
        schedule.departmentId !== this.currentDepartmentId ? "*" : "";
      switch (this.currentSchedulerFilter.display) {
        case 0:
          return `${marker}${schedule.value}`;
        case 1:
          return `${marker}${schedule.value} (${schedule.position})`;
        case 2:
          return `${marker}${schedule.shiftBeginTime}-${schedule.shiftEndTime}`;
        // add for team
        default:
          return null;
      }
    }
    return null;
  }
  private randomColor(name: string): string {
    let sum = 0;
    for (let i = 0 ; i < name.length ; i++) {
      sum += name.charCodeAt(i);
    }
    return this.colorArray[sum % this.colorArray.length];
  }
  private isDate(value: string | number): boolean {
    return moment(value, "YYYY-MM-DD", true).isValid();
  }
  private isHidden(column: string) {
    return this.hideColumns.includes(column);
  }
  private cellClick(index: number, name: string) {
    if (this.isOpened) {
      return;
    }
    const employee = this.employees.find(
      employee => employee.name === name
    ) as any;
    this.rowIndex = this.employees.findIndex(
      employee => employee.name === name
    );
    this.isOpened = true;
    const schedules = employee?.schedules[this.Columns[index]] ?? [];
    const actions: any[] = [];
    schedules.forEach(function(schedule: any) {
      if (schedule.type == "shift") {
        actions.push({
          shiftType: schedule.value,
          type: "shift",
          shiftId: schedule.shiftId,
          shiftTypeId: schedule.shiftTypeId,
          memberPosition: schedule.position,
          numberOfEmpHired: schedule.numberOfEmpHired,
          numberOfEmpReq: schedule.numberOfEmpReq,
          locationId: schedule.locationId,
          departmentId: schedule.departmentId,
          locationName: "Hartford",
          departmentName: "CICU/CDU",
          shiftDate: schedule.shiftDate,
          memberPositionId: schedule.positionId,
          shiftStartDate: moment(schedule.shiftStartDate).toDate(),
          shiftEndDate: moment(schedule.shiftEndDate).toDate()
        });
      }
      if (schedule.type == "icon") {
        actions.push({
          shiftType: schedule.value,
          type: "icon",
          userScheduleHours: schedule.hourType == 0 ? "" : schedule.hourType,
          userScheduleNotes: schedule.notes,
          scheduleBeginTime: schedule.scheduleBeginTime,
          scheduleEndTime: schedule.scheduleEndTime,
          url: schedule.value,
          title: schedule.name,
          scheduleDate: schedule.shiftDate,
          userScheduleType: schedule.userScheduleid,
          userSubtype: schedule.userSubtype
        });
      }
    });
    this.toggleSideMenu({
      type: SideBarType.EmployeeDay,
      item: {
        /* eslint-disable @typescript-eslint/camelcase */
        user_FName: employee.fname,
        /* eslint-disable @typescript-eslint/camelcase */
        user_LName: employee.lname,
        name: employee.name,
        userId: employee.userId,
        selected: -1,
        date: moment(this.Columns[index]).format("M/D/YYYY"),
        selectedDate: moment(this.Columns[index]).format("M/D/YYYY"),
        startDate: moment(this.selectedDate)
          .startOf("day")
          .toDate(),
        endDate: moment(this.selectedDate)
          .add(this.noOfWeeks, "w")
          .add(-1, "d")
          .endOf("day")
          .toDate(),
        total: 40,
        overtime: 20,
        ftePercent: 90,
        actions: actions
      }
    });
  }
  private getBodyCSS(
    index: number,
    name: string,
    rowIndex: number,
    length: number,
    main: boolean
  ): Array<string> {
    const dates = this.currentDates.map(date =>
      moment(date).format("YYYY-MM-DD")
    );
    const columns = this.Columns;
    const classes = ["text-center", "font-10"];
    if (
      this.rowIndex ===
        this.employees.findIndex(employee => employee.name === name) &&
      dates.includes(moment(this.Columns[index]).format("YYYY-MM-DD")) &&
      main === true
    ) {
      classes.push("selected");
    }
    if (index % 2 === 0) {
      classes.push("lightgray");
    }
    if (
      index > 0 &&
      !this.isDate(columns[index - 1]) &&
      (columns[index - 1] as number) % this.payPeriodWeeks === 0
    ) {
      classes.push("payroll");
    }
    if (columns[index] === moment().format("YYYY-MM-DD")) {
      if (rowIndex === 0) {
        classes.push("todayTop");
      }
      if (rowIndex === length - 1) {
        classes.push("todayBottom");
      }
      classes.push("today");
    }
    if (!this.isDate(columns[index])) {
      classes.push("hours");
    } else {
      classes.push("pointer");
    }
    if (
      this.isDate(columns[index]) &&
      (moment(columns[index]).format("dddd") === "Saturday" ||
        moment(columns[index]).format("dddd") === "Sunday")
    ) {
      classes.push("weekend");
    }
    return classes;
  }
  private sortBy(key: string): void {
    this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
    this.sort.key = key;
  }
  private isSorted(key: string, isAsc: boolean): boolean {
    return this.sort.key === key && this.sort.isAsc === isAsc;
  }
  private filter(employee: any, shifts: string[], type: string): boolean {
    let filter = false;
    Object.keys(employee.schedules).forEach(function(key) {
      employee.schedules[key].forEach(function(schedule: any) {
        if (
          schedule.type === "shift" &&
          shifts.includes(type === "shift" ? schedule.value : schedule.position)
        ) {
          filter = true;
        }
      });
    });
    return filter;
  }
  private dateChanged(value: Date): void {
    this.selectedDate = value;
  }
  private applyFilters() {
    this.viewStatistics =
      this.currentSchedulerFilter.viewStatistics &&
      this.currentSchedulerFilter.viewStatisticsTop &&
      this.StatisticsTop
        ? "top"
        : this.currentSchedulerFilter.viewStatistics
        ? "bottom"
        : "none";
    this.viewPeriods =
      this.currentSchedulerFilter.viewPeriods &&
      this.currentSchedulerFilter.viewPeriodsTop &&
      this.PeriodsTop
        ? "top"
        : this.currentSchedulerFilter.viewPeriods
        ? "bottom"
        : "none";
    this.viewTotals =
      this.currentSchedulerFilter.viewTotals &&
      this.currentSchedulerFilter.viewTotalsTop &&
      this.TotalsTop
        ? "top"
        : this.currentSchedulerFilter.viewTotals
        ? "bottom"
        : "none";
    this.noOfWeeks = this.currentSchedulerFilter.noOfWeeks;
    this.showIcons = this.currentSchedulerFilter.showIcons;
    this.mode = this.currentSchedulerFilter.mode;
    this.selectedShifts = this.currentSchedulerFilter.selectedShifts;
    this.selectedPositions = this.currentSchedulerFilter.selectedPositions;
    this.selectedTitles = this.currentSchedulerFilter.selectedTitles;
    this.selectedCategories = this.currentSchedulerFilter.selectedCategories;
    this.selectedFtes = this.currentSchedulerFilter.selectedFtes;
    this.hideColumns = this.currentSchedulerFilter.hideColumns;
    this.display = this.currentSchedulerFilter.display;
    this.showDepartments = this.currentSchedulerFilter.showDepartments;
  }
  private freezeTable() {
    this.$nextTick(function() {
      const tableEl = this.$refs.table as HTMLTableElement;
      const thead = tableEl.querySelectorAll("thead tr");
      const tbody = tableEl.querySelectorAll("tbody tr");
      const widths = [] as number[];
      let height = 0;
      thead.forEach(function(row: any, index: number) {
        let width = 0;
        const corners = row.querySelectorAll("th.freeze-corner");
        const headers = row.querySelectorAll("th.freeze-header");
        headers.forEach(function(header: any) {
          header.setAttribute(
            "style",
            "position: sticky; z-index: 8; top: " + height + "px;"
          );
        });
        corners.forEach(function(corner: any) {
          corner.setAttribute(
            "style",
            "position: sticky; z-index: 10; top: " +
              height +
              "px; left: " +
              width +
              "px;"
          );
          if (index === thead.length - 1) {
            widths.push(width);
            width += corner.getBoundingClientRect().width;
          }
        });
        height += row.getBoundingClientRect().height;
      });
      tbody.forEach(function(row: any) {
        let width = 0;
        const columns = row.querySelectorAll("th.freeze-column");
        columns.forEach(function(column: any) {
          column.setAttribute(
            "style",
            "position: sticky; z-index: 9; left: " + widths[width] + "px;"
          );
          width++;
        });
      });
    });
  }
  private reload() {
    console.log("dep reload", this.currentDepartmentId);
    showSpinner(document.getElementById("schedule") as HTMLElement);
    this.employees = [];
    const that = this as any;
    if (
      this.currentDepartmentId !== undefined &&
      this.currentDepartmentId !== 0
    ) {
      this.isLoading = true;
      this.onReload = true;
      const data: ParameterSchedulerModel = {
        startDate: moment(this.selectedDate)
          .startOf("day")
          .toDate(),
        endDate: moment(this.selectedDate)
          .add(this.noOfWeeks, "w")
          .add(-1, "d")
          .startOf("day")
          .toDate(),
        mode: this.mode,
        departmentId: this.currentDepartmentId
      };
      this.loadingSchedulerInfoByDateRange(data).then(
        (d: SchedulerItemModelByUserId[]) => {
          d.forEach(function(arrayItem) {
            try {
              const json = JSON.parse(arrayItem.json as string);
              json["userId"] = arrayItem.userId;
              that.employees.push(json);
            } catch (error) {
              console.error("Error parsing " + arrayItem.json);
            }
          });
          hideSpinner(document.getElementById("schedule") as HTMLElement);
          this.isLoading = false;
          this.onReload = false;
        }
      );
    }
  }
}
