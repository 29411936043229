















import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import { CardMixin } from "@/mixins";
import ProfileLogoTemplateComponent from "@/common/template/profile-logo/component.vue";
import ShiftTypeButtonTemplateComponent from "@/common/template/shift-type-button/component.vue";
@Component({
  components: {
    ProfileLogoTemplateComponent,
    ShiftTypeButtonTemplateComponent
  }
})
export default class ShiftCardTemplateComponent extends Mixins(CardMixin) {
  // @Watch("data", { immediate: true, deep: true })
  // modelChange() {
  //   console.log("modelChange template", this.data);
  // }
}
