


























































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { SideBarType } from "@/models/common/sidebar.state";
import { Sidebar } from "@syncfusion/ej2-vue-navigations";
import {
  ComposeMessageMenuComponent,
  ManageAccessLevelsComponent,
  EditDepartmentSettingsComponent,
  EditDepartmentComponent,
  AddNewDepartmentComponent,
  AddLicenseComponent,
  EditEmployeeDetailsComponent,
  ResetPasswordComponent
} from "@/common/side-bars/employee-profile";
import { DailyIconsComponent } from "@/common";
import { EmployeeDayComponent } from "@/common/side-bars/employee-day";
import {
  AddEmployeeShiftComponent,
  AddShiftComponent,
  AddStaffToShiftComponent,
  UpsetIconComponent,
  EditShiftComponent,
  AddEmployeeToShiftComponent,
  EditEmployeeShiftComponent,
  DeleteEmployeeShiftComponent
} from "./shifts";
import { 
  AddEmployeeIconComponent, 
  EditEmployeeIconComponent,
  DeleteEmployeeIconComponent,
  ApprovalEmployeeIconComponent
} from "./icons";
const SideBar = namespace("SideBar");
@Component({
  components: {
    AddNewDepartmentComponent,
    EditDepartmentComponent,
    ManageAccessLevelsComponent,
    ComposeMessageMenuComponent,
    AddLicenseComponent,
    AddEmployeeShiftComponent,
    AddShiftComponent,
    EditDepartmentSettingsComponent,
    EditEmployeeDetailsComponent,
    AddStaffToShiftComponent,
    UpsetIconComponent,
    EmployeeDayComponent,
    DailyIconsComponent,
    EditShiftComponent,
    ResetPasswordComponent,
    AddEmployeeToShiftComponent,
    AddEmployeeIconComponent,
    EditEmployeeIconComponent,
    EditEmployeeShiftComponent,
    DeleteEmployeeShiftComponent,
    DeleteEmployeeIconComponent,
    ApprovalEmployeeIconComponent
  }
})
export default class SideBarComponent extends Vue {
  @SideBar.Getter
  private currentActiveMenu!: SideBarType;
  SideBarType = SideBarType;
  private type = SideBarType.None;
  @Ref("sidebarInstance") readonly sidebarInstance!: Sidebar;
  isOpen = false;
  @Watch("currentActiveMenu", { immediate: true, deep: true })
  checkType(data: SideBarType) {
    setTimeout(() => {
      if (data !== SideBarType.None) {
        this.sidebarInstance.show();
      } else {
        this.sidebarInstance.hide();
      }
    }, 0);
  }
}
