








import { Component, Mixins, Prop } from "vue-property-decorator";
import { CardMixin } from "@/mixins";
import { namespace } from "vuex-class";
const SideBar = namespace("SideBar");
import { SideMenuItem, SideBarType } from "@/models";
@Component({
  components: {}
})
export default class LocationCardTemplateComponent extends Mixins(CardMixin) {}
