
























import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { FilterBase, FilterModel, FilterProperties } from "@/models";
import moment from "moment";
@Component({ name: "FilterByTimeRange" })
export default class FilterByTimeRangeComponent extends Vue
  implements FilterBase {
  @Prop() filter!: FilterModel;
  @Prop() data!: FilterProperties;

  startTime: Date | undefined | null = null;
  endTime: Date | undefined | null = null;

  @Watch("data", { immediate: true, deep: true })
  changeData() {
    this.setDefaultValue();
  }

  @Watch("filter", { immediate: true, deep: true })
  changeFilter() {
    this.setDefaultValue();
  }

  validate() {
    console.log("time range by validate");
  }
  collectValue(): FilterProperties | undefined {
    console.log("time range by collectValue", this.startTime, this.endTime);
    let start = undefined;
    let end = undefined;

    if (this.startTime) {
      start = moment(this.startTime).format("HH:mm");
    }
    if (this.endTime) {
      end = moment(this.endTime).format("HH:mm");
    }

    if (!start && !end) {
      return undefined;
    }
    return {
      startTimeValue: start,
      endTimeValue: end,
      key: this.data.key
    } as FilterProperties;
  }

  clearValue() {
    this.startTime = null;
    this.endTime = null;
  }

  setDefaultValue() {
    if (this.data && this.filter && this.filter.filterBy.length) {
      const val = this._.find(this.filter.filterBy, f => {
        return f.key == this.data.key;
      });
      console.log("setDefaultValue", val);
      if (val) {
        const dt = moment().format("MM/DD/yyyy");
        let startVal = undefined;
        let endVal = undefined;
        if (val.startTimeValue) {
          startVal = moment(dt + " " + val.startTimeValue).toDate();
        }
        if (val.endTimeValue) {
          endVal = moment(dt + " " + val.endTimeValue).toDate();
        }
        this.startTime = startVal;
        this.endTime = endVal;
      } else {
        this.startTime = undefined;
        this.endTime = undefined;
      }
    }
  }
}
